import { apiKeys } from '@constants/apiKeys';
import { apiRoutes } from '@constants/apiRoutes';
import { LOADING_KEYS } from '@constants/loading';
import { useLoadingContext } from '@domains/AppRoot/Providers/LoadingProvider';
import { AppTranslation } from '@domains/Intl/domains/App';
import { showErrorMessage } from '@helpers/error';
import axiosApi from '@lib/axiosApi';
import { toast } from '@ui-kit/message';
import React from 'react';
import { Control, DeepMap, FieldError, SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';

interface OptionsType {
  email: string;
}

export interface IUseResetPasswordReturnType {
  control: Control<any>;
  onResetPassword: (e: React.SyntheticEvent) => Promise<void>;
  formErrors: DeepMap<Record<string, any>, FieldError> | null;
  responseError?: unknown;
}

export const useResetPassword = (): IUseResetPasswordReturnType => {
  const { setLoading, removeLoading } = useLoadingContext();
  const { formatMessage } = useIntl();
  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm({
    defaultValues: {
      email: '',
    },
  });

  const { mutate } = useMutation(
    apiKeys.resetPassword,
    (data: OptionsType): Promise<string> => {
      setLoading(LOADING_KEYS.resetPassword);
      return axiosApi.post(apiRoutes.resetPassword, data)?.then(res => res.data);
    },
    {
      onSuccess: async () => {
        toast({ text: formatMessage({ id: AppTranslation['reset-password.success-message'] }), type: 'success' });
      },
      onError: showErrorMessage(),
      onSettled: removeLoading(LOADING_KEYS.resetPassword),
    },
  );

  const onSubmit: SubmitHandler<OptionsType> = credentials => {
    mutate(credentials);
  };

  return { control, onResetPassword: handleSubmit(onSubmit), formErrors };
};
