import { menuSelector } from '@szhsin/react-menu/style-utils';
import { Menu } from '@ui-kit/menu/controlledMenu';
import { themeMode } from '@ui-kit/theme';
import styled from 'styled-components';
import theme from 'styled-theming';

export const mainColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen,
});

export const StyledMenu = styled(Menu)`
  z-index: 9999;

  ${menuSelector.name} {
    border: 1px solid ${mainColor};
  }

${({ theme }) => theme.media.downWith.xxs} {
  ${menuSelector.name} {
    left: -30px !important;
}`;
