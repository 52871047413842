import ReactModal from 'react-modal';
import styled from 'styled-components';

export const ModalContent = styled.div.attrs({ className: 'modal-content' as string })`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
`;

export const UIModal = styled(ReactModal)<{ width?: string }>`
  width: ${({ width }) => width || 'auto'};
  ${({ theme }) => theme.media.down.s} {
    width: calc(100% - 60px);
  }
  ${({ theme }) => theme.media.down.xs} {
    width: 100%;
  }
`;
