import { ViolationErrorType } from '@type/error';
import { toast } from '@ui-kit/message';
import { UseFormSetError } from 'react-hook-form/dist/types/form';

type TypeShowErrorMessage = (params?: {
  setLoading?: () => void;
  closeModal?: () => void;
  setError?: UseFormSetError<any>;
}) => (error: any) => Promise<unknown> | void;

export const showErrorMessage: TypeShowErrorMessage =
  props =>
  ({ response, message }) => {
    const { setLoading, closeModal, setError } = props || {};
    if (setLoading) {
      setLoading();
    }
    if (closeModal) {
      closeModal();
    }
    if (response?.data?.violations?.length && setError && response?.data?.violations[0].propertyPath !== 'user') {
      return response?.data?.violations?.forEach((err: ViolationErrorType, index: number) =>
        setError(err.propertyPath, { message: err.message }, { shouldFocus: index === 0 }),
      );
    }
    const messageText = response?.data?.violations
      ? response?.data?.violations[0]?.message
      : response?.data?.detail || response?.data?.message || message;
    if (!messageText) return;
    toast({ text: messageText, type: 'error' });
  };
