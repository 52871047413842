import { ELEVATOR_FORM_TABS, ELEVATOR_TABS, ELEVATORS_TABS } from '@constants/elevator';
import { getTabTypesForRoute } from '@helpers/routing';
import { AUTH_TABS } from '@type/auth';
import { SETTINGS_TABS } from '@type/settings';

export const routes = {
  home: {
    key: 'home',
    path: '/',
    get: () => '/',
  },

  dashboard: {
    key: 'dashboard',
    path: '/dashboard',
    get: () => '/dashboard',
  },

  accountSwitcher: {
    key: 'accounts',
    path: '/accounts',
    get: () => '/accounts',
  },

  auth: {
    key: 'auth',
    path: `/auth/:tab(${getTabTypesForRoute(AUTH_TABS)})`,
    get: (tab: string) => `/auth/${tab}`,
  },

  login: {
    key: AUTH_TABS.login,
    path: `/auth/${AUTH_TABS.login}`,
    get: () => `/auth/${AUTH_TABS.login}`,
  },

  resetPassword: {
    key: AUTH_TABS.resetPassword,
    path: `/auth/${AUTH_TABS.resetPassword}`,
    get: () => `/auth/${AUTH_TABS.resetPassword}`,
  },

  changePassword: {
    key: AUTH_TABS.changePassword,
    path: `/auth/${AUTH_TABS.changePassword}`,
    get: () => `/auth/${AUTH_TABS.changePassword}`,
  },

  createExternalUser: {
    key: AUTH_TABS.createExternalUser,
    path: `/auth/${AUTH_TABS.createExternalUser}`,
    get: () => `/auth/${AUTH_TABS.createExternalUser}`,
  },

  inviteExternalUser: {
    key: AUTH_TABS.inviteExternalUser,
    path: `/auth/${AUTH_TABS.inviteExternalUser}`,
    get: () => `/auth/${AUTH_TABS.inviteExternalUser}`,
  },

  successfulExternalUserCreation: {
    key: AUTH_TABS.successfulExternalUserCreation,
    path: `/auth/${AUTH_TABS.successfulExternalUserCreation}`,
    get: () => `/auth/${AUTH_TABS.successfulExternalUserCreation}`,
  },

  successfulExternalUserPasswordChange: {
    key: AUTH_TABS.successfulExternalUserPasswordChange,
    path: `/auth/${AUTH_TABS.successfulExternalUserPasswordChange}`,
    get: () => `/auth/${AUTH_TABS.successfulExternalUserPasswordChange}`,
  },

  objects: {
    key: 'objects',
    path: `/objects/:tab(${getTabTypesForRoute(ELEVATORS_TABS)})`,
    get: (tab: string) => `/objects/${tab}`,
  },

  settings: {
    key: 'settings',
    path: `/settings/:tab(${getTabTypesForRoute(SETTINGS_TABS)})`,
    get: (tab: string) => `/settings/${tab}`,
  },

  settingsUser: {
    key: 'settings',
    path: `/settings/${SETTINGS_TABS.users}/:id`,
    get: (id: number | string) => `/settings/${SETTINGS_TABS.users}/${id}`,
  },

  settingsCompany: {
    key: 'settings',
    path: `/settings/${SETTINGS_TABS.companies}/:id`,
    get: (id: number | string) => `/settings/${SETTINGS_TABS.companies}/${id}`,
  },

  FAQ: {
    key: 'FAQ',
    path: '/FAQ',
    get: () => '/FAQ',
  },

  objectDetails: {
    key: 'objectDetails',
    path: `/objects/details/:id/:tab(${getTabTypesForRoute(ELEVATOR_TABS)})`,
    get: (id: string | number, tab: string) => `/objects/details/${id}/${tab}`,
  },

  editObject: {
    key: 'editObject',
    path: `/objects/:id/edit/:tab(${getTabTypesForRoute(ELEVATOR_FORM_TABS)})`,
    get: (id: string, tab: string) => `/objects/${id}/edit/${tab}`,
  },

  profile: {
    key: 'profile',
    path: '/profile',
    get: () => `/profile`,
  },

  404: {
    key: '404',
    path: '/404',
    get: () => `/404`,
  },
};
