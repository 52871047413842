import { ButtonBaseRoot } from '@ui-kit/button/Button/Button.styles';
import { ButtonProps } from '@ui-kit/button/Button/Button.types';
import { $object, Override } from '@ui-kit/helpers/types';
import React, { PropsWithChildren } from 'react';

type ButtonBaseProps<T extends $object> = {
  element: keyof JSX.IntrinsicElements | React.ComponentType<T>;
  className?: string;
  elementRef?: React.RefObject<Element>;
} & Override<ButtonProps, T>;

export const ButtonBase = <T extends $object>({
  children,
  className,
  element,
  elementRef,
  ...props
}: PropsWithChildren<ButtonBaseProps<T>>) => {
  return (
    <ButtonBaseRoot
      {...props}
      ref={elementRef}
      as={element as React.ComponentType<any>}
      className={className}
      spellCheck="false"
    >
      {children}
    </ButtonBaseRoot>
  );
};
