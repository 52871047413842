export enum ChangePasswordFormFields {
  password = 'plainPassword',
  passwordConfirmation = 'passwordConfirmation',
}

export enum InviteExternalUserFormFields {
  email = 'email',
}

export enum CreateExternalUserFormFields {
  firstName = 'firstName',
  lastName = 'lastName',
  plainPassword = 'plainPassword',
  confirmPlainPassword = 'confirmPlainPassword',
  phoneNumber = 'phoneNumber',
  email = 'email',
}

export enum AUTH_TABS {
  login = 'login',
  resetPassword = 'reset-password',
  changePassword = 'change-password',
  createExternalUser = 'create-external-user',
  inviteExternalUser = 'invite-external-user',
  successfulExternalUserCreation = 'successful-external-user-creation',
  successfulExternalUserPasswordChange = 'successful-external-user-password-change',
}
