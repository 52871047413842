import { apiKeys } from '@constants/apiKeys';
import { apiRoutes } from '@constants/apiRoutes';
import { SessionUserAccountId } from '@helpers/account';
import { SessionToken } from '@helpers/auth';
import axiosApi from '@lib/axiosApi';
import { IUser } from '@type/user';
import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { v4 as uuidv4 } from 'uuid';

import { appEnv } from '../../appEnv';

export enum SessionStatus {
  idle = 'idle',
  loading = 'loading',
  active = 'active',
  closed = 'closed',
  error = 'error',
}

interface ISession {
  status: SessionStatus;
  currentUser: IUser | null;
}

export interface ISessionContext {
  status: SessionStatus;
  currentUser: IUser | null;
  createSession: (sessionParams: {
    token?: string;
    currentUser?: IUser;
    currentUserAccessId?: number;
  }) => Promise<void>;
  closeSession: () => void;
}

export const SessionContext = React.createContext({} as ISessionContext);

export const useSessionProvider = (): ISessionContext => {
  const [session, setSession] = useState<ISession>({ currentUser: null, status: SessionStatus.idle });

  const { mutateAsync: getCurrentUser } = useMutation(apiKeys.currentUser, async () => {
    const { status, data } = await axiosApi.get<IUser>(apiRoutes.currentUser);

    if (status !== 200 || !data /** to do: Check user interface */) {
      throw new Error('Cannot get current user');
    }

    return data;
  });

  const createSession = useCallback(
    async (sessionParams: { token?: string; currentUser?: IUser; currentUserAccessId?: number }) => {
      const { token, currentUser, currentUserAccessId } = sessionParams;

      if (token) {
        SessionToken.set(token);
      }

      if (currentUserAccessId) {
        SessionUserAccountId.set(currentUserAccessId);
      }

      if (!SessionToken.has()) {
        setSession({ status: SessionStatus.error, currentUser: null });
        console.error(SessionToken.get());
        throw new Error(`Cannot start Session without token`);
      }

      if (currentUser) {
        setSession({ status: SessionStatus.active, currentUser });
        return;
      }

      try {
        setSession({ status: SessionStatus.active, currentUser: await getCurrentUser() });
      } catch {
        setSession({ status: SessionStatus.error, currentUser: null });
        throw new Error(`Session failed`);
      }
    },
    [getCurrentUser],
  );

  const closeSession = useCallback(() => {
    SessionToken.reset();
    SessionUserAccountId.reset();
    setSession({ status: SessionStatus.closed, currentUser: null });
  }, []);

  useEffect(() => {
    const requestInterceptionId = axiosApi.interceptors.request.use(request => {
      const sessionToken = SessionToken.get();
      const sessionUserAccountId = SessionUserAccountId.get();
      const requestId = uuidv4();
      if (sessionToken) {
        request.headers = { ...request.headers, Authorization: `Bearer ${sessionToken}` };
      }
      if (sessionUserAccountId) {
        request.headers = { ...request.headers, 'x-user-account-id': sessionUserAccountId };
      }
      if (requestId) {
        request.headers = { ...request.headers, 'X-Request-ID': requestId };
      }
      request.headers = { ...request.headers, 'X-Client-Version': appEnv.CLIENT_VERSION || '' };
      return request;
    });

    const responseInterceptionId = axiosApi.interceptors.response.use(
      response => response,
      error => {
        if (error?.response.status === 401) {
          console.log('STATUS', error.response.status);
          closeSession();
        }

        return Promise.reject(error);
      },
    );

    createSession({ token: SessionToken.get()! });

    return () => {
      axiosApi.interceptors.request.eject(requestInterceptionId);
      axiosApi.interceptors.request.eject(responseInterceptionId);
    };
  }, [createSession, closeSession]);

  return {
    ...session,
    closeSession,
    createSession,
  };
};
