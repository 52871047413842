import styled from 'styled-components';

export const LoaderItemWrapper = styled.div`
  position: absolute;
  background-color: transparent;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
`;

export const LoaderContainer = styled.div<{ isVisible?: boolean }>`
  z-index: 99998;
  opacity: 1;
  visibility: visible;
  transition: 0.5s all;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  ${({ isVisible }) => !isVisible && ` opacity:0; visibility:hidden;`}
`;

export const LoaderOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99997;
  background-color: rgba(255, 255, 255, 0.8);
`;
