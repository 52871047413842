import { apiKeys } from '@constants/apiKeys';
import { apiRoutes } from '@constants/apiRoutes';
import { LOADING_KEYS } from '@constants/loading';
import { routes } from '@constants/routes';
import { useLoadingContext } from '@domains/AppRoot/Providers/LoadingProvider';
import { useSession } from '@hooks/auth/useSession';
import axiosApi from '@lib/axiosApi';
import { toast } from '@ui-kit/message';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

export interface IUseLogoutReturnType {
  onLogout: () => void;
}

export const useLogout = (): IUseLogoutReturnType => {
  const history = useHistory();
  const { setLoading, removeLoading } = useLoadingContext();
  const { closeSession } = useSession();

  const { mutate } = useMutation(
    apiKeys.logout,
    (): Promise<string> => {
      setLoading(LOADING_KEYS.logout);
      return axiosApi.get(apiRoutes.logout)?.then(res => res.data);
    },
    {
      onSuccess: () => {
        closeSession();
        history.push(routes.login.path);
      },
      onError: ({ response, message }) => {
        toast({ text: response?.data?.message || message, type: 'error' });
      },
      onSettled: removeLoading(LOADING_KEYS.logout),
    },
  );

  const onLogout = () => {
    mutate();
  };

  return { onLogout };
};
