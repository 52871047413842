import { ConfirmModal } from '@components/common/ConfirmModal';
import { useLogout } from '@hooks/auth';
import React from 'react';

interface Props {
  isOpenModal: boolean;
  closeModal: () => void;
}

export const Logout: React.FC<Props> = ({ isOpenModal, closeModal }) => {
  const { onLogout } = useLogout();
  return (
    <ConfirmModal
      translationKey={{
        buttons: {
          cancel: 'cancel',
          confirm: 'logout.modal.buttons.confirm',
        },
        content: 'logout.modal.content',
        header: 'logout.modal.header',
      }}
      onConfirm={onLogout}
      isOpenModal={isOpenModal}
      closeModal={closeModal}
    />
  );
};
