import { mainColor } from '@ui-kit/form/SearchInput/Common.styles';
import { outlineColor } from '@ui-kit/globalStyles';
import { pxToRem } from '@ui-kit/helpers';
import { themeMode } from '@ui-kit/theme';
import styled from 'styled-components';
import theme from 'styled-theming';

const inputColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.white,
});

export const Container = styled.div<{ isFocused: boolean }>`
  border-radius: ${({ theme }) => theme.borderRadius.s};
  border: 1px solid ${mainColor};
  position: relative;
  background: ${inputColor};
  font-size: ${pxToRem(16)};
  box-shadow: ${props => (props.isFocused ? `0 0 0 2px ${outlineColor(props)}` : 'none')};
`;

export const Inner = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 15px 0;
`;
