import { TIconButtonProps } from '@ui-kit/button';
import { themeMode } from '@ui-kit/theme';
import styled from 'styled-components';
import theme from 'styled-theming';

export const borderColor = theme.variants('mode', 'borderColor', {
  primary: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.white },
  secondary: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen },
  tertiary: { [themeMode.light]: 'transparent' },
  error: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.red },
});

export const bgColor = theme.variants('mode', 'bgColor', {
  transparent: { [themeMode.light]: 'transparent' },
  primary: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen },
});

export const StyledIconButton = styled.button<Omit<TIconButtonProps, 'icon'>>`
  align-items: center;
  background: transparent;
  border-radius: ${({ rounded, theme }) => rounded && theme.borderRadius.circle};
  border: 2px solid ${borderColor};
  background: ${bgColor};
  cursor: pointer;
  display: flex;
  width: ${({ buttonSize }) => buttonSize || '10px'};
  height: ${({ buttonSize }) => buttonSize || '10px'};
  justify-content: center;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  box-sizing: border-box;
  padding: 0;
  outline-offset: 2px;
  flex-shrink: 0;
  position: relative;
  & > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
