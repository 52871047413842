export const apiRoutes: { [key: string]: any } = {
  login: `/api/login_check`,
  logout: '/api/token/invalidate',
  resetPassword: `/api/user/reset_password`,
  changePassword: `/api/user/reset_password/change`,
  currentUser: `/api/user/users/current`,
  entries: (id: string) => `/api/elevator/elevators/${id}/entries`,
  entryCategories: '/api/logbook/entry-categories',
  entriesUsers: (id: string) => `/api/elevator/elevators/${id}/entries/users`,
  entryImage: (id: string) => `/api/logbook/media-documents/${id}/download`,
  newEntry: '/api/logbook/entries',
  deleteEntry: (id: number) => `/api/logbook/entries/${id}`,
  elevator: (id: string) => `/api/elevator/elevators/${id}`,
  editElevator: (id: string) => `/api/elevator/elevators/${id}`,
  elevators: `/api/elevator/elevators`,
  elevatorTypes: 'api/elevator/types',
  elevatorModels: 'api/elevator/models',
  elevatorManufacturers: '/api/elevator/manufacturers',
  elevatorAlarmLineTypes: '/api/elevator/alarm-line-types',
  elevatorAlarmTypes: '/api/elevator/alarm-types',
  elevatorLineProviders: '/api/elevator/line-providers',
  createElevator: '/api/elevator/elevators',
  users: 'api/user/users',
  companies: 'api/companies',
  giveUserAccessToElevator: 'api/user/users/elevators',
  giveCompanyAccessToElevator: 'api/companies/elevators',
  userFavoriteLists: (userId?: number) => `api/user/users/${userId}/favorite-lists`,
  addToFavoriteLists: (id: string) => `api/user/favorite-lists/${id}/add`,
  createFavoriteList: 'api/user/favorite-lists/',
  editFavoriteList: (id: number) => `/api/user/favorite-lists/${id}`,
  deleteFavoriteList: (id: number) => `/api/user/favorite-lists/${id}`,
  removeFromFavorites: (id: string) => `api/user/favorite-lists/${id}/delete`,
  setLocationMapToElevator: (id: number) => `/api/elevator/elevators/${id}/location_map`,
  deactivateUser: (id: number) => `/api/user/users/${id}/disable`,
  activateUser: (id: number) => `/api/user/users/${id}/enable`,
  deleteUser: (id: number) => `/api/account/account-user-accesses/${id}`,
  createUser: `/api/user/users`,
  editUser: (id?: number) => `/api/user/users/${id}`,
  editUserDomainRole: (id?: number) => `/api/account/account-user-accesses/${id}`,
  domainRoles: `/api/user/domain-roles`,
  profileChangePassword: (id: number) => `/api/user/users/${id}/password`,
  uploadProfileImage: (id: number) => `/api/user/users/${id}/profile_image`,
  deleteProfileImage: (id: number) => `/api/user/users/${id}/profile_image`,
  archivedElevators: '/api/elevator/elevators/archived',
  archiveElevators: '/api/elevator/elevators/archive',
  account: (id?: number | string) => `/api/accounts/${id}`,
  editAccount: (id?: number | string) => `/api/accounts/${id}`,
  accounts: '/api/accounts',
  membershipTypes: '/api/account/membership-types',
  user: (id: string) => `/api/user/users/${id}`,
  userByEmail: (email: string) => `/api/user/users/email/${email}`,
  removeUserAccessToElevators: '/api/user/users/elevators-delete',
  sendEmailForCreateExternalUser: '/api/user/registration/email',
  createExternalUser: '/api/user/registration/external',
  companyTypes: '/api/company/types',
  accountCompanyAccesses: '/api/account-company-accesses',
  addAccountCompanyAccesses: '/api/account-company-accesses',
  removeAccountCompanyAccesses: (id: string) => `/api/account-company-accesses/${id}`,
  removeCompanyAccessToElevators: '/api/companies/elevators-delete',
  company: (id: string) => `/api/companies/${id}`,
  editExternalUser: (id?: number) => `/api/user/users/external/${id}`,
  activityTypes: '/api/activity/types',
  activities: '/api/activities',
  reactivateUser: '/api/user/activation',
  exportLogbook: '/api/export/elevators',
};
