export const zIndex = {
  _1: 100,
  _2: 200,
  _3: 300,
  _4: 400,
  _5: 500,
  _6: 600,
  _7: 700,
  _8: 800,
  _9: 900,
  _10: 1000,
};
