import { StyledText } from '@ui-kit/typography/Text/Text.styles';
import React, { AnchorHTMLAttributes } from 'react';

export type TextColorType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'quaternary'
  | 'quinary'
  | 'senary'
  | 'septenary'
  | 'octal'
  | 'error';

export type HoverColorType = 'main' | 'darkenedMain';

export type TypeCursor = 'auto' | 'default' | 'none' | 'pointer' | 'grab' | 'grabbing';

export interface ITextBaseProps extends AnchorHTMLAttributes<any> {
  margin?: string;
  padding?: string;
  disabled?: boolean;
  lineClamp?: number;
  fontSize?: string;
  fontVariant?: 'primary';
  fontWeight?: 'regular' | 'medium' | 'semiBold' | 'bold';
  decoration?: 'underline' | 'none';
  case?: 'uppercase' | 'lowercase' | 'capitalize';
  textColor?: TextColorType;
  isImportantTextColor?: boolean;
  className?: string;
  textAlign?: 'center' | 'left' | 'right' | 'justify';
  lineHeight?: string;
  as?: keyof JSX.IntrinsicElements;
  cursor?: TypeCursor;
  onClick?: <T>(param?: T) => void;
  hoverColor?: HoverColorType;
  upperFirst?: boolean;
  whiteSpace?: 'pre-line' | 'nowrap' | 'pre' | 'normal' | 'pre-wrap' | 'inherit' | 'initial';
  wordBreak?: 'normal' | 'break-all' | 'keep-all' | 'break-word';
}

export const Text: React.FC<ITextBaseProps> = ({
  textColor = 'primary',
  fontVariant = 'primary',
  textAlign = 'left',
  className,
  ...props
}) => (
  <StyledText {...props} className={className} textColor={textColor} fontVariant={fontVariant} textAlign={textAlign} />
);
