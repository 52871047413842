import { MessageType } from '@ui-kit/message/ToastMessage';
import { themeMode } from '@ui-kit/theme';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';

const background = theme.variants('mode', 'type', {
  error: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.red },
  success: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen },
  info: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.cruelSeaGreen },
});

const stylesFlex = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div<{ type: keyof typeof MessageType }>`
  background: ${background};
  position: relative;
  padding: 20px 22px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 15px;
`;

export const InnerIcon = styled.div`
  ${stylesFlex};
`;

export const InnerContent = styled.div`
  flex-grow: 1;
`;

export const CloseButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  ${stylesFlex};
`;
