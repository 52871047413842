import { AppTranslation } from '@domains/Intl/domains/App';
import { Router } from '@domains/Router';
import { useGoogleAnalytics } from '@domains/Tracking/Tracking.hooks';
import { GlobalStyles } from '@ui-kit/globalStyles';
import { ToastContainer } from '@ui-kit/message';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Helmet } from 'react-helmet';
import { hot } from 'react-hot-loader';
import { useIntl } from 'react-intl';
import 'react-toastify/dist/ReactToastify.css';

const Head = () => {
  const { formatMessage } = useIntl();

  return (
    <Helmet>
      <title>{formatMessage({ id: AppTranslation.appTitle })}</title>
    </Helmet>
  );
};

const AppRoot = () => {
  useGoogleAnalytics();

  return (
    <>
      <Head />
      <Router />
      <GlobalStyles />
      <ToastContainer hideProgressBar position={'bottom-center'} />
    </>
  );
};

export default hot(module)(AppRoot);
