import { useMenuState } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { Control } from '@ui-kit/form/SearchInput/Control';
import { ISearchInputProps } from '@ui-kit/form/SearchInput/SearchInput.types';
import React, { useEffect, useRef, useState } from 'react';

export const SearchInput = <Data extends object>({
  values: propsValues,
  onFilter,
  labelField = 'label',
  valueField = 'value',
  inputValue,
  onInputChange,
  placeholder,
  dropdownContent,
  onDeleteValue: onDeleteValueProp,
  isDropdown = true,
}: ISearchInputProps<Data>) => {
  const [values, setValues] = useState(propsValues);
  const [inputValueRef, setInputValueRef] = useState<HTMLInputElement | null>(null);
  const [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [menuProps, toggleMenu] = useMenuState({ transition: true });
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setValues(propsValues);
  }, [propsValues]);

  const onDeleteValue = (key: string | number, item: any) => () => {
    const filterValues = values.filter(item => key !== (item as any)[valueField]);
    setValues(filterValues);
    onDeleteValueProp?.(item, filterValues);
  };

  const onFocusInputValue = () => {
    inputValueRef?.focus();
  };

  const handleToggleMenu = (open?: boolean) => {
    toggleMenu(open);
  };

  return (
    <Control
      values={values}
      onFilter={onFilter}
      labelField={labelField}
      valueField={valueField}
      onDeleteValue={onDeleteValue}
      inputValue={inputValue}
      onInputChange={onInputChange}
      placeholder={placeholder}
      menuProps={menuProps}
      wrapperRef={wrapperRef}
      handleToggleMenu={handleToggleMenu}
      dropdownContent={dropdownContent}
      setInputValueRef={setInputValueRef}
      onFocusInputValue={onFocusInputValue}
      buttonRef={buttonRef}
      setButtonRef={setButtonRef}
      isFocused={isFocused}
      setIsFocused={setIsFocused}
      isDropdown={isDropdown}
    />
  );
};
