export const getAvatarPlaceholder = (name: string) => {
  return name
    .split(' ')
    .map(item => item[0])
    .join('');
};

export const getPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.split(' ').join('');
};

export const getPrice = (price: number, quantity: number, discount: number) => {
  const priceWithoutDiscount = price * quantity;
  if (discount > 0) {
    return (priceWithoutDiscount * (100 - discount)) / 100;
  } else {
    return priceWithoutDiscount;
  }
};
