import { Loader } from '@ui-kit/loader';
import React, { createContext, FC, useCallback, useContext, useState } from 'react';

const LoadingContext = createContext<{
  isLoadingState: LoadingStateType;
  setLoading: (item: string) => void;
  removeLoading: (item: string) => () => void;
  removeAllLoading: () => void;
}>({ isLoadingState: [], setLoading: () => {}, removeLoading: () => () => {}, removeAllLoading: () => {} });

export const useLoadingContext = () => useContext(LoadingContext);

type LoadingStateType = (string | undefined)[];

export const LoadingProvider: FC = ({ children }) => {
  const [isLoadingState, setStateLoading] = useState<LoadingStateType>([]);

  const setLoading = useCallback(
    (key: string) => {
      setStateLoading(prev => [...prev, key]);
    },
    [setStateLoading],
  );

  const removeLoading = useCallback(
    (key: string) => () => {
      setStateLoading(prev => {
        return prev.filter(item => item !== key);
      });
    },
    [setStateLoading],
  );

  const removeAllLoading = () => {
    setStateLoading([]);
  };

  return (
    <LoadingContext.Provider value={{ isLoadingState, setLoading, removeLoading, removeAllLoading }}>
      {children}
      <Loader isVisible={!!isLoadingState?.length} size={40} />
    </LoadingContext.Provider>
  );
};
