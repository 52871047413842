import { ICompanyDomainRole } from '@type/company';
import { IDomainRole } from '@type/user';

export const getDomainRoleIDs = (data?: IDomainRole[] | IDomainRole) => {
  if (Array.isArray(data)) {
    return data?.length ? data.map(role => role.id) : undefined;
  }
  return data?.id;
};

export enum DomainRoleDividerType {
  commaWithNewline = 'commaWithNewline',
  comma = 'comma',
}

export const DomainRoleDividerTypeMap = {
  [DomainRoleDividerType.commaWithNewline]: ',\n',
  [DomainRoleDividerType.comma]: ', ',
};

interface GetDomainRoleTitleParams {
  data?: IDomainRole[] | IDomainRole | ICompanyDomainRole[] | ICompanyDomainRole;
  divider?: keyof typeof DomainRoleDividerType;
}

export const getDomainRoleTitle = ({
  data,
  divider = DomainRoleDividerType.commaWithNewline,
}: GetDomainRoleTitleParams) => {
  return Array.isArray(data) ? data.map(role => role.name).join(DomainRoleDividerTypeMap[divider]) : data?.name;
};
