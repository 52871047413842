import '@szhsin/react-menu/dist/index.css';
import { Hidden } from '@ui-kit/hidden';
import { DesktopContainer, Drawer, DrawerProps, MobileDrawerContainer, MobileOverlay } from '@ui-kit/navbar/Drawer';
import { TopBar } from '@ui-kit/navbar/TopBar/TopBar';
import { theme } from '@ui-kit/theme';
import React, { useState } from 'react';

const DesktopComponent: React.FC<DrawerProps> = props => {
  return (
    <Hidden mq={theme.media.downWith.xxl}>
      <DesktopContainer>
        <Drawer {...props} />
      </DesktopContainer>
    </Hidden>
  );
};

const MobileComponent: React.FC<DrawerProps> = props => {
  const { isAccountSwitcherRoute, homeRoute } = props;
  const [isMenuOpened, toggleMenu] = useState(false);

  const handleToggleMenu = () => {
    toggleMenu(!isMenuOpened);
    document.body.classList.toggle('no-scrolling');
  };

  return (
    <Hidden mq={theme.media.up.xxl}>
      <TopBar toggleMenu={handleToggleMenu} isAccountSwitcherRoute={isAccountSwitcherRoute} homeRoute={homeRoute} />
      {isMenuOpened && <MobileOverlay />}
      <MobileDrawerContainer className={isMenuOpened ? 'active' : ''}>
        <Drawer {...props} toggleMenu={handleToggleMenu} />
      </MobileDrawerContainer>
    </Hidden>
  );
};

export const Navbar: React.FC<DrawerProps> = props => {
  return (
    <>
      <DesktopComponent {...props} />
      <MobileComponent {...props} />
    </>
  );
};
