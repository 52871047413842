import { StyledMenu } from '@ui-kit/form/SearchInput/Dropdown.styles';
import { DropdownProps, TypeMenuProps } from '@ui-kit/form/SearchInput/SearchInput.types';
import React from 'react';

interface IDropdownProps {
  wrapperRef: React.RefObject<Element>;
  buttonRef?: HTMLButtonElement | null;
  handleToggleMenu: (open?: boolean) => void;
  menuProps: TypeMenuProps;
  dropdownContent: ({ closeMenu }: DropdownProps) => React.ReactNode;
}

export const Dropdown = ({ menuProps, handleToggleMenu, wrapperRef, buttonRef, dropdownContent }: IDropdownProps) => {
  const handleClose = React.useCallback(
    e => {
      handleToggleMenu(false);
      if (!buttonRef || (e && !e.key)) return;
      buttonRef.focus();
    },
    [handleToggleMenu],
  );

  return (
    <StyledMenu
      {...menuProps}
      tabIndex={-1}
      anchorRef={wrapperRef}
      direction={'bottom'}
      position={'initial'}
      closeMenu={handleClose}
    >
      {dropdownContent({ closeMenu: handleClose })}
    </StyledMenu>
  );
};
