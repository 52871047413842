import { themeMode } from '@ui-kit/theme';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';

export const Container = styled.div`
  @keyframes zoom {
    from {
      transform: scale(0.4);
    }
    to {
      transform: scale(1);
    }
  }
  animation-name: zoom;
  animation-duration: 0.6s;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const backgroundColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.white,
});

export const Inner = styled.div.attrs({ className: 'wrapper-content' })`
  position: relative;
  background: ${backgroundColor};
  border-radius: ${({ theme }) => theme.borderRadius.s};
  padding: 10px;
  box-sizing: border-box;
`;

export const InnerContent = styled.div.attrs({ className: 'inner-content' })`
  border-radius: ${({ theme }) => theme.borderRadius.s};
  overflow: hidden;
`;

export const overlayStyles = css`
  padding-top: 43px;
  padding-bottom: 50px;
  box-sizing: border-box;
`;

export const CloseButtonInner = styled.div.attrs({ className: 'close-button-container' })`
  position: absolute;
  top: 18px;
  right: 18px;
  z-index: 10;
`;
