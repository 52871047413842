import { AppLocale, AppTranslation } from './i18n.types';

export const enAppLocale: AppLocale = {
  [AppTranslation.appTitle]: 'lilo',
  [AppTranslation.headTitle]: 'Today is {ts, date, ::yyyyMMdd}',
  [AppTranslation['navbar.item.overview']]: 'Overview',
  [AppTranslation['navbar.item.objects']]: 'Your Objects',
  [AppTranslation['navbar.item.settings']]: 'Settings',
  [AppTranslation['navbar.item.FAQ']]: 'Customer service',
  [AppTranslation['navbar.item.logOut']]: 'Log Out',
  [AppTranslation['navbar.user.profile-settings']]: 'Profile settings',
  [AppTranslation['navbar.user.change-company']]: 'Change company',
  [AppTranslation['navbar.user.all-companies']]: 'All companies',
  [AppTranslation['app-tour.button.end-introduction']]: 'End introduction',
  [AppTranslation['app-tour.title.welcome']]: 'Welcome to LILO',
  [AppTranslation['app-tour.content.overview']]:
    'As the operator of an elevator, there is a lot to keep track of, and it is not always as easy to have an overview of everything that goes on at all times. LILO gives you an easy overview of all activities in the operational phase of the facility and is easily accessible where you are.',
  [AppTranslation['app-tour.content.objects']]:
    'Regardless of whether you are to document completed security checks or necessary inspection work, it is important to have easy access to the object in question. With LILO, you get a digital logbook for each object that makes it easy to log or search existing history. That means the end of ragged and indistinct logbooks.\n' +
    'And the beginning of a simpler everyday life',
  [AppTranslation['app-tour.content.welcome.part1']]:
    'LILO is an abbreviation for lifting device log, and is a modern one digital logbook for elevator administration adapted to the 21st century.\n' +
    'With LILO, we want to build the tool that is lacking in the industry, this version is a foundation for what we mean by such a tool should contain.',
  [AppTranslation['app-tour.content.welcome.part2']]:
    'Before you start, we want to give you a brief introduction to what you will find under the various pages.',
  [AppTranslation.createAccount]: 'Register service user',
  [AppTranslation['login.title']]: 'Smart, digital logbooks for elevators and escalators',
  [AppTranslation['login.subtitle.part1']]: 'Log in',
  [AppTranslation['login.subtitle.part2']]: 'to continue to LILO',
  [AppTranslation['login.link.forgotPassword']]: 'Forgot your password?',
  [AppTranslation['login.input.password.placeholder']]: 'Password',
  [AppTranslation['login.input.username.placeholder']]: 'Email',
  [AppTranslation['login.input.username.error.required-message']]: 'Email is required',
  [AppTranslation['login.input.password.error.required-message']]: 'Password is required',
  [AppTranslation['login.input.password.error.invalid-value']]: 'Must be greater than 6 characters',
  [AppTranslation['login.input.username.error.invalid-value']]: 'Invalid email',
  [AppTranslation.copyright]: '© {year} Norsk Heiskontroll AS',
  [AppTranslation['privacy-policy']]: 'Privacy policy',
  [AppTranslation['elevator.tab-name.info']]: 'Information',
  [AppTranslation['elevator.tab-name.logbook']]: 'Digital logbook',
  [AppTranslation['elevator.tab.logbook.search-input.placeholder']]: 'Search the logbook',
  [AppTranslation['date-input.label.from']]: 'Date from',
  [AppTranslation['date-input.label.to']]: 'Date to',
  [AppTranslation['date-input.clear-button.title']]: 'Clear',
  [AppTranslation['elevator.tab.logbook.category.title']]: 'Category',
  [AppTranslation['elevator.tab.logbook.subcategory.title']]: 'Subcategory',
  [AppTranslation['elevator.tab.logbook.deviation.title']]: 'Deviation',
  [AppTranslation['elevator.tab.logbook.search-input.filter-button']]: 'Add filter to search',
  [AppTranslation['elevator.tab.logbook.category-select.placeholder']]: 'Select a category',
  [AppTranslation['elevator.tab.logbook.subcategory-select.placeholder']]: 'Select a subcategory',
  [AppTranslation['elevator.tab.logbook.text-select.placeholder']]: 'Select a text',
  [AppTranslation['elevator.tab.logbook.signedBy-select.placeholder']]: 'Please select',
  [AppTranslation['elevator.tab.logbook.search-input.signedBy-select.label']]: 'Performed by',
  [AppTranslation['elevator.tab.logbook.table.title']]: 'All logs',
  [AppTranslation['elevator.tab.logbook.table.header.date']]: 'Date',
  [AppTranslation['elevator.tab.logbook.description.title']]: 'Description',
  [AppTranslation['elevator.tab.logbook.description.title.max-length']]: ' (max {length} characters)',
  [AppTranslation['elevator.tab.logbook.text.title']]: 'Proposal texts',
  [AppTranslation['elevator.tab.logbook.signedBy.title']]: 'Performed by',
  [AppTranslation['elevator.tab.logbook.createdByCompany.title']]: 'Company',
  [AppTranslation['elevator.tab.logbook.attachments.title']]: 'Attachments',
  [AppTranslation['elevator.tab.logbook.button.create-entry']]: 'New logging',
  [AppTranslation['elevator.tab.logbook.elevator.niNumber']]: 'Ninr: {niNumber}',
  [AppTranslation['elevator.tab.logbook.modal.entry-details.header.logging']]: 'Logging',
  [AppTranslation['elevator.tab.logbook.modal.create-entry.header.new-log']]: 'Create a new log for',
  [AppTranslation['elevator.tab.logbook.modal.create-entry.submit-button']]: 'Sign and submit logging',
  [AppTranslation['elevator.tab.logbook.modal.create-entry.file-upload.text.part1']]: 'Drag and drop files here, or ',
  [AppTranslation['elevator.tab.logbook.modal.create-entry.file-upload.text.part2']]: 'find the file',
  [AppTranslation['elevator.tab.logbook.modal.create-entry.form.description.error-msg']]: 'Description is required',
  [AppTranslation['elevator.tab.logbook.modal.create-entry.form.category.error-msg']]: 'Category is required',
  [AppTranslation['elevator.tab.logbook.modal.create-entry.form.subCategory.error-msg']]: 'Subcategory is required',
  [AppTranslation['elevator.tab.logbook.modal.create-entry.form.elevatorId.error-msg']]: 'Elevator id is required',
  [AppTranslation['elevator.tab.logbook.table.noData']]: 'No data',
  [AppTranslation['elevator.tab.logbook.modal.delete-entry.title']]: 'Delete logbook entry?',
  [AppTranslation['elevator.tab.logbook.modal.delete-entry.text']]:
    'Are you sure you want to delete the logbook entry?',
  [AppTranslation['elevator.tab.logbook.delete-entry.msg.success']]: 'Logbook entry deleted',
  [AppTranslation['elevators.page-title']]: 'Your objects',
  [AppTranslation['elevators.page.add-new-object']]: 'Add new object',
  [AppTranslation['elevators.page.tab-name.all']]: 'All objects',
  [AppTranslation['elevators.page.tab-name.favorites']]: 'Favorites',
  [AppTranslation['elevators.page.tab-name.archived']]: 'Archived',
  [AppTranslation['elevators.page.type']]: 'Type',
  [AppTranslation['elevators.page.niNumber']]: 'NInumber',
  [AppTranslation['elevators.page.reference']]: 'Own reference',
  [AppTranslation['elevators.page.last-logging']]: 'Last logging',
  [AppTranslation['elevators.page.account']]: 'Owner',
  [AppTranslation['elevators.page.address']]: 'Address',
  [AppTranslation['elevators.page.search-input.placeholder']]: 'Search your objects',
  [AppTranslation['elevators.page.search-input.dropdown.elevator.type']]: 'Type of lifting device',
  [AppTranslation['elevators.page.search-input.dropdown.fabrication-number']]: 'Fabrication number',
  [AppTranslation['elevators.page.search-input.dropdown.account.placeholder']]: 'Search owner',
  [AppTranslation['elevators.page.search-input.dropdown.fabrication-number-input.placeholder']]:
    'Search the fabrication number',
  [AppTranslation['elevators.page.search-input.dropdown.elevator-type-select.placeholder']]:
    'Search the type of lifting device',
  [AppTranslation['elevators.page.search-input.dropdown.elevator-manufacturer-select.placeholder']]:
    'Search in the manufacturer',
  [AppTranslation['elevators.page.search-input.dropdown.elevator-model-select.placeholder']]: 'Search in the model',
  [AppTranslation['elevators.page.search-input.dropdown.niNumber-input.placeholder']]: 'Search the niNumber',
  [AppTranslation['elevators.page.tab.all.text']]: 'Showing all {quantity} objects',
  [AppTranslation['elevators.page.tab.loaded-elevators.text']]:
    'Showing {loadedQuantity} of a total of {totalQuantity} objects',
  [AppTranslation['elevators.page.tab.all.text.for-one-object']]: 'Showing 1 object',
  [AppTranslation['elevators.page.tab.all.filtering-text']]: 'Search results for {freeText}',
  [AppTranslation['elevators.page.tab.all.checked-elevators']]: '{quantity} 2 objects marked',
  [AppTranslation['elevators.page.tab.all.checked-elevators.for-one-object']]: '1 object marked',
  [AppTranslation['elevator.page.settings-button.edit-object']]: 'Edit object',
  [AppTranslation['elevator.page.settings-button.give-access']]: 'Give access',
  [AppTranslation['elevator.page.settings-button.generate-qr-code']]: 'Generate QR code',
  [AppTranslation['elevator.page.settings-button.archive-object']]: 'Archive object',
  [AppTranslation['elevator.give-access']]: 'Give access',
  [AppTranslation['elevator.update-information']]: 'Update information',
  [AppTranslation['elevator.give-access.company']]: 'Provide company access',
  [AppTranslation['elevator.modal.give-access.subtitle']]: 'Access control',
  [AppTranslation['elevator.modal.give-access.user.title']]: 'Person',
  [AppTranslation['elevator.modal.give-access.company.title']]: 'Service company / Company',
  [AppTranslation['elevator.modal.give-access.user.select.placeholder']]: 'Select a person',
  [AppTranslation['elevator.modal.give-access.company.select.placeholder']]: 'Select a company',
  [AppTranslation['elevators.modal.give-access.button.title']]: 'Provide access to selected objects',
  [AppTranslation['elevator.modal.give-access.button.title']]: 'Provide access to this object',
  [AppTranslation['elevators.modal.give-access.save-button.title']]: 'Save  changes',
  [AppTranslation['elevators.modal.give-access.company.confirm.title']]: 'Save changes?',
  [AppTranslation['elevators.modal.give-access.company.confirm.content']]:
    'You are in the process of making changes that either involve a new company gaining access to elevator objects or updating information on elevator objects.\n\n' +
    'Are you sure you want to save your changes?',
  [AppTranslation['elevator.err-msg.required-elevators']]: 'Please select object',
  [AppTranslation['elevator.err-msg.form-field-values']]: 'At least one value should be selected',
  [AppTranslation['elevator.modal.favorite.header.title']]: 'Add to favorites',
  [AppTranslation['elevator.modal.favorite.header.subtitle']]: 'Favorites',
  [AppTranslation['elevator.modal.favorite.content.select.title']]: 'Select favorite list',
  [AppTranslation['elevator.modal.favorite.content.button.title']]: 'Add object to list',
  [AppTranslation['elevator.tab.information.commissioned.title']]: 'Commissioned:',
  [AppTranslation['elevator.tab.information.last-logging.title']]: 'Last logging:',
  [AppTranslation['elevator.tab.information.modernized.title']]: 'Modernized:',
  [AppTranslation['elevator.tab.information.factory-number.title']]: 'Factory number:',
  [AppTranslation['elevator.tab.information.controlled.title']]: 'Controlled:',
  [AppTranslation['elevator.tab.information.control-and-security-services.title']]: 'Control and security services:',
  [AppTranslation['elevator.tab.information.service-agreement.title']]: 'Service agreement:',
  [AppTranslation['elevator.tab.information.alarm-company.title']]: 'Alarm company:',
  [AppTranslation['elevator.tab.information.alarm-type.title']]: 'Alarm type:',
  [AppTranslation['elevator.tab.information.alarm-line-and-line-provider.title']]: 'Alarm line and line provider:',
  [AppTranslation['elevator.tab.information.alarm-unit-phone-number.title']]: 'Alarm line and line provider:',
  [AppTranslation['elevator.tab.information.open-map-button']]: 'Open map in full view',
  [AppTranslation['elevators.tab.favorites.elevator.type']]: 'Type',
  [AppTranslation['elevators.tab.favorites.elevator.NInummer']]: 'NInummer',
  [AppTranslation['elevators.tab.favorites.elevator.address']]: 'Adresse',
  [AppTranslation['elevators.tab.favorites.elevator.reference']]: 'Own reference',
  [AppTranslation['elevators.tab.favorites.elevator.lastLogging']]: 'Last logging',
  [AppTranslation['elevators.tab.favorites.button.add-favorite-list']]: 'Create a new list',
  [AppTranslation['elevators.tab.favorites.page-title']]: 'Favorites',
  [AppTranslation['elevators.tab.favorites.modal.header.title']]: 'Create a new list',
  [AppTranslation['elevators.tab.favorites.modal.header.subtitle']]: 'Favorites',
  [AppTranslation['elevators.tab.favorites.modal.button.title']]: 'Create a new favorite list',
  [AppTranslation['elevators.tab.favorites.modal.input.name']]: 'Name',
  [AppTranslation['elevators.tab.favorites.modal.input.placeholder']]: 'Enter name',
  [AppTranslation['elevators.tab.favorites.modal.input.error']]: 'Name is required',
  [AppTranslation['reset-password.success-message']]: 'Password recovery email has been sent to your email',
  [AppTranslation['reset-password.page-title']]: 'Forgot your password?',
  [AppTranslation['reset-password.page-subtitle']]:
    'If you have forgotten your password, you can reset it by entering your email address below.',
  [AppTranslation['reset-password.email-input.error-msg']]: 'Invalid email',
  [AppTranslation['reset-password.email-input.placeholder']]: 'Email',
  [AppTranslation['reset-password.submit-button']]: 'Send',
  [AppTranslation['change-password.title']]: 'Create a new password',
  [AppTranslation['change-password.subtitle']]: 'Enter your new password',
  [AppTranslation['change-password.input.password.placeholder']]: 'Password',
  [AppTranslation['change-password.input.password.err-msg.required']]: 'Password is required',
  [AppTranslation['change-password.input.password.err-msg.invalid-value']]:
    'Invalid password, the number of characters should be at least 6',
  [AppTranslation['change-password.input.password-confirmation.placeholder']]: 'Confirm password',
  [AppTranslation['change-password.input.password-confirmation.err-msg.invalid-value']]: 'Password mismatch',
  [AppTranslation['change-password.submit-button']]: 'Create a new password',
  [AppTranslation['change-password.request.success']]: 'Password changed successfully',
  [AppTranslation['change-password.external-user.success.title']]: 'Password changed',
  [AppTranslation['change-password.external-user.success.subtitle']]:
    'Please log in with the updated password in the app',
  [AppTranslation['change-password.token.err-msg.invalid-token']]: 'Invalid token',
  [AppTranslation['elevator.form.tab.info.title']]: '{indexNumber}. Object information',
  [AppTranslation['elevator.form.tab.contact.title']]: '{indexNumber}. Contact persons / Supervisor',
  [AppTranslation['elevator.form.tab.control.title']]: '{indexNumber}. Control, inspection and alarm',
  [AppTranslation['elevator.form.tab.location.title']]: '{indexNumber}. Location',
  [AppTranslation['elevator.form.tab.summary.title']]: '{indexNumber}. Summary',
  [AppTranslation['elevator.form.address']]: 'Address',
  [AppTranslation['elevator.form.address.err-msg']]: 'Address is required',
  [AppTranslation['elevator.form.alarm-company.placeholder']]: 'Select an alarm company',
  [AppTranslation['elevator.form.alarm-type.placeholder']]: 'Select an alarm type',
  [AppTranslation['elevator.form.alarm-line-type.placeholder']]: 'Select an alarm line type',
  [AppTranslation['elevator.form.fabrication-number.err-msg']]: 'Fabrication number is required',
  [AppTranslation['elevator.form.fabrication-year.err-msg.required']]: 'Year of fabrication is required',
  [AppTranslation['elevator.form.fabrication-year.err-msg.wrong-value']]: 'Year of fabrication is wrong',
  [AppTranslation['elevator.form.model.err-msg']]: 'Model name is required',
  [AppTranslation['elevator.form.model.placeholder']]: 'Select a model name',
  [AppTranslation['elevator.form.manufacturer.err-msg']]: 'Manufacturer is required',
  [AppTranslation['elevator.form.manufacturer.placeholder']]: 'Select a manufacturer',
  [AppTranslation['elevator.form.type.err-msg']]: 'Type of lifting device is required',
  [AppTranslation['elevator.form.type.placeholder']]: 'Select a type of lifting device',
  [AppTranslation['elevator.form.reference']]: 'Own reference (For example, the location in the building)',
  [AppTranslation['elevator.form.reference.err-msg']]:
    'Own reference (For example, the location in the building) is required',
  [AppTranslation['elevator.form.location-map']]: 'Upload floor plan / location map',
  [AppTranslation['elevator.form.renovation-year.err-msg.wrong-value']]: 'Year is wrong',
  [AppTranslation['elevator.form.private-room-elevator-access']]: 'Elevator access through private rooms?',
  [AppTranslation['elevator.form.ni-number.err-msg']]: 'Ni number is required',
  [AppTranslation['elevator.form.safety-inspection-body.err-msg']]: 'Safety inspection company is required',
  [AppTranslation['elevator.form.safety-inspection-body.placeholder']]: 'Select a safety inspection company',
  [AppTranslation['elevator.form.inspection-company.err-msg']]: 'Inspection company is required',
  [AppTranslation['elevator.form.inspection-company.placeholder']]: 'Select a inspection company',
  [AppTranslation['elevator.form.phone']]: 'Telephone number for the alarm central',
  [AppTranslation['elevator.form.mazemap-url']]: 'Mazemap URL (Indoor Map View)',
  [AppTranslation['elevator.form.mazemap-url.err-msg']]: 'Invalid URL',
  [AppTranslation['elevator.form.cancel-button']]: 'Cancel',
  [AppTranslation['elevator.form.next-step-button']]: 'Next step',
  [AppTranslation['elevator.form.submit-button']]: 'Add object',
  [AppTranslation['elevator.form.line-provider.placeholder']]: 'Select a line provider',
  [AppTranslation['elevator.form.radio-button.yes']]: 'Yes',
  [AppTranslation['elevator.form.radio-button.no']]: 'No',
  [AppTranslation['elevator.form.upload-button']]: 'Choose File',
  [AppTranslation['elevator.form.search-user-select.loading']]: 'Loading...',
  [AppTranslation['elevator.form.search-user-select.no-data']]: 'no options',
  [AppTranslation['elevator.form.search-user-select.title']]: 'People added as contact persons',
  [AppTranslation['elevator.form.contact-person.name']]: 'Name',
  [AppTranslation['elevator.form.contact-person.mobile']]: 'Mobile',
  [AppTranslation['elevator.form.contact-person.mail']]: 'E-mail',
  [AppTranslation['elevator.form.contact-person.role']]: 'Role',
  [AppTranslation['elevator.form.contact-person.button.remove']]: 'Remove',
  [AppTranslation['elevator.form.err-msg.required-fields']]: 'Please fill in all required fields',
  [AppTranslation['edit-elevator.page.title']]: 'Edit object',
  [AppTranslation['edit-elevator.form.submit-button']]: 'Edit object',
  [AppTranslation['save-elevator.form.submit-button']]: 'Save object',
  [AppTranslation['edit-elevator.form.success-msg']]: 'Operation is successful',
  [AppTranslation['edit-elevator.form.success-msg.location-map']]: 'Location map is updated',
  [AppTranslation['elevator.form.tab.summary.block.object-info.title']]: 'Object information',
  [AppTranslation['elevator.form.tab.summary.block.contact-person.title']]: 'Contact person / Supervisor',
  [AppTranslation['elevator.form.tab.summary.block.control.title']]: 'Control, inspection and alarm',
  [AppTranslation['elevator.form.tab.summary.block.location.title']]: 'Location',
  [AppTranslation['elevator.address']]: 'Invoice address',
  [AppTranslation['elevator.alarm-company']]: 'Alarm company',
  [AppTranslation['elevator.alarm-type']]: 'Alarm type',
  [AppTranslation['elevator.alarm-line-type']]: 'Alarm line type',
  [AppTranslation['elevator.fabrication-number']]: 'Fabrication number',
  [AppTranslation['elevator.fabrication-year']]: 'Year of fabrication',
  [AppTranslation['elevator.model']]: 'Model name',
  [AppTranslation['elevator.manufacturer']]: 'Manufacturer name',
  [AppTranslation['elevator.type']]: 'Type of lifting device',
  [AppTranslation['elevator.reference']]: 'Own reference',
  [AppTranslation['elevator.location-map']]: 'Floor plan',
  [AppTranslation['elevator.renovation-year']]: 'Modernized',
  [AppTranslation['elevator.private-room-elevator-access']]: 'Elevator access through private rooms?',
  [AppTranslation['elevator.ni-number']]: 'Ni number',
  [AppTranslation['elevator.contact-person']]: 'Contact person',
  [AppTranslation['elevator.safety-inspection-body']]: 'Safety inspection company',
  [AppTranslation['elevator.inspection-company']]: 'Inspection company',
  [AppTranslation['elevator.phone']]: 'Telephone number for the alarm central',
  [AppTranslation['elevator.mazemap-url']]: 'Mazemap URL',
  [AppTranslation['elevator.line-provider']]: 'Line provider',
  [AppTranslation['user.first-name']]: 'First name',
  [AppTranslation['user.last-name']]: 'Last name',
  [AppTranslation['user.telephone-number']]: 'Telephone number',
  [AppTranslation['user.email']]: 'Email',
  [AppTranslation['user.role']]: 'Role',
  [AppTranslation['user.organization']]: 'Organization',
  [AppTranslation['user.logged-in']]: 'Logged in',
  [AppTranslation['user.initials']]: 'Initials',
  [AppTranslation['user.employee-number']]: 'Employee number',
  [AppTranslation['user.password']]: 'Password',
  [AppTranslation['user.confirmPassword']]: 'Confirm password',
  [AppTranslation['user.first-name.error-message.required']]: 'First name is required',
  [AppTranslation['user.last-name.error-message.required']]: 'Last name is required',
  [AppTranslation['user.company-name.error-message.required']]: 'Organization is required',
  [AppTranslation['user.telephone-number.error-message.required']]: 'Telephone number is required',
  [AppTranslation['user.email.error-message.required']]: 'Email is required',
  [AppTranslation['user.role.error-message.required']]: 'Role is required',
  [AppTranslation['user.initials.error-message.required']]: 'Initials are required',
  [AppTranslation['user.employee-number.error-message.required']]: 'Employee number is required',
  [AppTranslation['select.no-options']]: 'No options',
  [AppTranslation['settings.page-title']]: 'Settings',
  [AppTranslation['settings.tab.userManagement']]: 'User management',
  [AppTranslation['settings.tab.companies']]: 'Business approaches',
  [AppTranslation['settings.tab.invoiceInformation']]: 'Invoice information',
  [AppTranslation['settings.tab.productsSubscription']]: 'Subscription and product',
  [AppTranslation['settings.tab.productsSubscription.product.name']]: 'Product name',
  [AppTranslation['settings.tab.productsSubscription.product.price']]: 'Price',
  [AppTranslation['settings.tab.productsSubscription.product.quantity']]: 'Quantity',
  [AppTranslation['settings.tab.productsSubscription.product.discount']]: 'Discount',
  [AppTranslation['settings.tab.productsSubscription.product.subscription-start-date']]: 'Subscription start date',
  [AppTranslation['settings.tab.productsSubscription.product.invoicing-start-date']]: 'Invoicing start date',
  [AppTranslation['settings.tab.productsSubscription.product.renewal-date']]: 'Renewal date',
  [AppTranslation['settings.tab.productsSubscription.product.total-price']]: 'Total price',
  [AppTranslation['settings.tab.userManagement.search-input.placeholder']]: 'User search by first name and email',
  [AppTranslation['settings.tab.userManagement.content-title']]: 'Users',
  [AppTranslation['settings.tab.userManagement.user-titles.name']]: 'Name',
  [AppTranslation['settings.tab.userManagement.user-titles.email']]: 'Email',
  [AppTranslation['settings.tab.userManagement.user-titles.phone']]: 'Phone',
  [AppTranslation['settings.tab.userManagement.user-titles.role']]: 'Role',
  [AppTranslation['settings.tab.userManagement.user-titles.status']]: 'Status',
  [AppTranslation['settings.tab.userManagement.user-titles.status.active']]: 'Active',
  [AppTranslation['settings.tab.userManagement.user-titles.status.inactive']]: 'Inactive',
  [AppTranslation['settings.tab.userManagement.button.create-user']]: 'Add new user',
  [AppTranslation['user.deactivate.msg.success']]: 'User is disabled',
  [AppTranslation['user.activate.msg.success']]: 'User is enabled',
  [AppTranslation['user.delete.err-msg.required-fields']]: 'Please select a user',
  [AppTranslation['settings.tab.userManagement.data-title']]: 'Users',
  [AppTranslation['settings.tab.userManagement.modal.delete-user.title']]: 'Delete user?',
  [AppTranslation['settings.tab.userManagement.modal.delete-user.text']]: 'Are you sure you want to delete the user?',
  [AppTranslation['settings.tab.userManagement.modal.create-user.title']]: 'Add a new user',
  [AppTranslation['settings.tab.userManagement.modal.create-user.subtitle']]: 'User management',
  [AppTranslation['settings.tab.userManagement.modal.create-user.button.title']]: 'Create a new user',
  [AppTranslation['settings.tab.userManagement.modal.edit-user.title']]: 'Edit user',
  [AppTranslation['settings.tab.userManagement.modal.edit-user.button.title']]: 'Save changes',
  [AppTranslation['settings.tab.userManagement.modal.edit-user.success']]: 'The role was updated',
  [AppTranslation['settings.tab.userManagement.modal.edit-user-role.text']]:
    "You are now giving {userName} the Account owner role for this account. By confirming, your user's role will change from Account Owner to Account administrator. Please confirm.",
  [AppTranslation['settings.tab.userManagement.modal.edit-user-role.title']]: 'Save changes?',
  [AppTranslation['button.delete.title']]: 'Yes, delete',
  [AppTranslation['button.cancel.title']]: 'Cancel',
  [AppTranslation['email.error-message.invalid-value']]: 'Email is wrong',
  [AppTranslation['settings.tab.userManagement.modal.edit-user.activate-user-button.title']]: 'Enable user',
  [AppTranslation['settings.tab.userManagement.modal.edit-user.deactivate-user-button.title']]: 'Disable user',
  [AppTranslation['user.delete.msg.success']]: 'User deleted',
  [AppTranslation['login-link.title']]: 'Return to the login page',
  [AppTranslation['profile.page-title']]: 'Your profile',
  [AppTranslation['profile.edit-modal.title']]: 'Edit profile',
  [AppTranslation['profile.edit-modal.subtitle']]: 'Your profile',
  [AppTranslation['user.change-password']]: 'Change password',
  [AppTranslation['profile.change-password.modal.old-password']]: 'Old password',
  [AppTranslation['profile.change-password.modal.new-password']]: 'New password',
  [AppTranslation['profile.change-password.modal.repeat-password']]: 'Repeat password',
  [AppTranslation['profile.change-password.modal.input.old-password.err-msg']]: 'Invalid old password',
  [AppTranslation['profile.change-password.modal.input.new-password.err-msg']]:
    'Invalid new password, the number of characters should be at least 6',
  [AppTranslation['profile.change-password.modal.input.repeat-password.err-msg']]: 'Password mismatch',
  [AppTranslation['profile.avatar.button.upload-image']]: 'Upload new image',
  [AppTranslation['profile.avatar.button.delete-image']]: 'Delete',
  [AppTranslation['profile.avatar.upload.error']]: 'Please upload an image',
  [AppTranslation['profile.avatar.upload.success']]: 'Profile image updated successfully',
  [AppTranslation['profile.avatar.delete.success']]: 'Profile image deleted successfully',
  [AppTranslation['archive-elevators.modal.button.confirm']]: 'Yes, archive objects',
  [AppTranslation['archive-elevators.modal.content']]: 'Are you sure you want to archive objects?',
  [AppTranslation['archive-elevators.modal.header']]: 'Archive objects?',
  [AppTranslation['archive-elevator.modal.button.confirm']]: 'Yes, archive object',
  [AppTranslation['archive-elevator.modal.content']]: 'Are you sure you want to archive object?',
  [AppTranslation['archive-elevator.modal.header']]: 'Archive object?',
  [AppTranslation['un-archive-elevators.modal.button.confirm']]: 'Yes, un-archive objects',
  [AppTranslation['un-archive-elevators.modal.content']]: 'Are you sure you want to un-archive objects?',
  [AppTranslation['un-archive-elevators.modal.header']]: 'Un-archive objects?',
  [AppTranslation['un-archive-elevator.modal.button.confirm']]: 'Yes, un-archive object',
  [AppTranslation['un-archive-elevator.modal.content']]: 'Are you sure you want to un-archive object?',
  [AppTranslation['un-archive-elevator.modal.header']]: 'Un-archive object?',
  [AppTranslation['elevators.tab.archived.data-title']]: 'Archived objects',
  [AppTranslation['cancel']]: 'Cancel',
  [AppTranslation['success.message']]: 'Operation successful',
  [AppTranslation['elevator.archived-elevator-message']]: 'This object has been archived. To unlock it {button}',
  [AppTranslation['elevator.un-archive-elevator-button.title']]: 'click here',
  [AppTranslation['account.billingCompanyName']]: 'Company name',
  [AppTranslation['account.billingOrganizationNumber']]: 'Organization number',
  [AppTranslation['account.billingAddress']]: 'Invoice address',
  [AppTranslation['account.billingContactName']]: 'Contact person',
  [AppTranslation['account.billingEmail']]: 'Email for invoice',
  [AppTranslation['account.invoiceReference']]: 'Invoice reference',
  [AppTranslation['account.membershipType']]: 'Subscription type',
  [AppTranslation['error-message.required']]: 'This field is required',
  [AppTranslation['error-message.invalid-email']]: 'Email is invalid',
  [AppTranslation['save']]: 'Save',
  [AppTranslation['save-changes']]: 'Save changes',
  [AppTranslation['logout.modal.buttons.confirm']]: 'Yes',
  [AppTranslation['logout.modal.header']]: 'Log out',
  [AppTranslation['logout.modal.content']]: 'Are you sure you want to log out of your account?',
  [AppTranslation['elevators.tab.favorites.modal.edit-delete-list.title']]: 'Edit list',
  [AppTranslation['elevators.tab.favorites.modal.edit-delete-list.delete-button']]: 'Delete list',
  [AppTranslation['select-value']]: 'Select value',
  [AppTranslation['settings.back-button']]: 'Back',
  [AppTranslation['settings.search-input.placeholder']]: 'Search for object',
  [AppTranslation['settings.user.table.title']]: 'Has access to the following objects',
  [AppTranslation['yes']]: 'Yes',
  [AppTranslation['remove-access-modal.users.header']]: 'Remove access',
  [AppTranslation['remove-access-to-elevators-modal.users.content']]:
    "Are you sure you want to remove the user's access to the selected objects?",
  [AppTranslation['remove-access-to-elevator-modal.users.content']]:
    "Are you sure you want to remove the user's access to the selected object?",
  [AppTranslation['user.last-logged-in']]: 'Logged in',
  [AppTranslation['create-external-user.title']]: 'Register service user',
  [AppTranslation['create-external-user.subtitle']]: 'Get started with LILO by creating an account',
  [AppTranslation['create-external-user.button']]: 'Create account',
  [AppTranslation['create-external-user.send-email-part.subtitle']]:
    'To access the Lilo digital logbook, you can enter your work email. This applies to those who need access to elevators and escalators linked to your service company.',
  [AppTranslation['create-external-user.send-email-part.text-with-link']]:
    'If you are a elevator owner and want to create a subscription, {link}',
  [AppTranslation['create-external-user.send-email-part.link.title']]: 'click here',
  [AppTranslation['create-external-user.send-email-part.button']]: 'Send instructions',
  [AppTranslation['create-external-user.send-email-part.success-message']]:
    'Account creation instructions successfully sent to your email {email}',
  [AppTranslation['create-external-user.send-email-part.email-error']]: 'Email is required!',
  [AppTranslation['create-external-user.form.title']]: 'Fill in the necessary information in the fields below',
  [AppTranslation['create-external-user.form.subtitle']]: 'The account you create applies to the email {email}',
  [AppTranslation['create-external-user.form.privacyPolicy']]: 'privacy policy',
  [AppTranslation['create-external-user.form.terms']]: 'the terms of use',
  [AppTranslation['create-external-user.form.terms-policy-confirmation']]:
    'By clicking Create account, you indicate that you have read and accept {terms} and {privacyPolicy}.',
  [AppTranslation['create-external-user.success-part.heading']]: 'Your account had been created',
  [AppTranslation['create-external-user.success-part.title']]: 'Please install the app Lilo - Digital Logbook',
  [AppTranslation['create-external-user.success-part.subtitle']]: 'Log in with your username and password',
  [AppTranslation['create-external-user.success-part.block.title']]: 'Download the app',
  [AppTranslation['create-external-user.success-part.block.subtitle']]:
    'Go to the login page to log in and start using your account',
  [AppTranslation['create-external-user.success-part.login-link']]: 'Go to the login page',
  [AppTranslation['create-external-user.token-error']]: 'Invalid token',
  [AppTranslation['settings.tab.companies.access-modal.title']]: 'Give access to company',
  [AppTranslation['settings.tab.companies.access-modal.input-label']]: 'Select company',
  [AppTranslation['settings.tab.companies.data-title']]: 'Companies with access',
  [AppTranslation['settings.tab.companies.modal.remove-access.text']]:
    "Are you sure you want to remove the company's access to your account?",
  [AppTranslation['settings.tab.companies.modal.remove-access.header']]: 'Remove access',
  [AppTranslation['company.name']]: 'Name',
  [AppTranslation['company.role']]: 'Role',
  [AppTranslation['company.emailMask']]: 'Email domain',
  [AppTranslation['company.email']]: 'Email',
  [AppTranslation['company.address']]: 'Address',
  [AppTranslation['company.organizationNumber']]: 'Organization number',
  [AppTranslation['company.lastLoginAt']]: 'Logged in',
  [AppTranslation['settings.tab.companies.search-input.placeholder']]: 'Search company',
  [AppTranslation['remove-access-to-elevators-modal.company.content']]:
    "Are you sure you want to remove the company's access to the selected objects?",
  [AppTranslation['remove-access-to-elevator-modal.company.content']]:
    "Are you sure you want to remove company's access to the selected object?",
  [AppTranslation['deleted-company-tooltip.title']]: "{elevator.inspection-company} isn't available",
  [AppTranslation['deleted-company-tooltip.text1']]: '{entityName} is no longer available in the system.',
  [AppTranslation['deleted-company-tooltip.text2']]: 'By editing the object, you can select a new {entityName}.',
  [AppTranslation['deleted-company.warn-msg']]:
    'The company you selected is no longer available, please select another',
  [AppTranslation['deleted-company.message']]: 'Not available',
  [AppTranslation['tooltip.archive-object']]: 'archive an object',
  [AppTranslation['tooltip.unarchive-object']]: 'unarchive an object',
  [AppTranslation['tooltip.export-logbook']]: 'export the entire logbook',
  [AppTranslation['tooltip.add-company-access-to-objects']]: 'add company access to objects',
  [AppTranslation['tooltip.add-user-access-to-objects']]: 'add user access to objects',
  [AppTranslation['tooltip.add-to-favorites']]: 'add to favorites',
  [AppTranslation['tooltip.remove-from-favorites']]: 'add to favorites',
  [AppTranslation['tooltip.settings']]: 'settings',
  [AppTranslation['tooltip.remove-access-to-objects']]: 'remove access to objects',
  [AppTranslation['tooltip.edit-favorite-list']]: 'edit/delete list',
  [AppTranslation['activities.content-title']]: 'Latest activities',
  [AppTranslation['activities.page-title']]: 'Welcome, {userName}',
  [AppTranslation['activities.types.new-object.text']]: 'Created a new object',
  [AppTranslation['activities.types.new-log-entry.text']]: 'Created a new log entry in the logbook',
  [AppTranslation['activities.types.new-user-created.text']]: 'Add a new user',
  [AppTranslation['activities.types.object-archived.text']]: 'Archived an object',
  [AppTranslation['activities.types.object-un-archived.text']]: 'Reopened an archived object',
  [AppTranslation['activities.types.add-company-access.text']]: 'Provided access to a new service company',
  [AppTranslation['activities.types.new-object.title']]: 'New object created',
  [AppTranslation['activities.types.new-log-entry.title']]: 'New logbook entry',
  [AppTranslation['activities.types.new-user-created.title']]: 'New user added',
  [AppTranslation['activities.types.object-archived.title']]: 'Archived an object',
  [AppTranslation['activities.types.object-un-archived.title']]: 'Unarchived an object',
  [AppTranslation['activities.types.add-company-access.title']]: 'New service company added',
  [AppTranslation['activities.types-select.placeholder']]: 'Select activity status',
  [AppTranslation['activities.types-select.default-option']]: 'All activities',
  [AppTranslation['page-not-found.title']]: 'The page does not exist.',
  [AppTranslation['page-not-found.description']]:
    'The page you tried to find at this address is not available.\n' +
    'Use one of the links in the side menu or go back to the {homePageLink}.',
  [AppTranslation['page-not-found.home-page-link']]: 'home page',
  [AppTranslation['user.company-name']]: 'Organization',
  [AppTranslation['tooltip.deleted-user.title']]: 'The user does not exist',
  [AppTranslation['tooltip.deleted-user.description']]:
    'This user is no longer available in the system. To proceed, you must delete the user from the table.',
  [AppTranslation['elevator.form.contact-persons.err-msg.wrong-value']]:
    'This user is no longer available in the system. To proceed, you must delete the user from the table.',
  [AppTranslation['aria-label.input.password']]: 'Password',
  [AppTranslation['aria-label.input.confirm-password']]: 'Password confirmation',
  [AppTranslation['aria-label.input.email']]: 'Email',
  [AppTranslation['aria-label.user-image']]: 'User image',
  [AppTranslation['aria-label.profile-image']]: 'Profile image',
  [AppTranslation['aria-label.button.deleted-company-info-message']]: 'informational message about a deleted company',
  [AppTranslation['aria-label.mazeMap-image']]: 'MazeMap image',
  [AppTranslation['aria-label.mazeMap']]: 'MazeMap',
  [AppTranslation['aria-label.button.remove-logbook-entry-modal']]: 'Remove logbook entry',
  [AppTranslation['aria-label.logbook-entry-file-icon']]: 'Logbook entry has files',
  [AppTranslation['aria-label.logbook-entry-image']]: 'Logbook entry image',
  [AppTranslation['aria-label.profile.settings-button']]: 'Settings',
  [AppTranslation['aria-label.settings.remove-company-access-button']]: 'Remove access for this company',
  [AppTranslation['aria-label.button.open-edit-invoice-info-modal']]:
    'Open a modal window for editing invoice information',
  [AppTranslation['aria-label.button.edit-user']]: 'Open a modal window to edit user',
  [AppTranslation['aria-label.settings.remove-user-access-button']]:
    'Open a modal window to remove access from the user',
  [AppTranslation['aria-label.search-input.button.open-dropdown']]: 'Open search input drop-down menu',
  [AppTranslation['aria-label.button.close-modal']]: 'Close modal window',
  [AppTranslation['elevators.select.inspection-company.placeholder']]: 'Search for an inspection company',
  [AppTranslation['user.re-activate.msg.success']]: 'Your account has been re-activated. You can log in as normal.',
  [AppTranslation['account-switcher.content-title']]: 'Select company',
  [AppTranslation['account-switcher.content-description']]:
    'You have access to multiple companies, please select which company you wish to log in to.',
  [AppTranslation['account-switcher.organization-number']]: 'Organization number: {organizationNumber}',
  [AppTranslation['account-switcher.search-input.placeholder']]: 'Search for a company',
};
