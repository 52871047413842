import { ReactComponent as LogoIcon } from '@ui-kit/assets/icons/Logo.svg';
import { themeMode } from '@ui-kit/theme';
import React from 'react';
import styled from 'styled-components';
import theme from 'styled-theming';

const svgColor = theme.variants('mode', 'variant', {
  primary: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen },
  secondary: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.white },
});

const StyledLogo = styled(LogoIcon)`
  & > *:first-child {
    fill: ${svgColor} !important;
  }
  & > g {
    fill: none;
    stroke: ${svgColor} !important;
  }
`;

interface LogoProps extends React.SVGProps<SVGSVGElement> {
  variant?: 'primary' | 'secondary';
}

export const Logo: React.FC<Omit<LogoProps, 'ref'>> = ({ variant = 'primary', ...props }) => (
  <StyledLogo {...props} variant={variant} />
);
