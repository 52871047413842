import { LoaderContainer, LoaderItemWrapper, LoaderOverlay } from '@ui-kit/loader/Loader.styles';
import { LoaderProps } from '@ui-kit/loader/Loader.types';
import { Spinner } from '@ui-kit/Spinner';
import React from 'react';

export const Loader = ({ size = 30, isVisible, className, showOverlay = true }: LoaderProps) => {
  return (
    <LoaderContainer isVisible={isVisible} className={className}>
      {showOverlay && <LoaderOverlay className={'page-loader__overlay'} />}
      <LoaderItemWrapper>
        <Spinner size={size} />
      </LoaderItemWrapper>
    </LoaderContainer>
  );
};
