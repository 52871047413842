import { Overlay as UiOverlay } from '@ui-kit/overlay/Overlay.styles';
import React, { PropsWithChildren } from 'react';
import { DefaultTheme, FlattenInterpolation, ThemeProps } from 'styled-components';

export type BackgroundTypeOverlay = 'primary' | 'darken';

export interface OverlayProps {
  styles?: FlattenInterpolation<ThemeProps<DefaultTheme>>;
  backgroundType?: 'primary' | 'darken';
  className?: string;
}

export const OverlayInner = (
  { className, backgroundType = 'primary', ...props }: PropsWithChildren<OverlayProps>,
  ref: React.ForwardedRef<HTMLDivElement>,
) => <UiOverlay ref={ref} className={className} backgroundType={backgroundType} {...props} />;

export const Overlay = React.forwardRef(OverlayInner);
