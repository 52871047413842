import { Main, StyledLayout } from '@ui-kit/layout/Layout.styles';
import React from 'react';

interface LayoutProps {
  navBar?: React.ReactNode;
  footer?: React.ReactNode;
  className?: string;
}

export const Layout: React.FC<LayoutProps> = ({ children, navBar, footer, className }) => {
  return (
    <StyledLayout className={className}>
      <>
        {navBar}
        <Main>{children}</Main>
        {footer}
      </>
    </StyledLayout>
  );
};
