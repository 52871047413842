import { CaretArrow } from '@ui-kit/index';
import {
  EmptyPlaceholder,
  HeaderTableRow,
  InnerFixedHeader,
  TableBodyCell,
  TableContainer,
  TableHeaderCell,
  TableRow,
} from '@ui-kit/table/common';
import React from 'react';
import { HeaderGroup, Row, TableBodyPropGetter, TableBodyProps, TablePropGetter, TableProps } from 'react-table';

interface ITableProps<D extends object> {
  headerGroups: Array<HeaderGroup<D>>;
  getTableProps: (propGetter?: TablePropGetter<D>) => TableProps;
  getTableBodyProps: (propGetter?: TableBodyPropGetter<D>) => TableBodyProps;
  rows: Array<Row<D>>;
  onClickRow?: (original: D) => () => void;
  emptyDataText?: string;
  isStickyHeader?: boolean;
  isLoading?: boolean;
}

export const SimpleTable = <D extends object>(props: ITableProps<D>) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, onClickRow, emptyDataText, isStickyHeader, isLoading } =
    props;
  return (
    <TableContainer {...getTableProps()}>
      <InnerFixedHeader isStickyHeader={isStickyHeader}>
        {headerGroups?.map(headerGroup => (
          <HeaderTableRow {...headerGroup?.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => {
              const typeArrow = column?.isSorted ? (column?.isSortedDesc ? 'down' : 'up') : '';
              return (
                <TableHeaderCell
                  // @ts-ignore
                  mediaQueries={column.style?.mediaQueries}
                  tabIndex={column?.canSort === false ? -1 : 0}
                  onKeyDown={event => {
                    if (column?.canSort !== false && (event.key === 'Enter' || event.key === ' '))
                      column.toggleSortBy();
                  }}
                  {...column?.getHeaderProps([
                    {
                      // @ts-ignore
                      style: column?.style,
                    },
                    ...[column?.getSortByToggleProps ? column.getSortByToggleProps({ title: undefined }) : {}],
                  ])}
                >
                  {column.render('Header')}
                  {!column?.disableSortBy && typeArrow && (
                    <CaretArrow margin="0 0 0 10px" color="primary" type={typeArrow} />
                  )}
                </TableHeaderCell>
              );
            })}
          </HeaderTableRow>
        ))}
      </InnerFixedHeader>
      <div {...getTableBodyProps()}>
        {!rows?.length ? (
          <EmptyPlaceholder isLoading={isLoading} emptyDataText={emptyDataText} />
        ) : (
          <>
            {rows?.map(row => {
              const onEnter = (event: any) => {
                event.keyCode !== 13 || onClickRow?.(row.original)();
              };

              return (
                <TableRow
                  {...row.getRowProps()}
                  {...(onClickRow ? { onClick: onClickRow(row.original), tabIndex: 0 } : {})}
                  onKeyDown={onEnter}
                  isSelected={row?.isSelected}
                >
                  {row.cells.map(cell => {
                    return (
                      <TableBodyCell
                        // @ts-ignore
                        mediaQueries={cell.column.style?.mediaQueries}
                        {...cell.getCellProps([
                          {
                            // @ts-ignore
                            style: cell.column?.style,
                          },
                        ])}
                      >
                        {cell.render('Cell')}
                      </TableBodyCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </>
        )}
      </div>
    </TableContainer>
  );
};
