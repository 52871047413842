import { isServer } from '@ui-kit/helpers';

export const useStorageManager = (params: { key: string }) => {
  return {
    get item() {
      if (isServer()) return null;
      const code = localStorage.getItem(params.key);
      if (!code) return null;

      return code;
    },
    set item(value: string | null) {
      if (isServer()) return;

      if (value === null) {
        localStorage.removeItem(params.key);
        return;
      }

      localStorage.setItem(params.key, value);
    },
  };
};
