import { Menu } from '@szhsin/react-menu';
import { menuSelector } from '@szhsin/react-menu/style-utils';
import { themeMode, theme as uiTheme } from '@ui-kit/theme';
import styled, { keyframes } from 'styled-components';
import theme from 'styled-theming';

const menuShow = keyframes`
  from {
    opacity: 0;
  }
`;
const menuHide = keyframes`
  to {
    opacity: 0;
  }
`;

export const mainColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen,
});

export const background = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.white,
});

export const UiMenu = styled(Menu)`
  ${menuSelector.name} {
    border: 1px solid ${mainColor};
    border-radius: ${({ theme }) => theme.borderRadius.s};
    padding: 23px 25px 30px 25px;
    box-sizing: border-box;
    z-index: ${({ theme }) => theme.zIndex._7};
    background: ${background};
    display: flex;
    flex-direction: column;
    width: 350px;
  }
  ${menuSelector.stateOpening} {
    animation: ${menuShow} 0.15s ease-out;
  }

  ${menuSelector.stateClosing} {
    animation: ${menuHide} 0.2s ease-out forwards;
  }
  ${uiTheme.media.downWith.xxs} {
    ${menuSelector.name} {
      max-width: 319px;
    }
  }
`;
