import enGB from 'date-fns/locale/en-GB';
import nb from 'date-fns/locale/nb';

export enum LocaleType {
  enGB = 'enGB',
  no = 'no',
}

interface IMapLocale {
  [LocaleType.enGB]: Locale;
  [LocaleType.no]: Locale;
}

export const mapLocale: IMapLocale = {
  [LocaleType.enGB]: enGB,
  [LocaleType.no]: nb,
};
