import { MenuProps } from '@szhsin/react-menu';
import { UiMenu } from '@ui-kit/menu/regularMenu/RegularMenu.styles';
import React from 'react';

export const RegularMenu: React.FC<MenuProps> = ({ children, align, position, offsetY, menuButton, ...props }) => {
  return (
    <UiMenu
      {...props}
      menuButton={menuButton}
      align={align || 'end'}
      position={position || 'anchor'}
      offsetY={offsetY || 3}
    >
      {children}
    </UiMenu>
  );
};
