import { VIEWPORT_HEIGHT } from '@ui-kit/constants';
import { HeaderColorType } from '@ui-kit/dialog/Dialog';
import { themeMode } from '@ui-kit/theme';
import styled from 'styled-components';
import theme from 'styled-theming';

const wrapperBackground = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.white,
});

const headerBackground = theme.variants('mode', 'headerColor', {
  primary: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.cruelSeaGreen },
  secondary: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen },
});

export const Container = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.s};
  display: flex;
  flex-direction: column;
  background: ${wrapperBackground};
  overflow: hidden;
  width: 100%;
  max-height: calc(var(${VIEWPORT_HEIGHT}) - 2rem);
`;

export const Header = styled.div<{ headerColor?: HeaderColorType; isCloseButton: boolean }>`
  background: ${headerBackground};
  padding: ${({ isCloseButton }) => (isCloseButton ? '33px 80px 25px 35px' : '33px 35px 25px 35px')};
  box-sizing: border-box;
  position: relative;
  min-height: 89px;
  flex-shrink: 0;
  border-top-left-radius: ${({ theme }) => theme.borderRadius.s};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.s};
`;
export const InnerButton = styled.div`
  position: absolute;
  top: 30px;
  right: 35px;
`;

export const InnerContent = styled.div.attrs({ className: 'dialog--content-container' })`
  flex-grow: 1;
  overflow-y: auto;
`;
