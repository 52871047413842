import { SliderPopup } from '@ui-kit/slider/popup';
import React from 'react';
import 'swiper/components/navigation/navigation.min.css';
import SwiperCore, { Keyboard, Navigation } from 'swiper/core';
import { SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

import { MainSwiper, UISwiper } from './Slider.styles';

SwiperCore.use([Navigation, Keyboard]);

export interface ImageType {
  id: number;
}

interface SliderProps<I extends ImageType> {
  images?: I[];
  activeImgIndex?: number;
  height: number;
  onImageClick: (index: number) => () => void;
  onEnter: (index: number) => (event: any) => void;
  PreviewImageComponent: React.FC<{ img: I }>;
  ViewImageComponent: React.FC<{ img: I; className?: string }>;
  modal: {
    isOpen: boolean;
    close: () => void;
  };
}

export const Slider = <I extends ImageType>({
  images,
  height: sliderHeight,
  onImageClick,
  onEnter,
  modal,
  PreviewImageComponent,
  ViewImageComponent,
  activeImgIndex,
}: SliderProps<I>) => {
  return (
    <>
      <UISwiper
        slidesPerView={'auto'}
        centeredSlides={false}
        spaceBetween={10}
        height={sliderHeight}
        slideToClickedSlide={false}
      >
        {(images || []).map((img, index) => (
          <SwiperSlide key={img.id} onClick={onImageClick(index)} onKeyDown={onEnter(index)}>
            <PreviewImageComponent img={img} />
          </SwiperSlide>
        ))}
      </UISwiper>
      <SliderPopup isOpenPopup={modal.isOpen} onClosePopup={modal.close}>
        <MainSwiper
          centeredSlides
          updateOnWindowResize
          slidesPerView={'auto'}
          slidesPerGroup={1}
          navigation={Number(images?.length) > 1}
          keyboard
          loop={Number(images?.length) > 1}
          initialSlide={activeImgIndex}
          autoHeight
          updateOnImagesReady
          slideToClickedSlide={false}
        >
          {(images || []).map(img => (
            <SwiperSlide key={img.id}>
              <ViewImageComponent img={img} />
            </SwiperSlide>
          ))}
        </MainSwiper>
      </SliderPopup>
    </>
  );
};
