import { StyledTabs } from '@ui-kit/tabs/Tabs.styles';
import RcTabs from 'rc-tabs';
import { TabsProps } from 'rc-tabs/lib/Tabs';
import React from 'react';

export const Tabs: React.FC<TabsProps & { commonContent?: React.ReactNode; tabsGutter?: string }> = ({
  children,
  commonContent,
  tabsGutter,
  ...props
}) => {
  return (
    <StyledTabs hasCommonContent={Boolean(commonContent)} tabsGutter={tabsGutter}>
      <RcTabs {...props}>
        {commonContent}
        {children}
      </RcTabs>
    </StyledTabs>
  );
};
