import styled, { css } from 'styled-components';

export type BadgePosition = `${'top' | 'bottom'}-${'left' | 'right'}`;

const topLeft = css`
  inset: 15% auto auto 15%;
  transform: scale(1) translate(-50%, -50%);
  transform-origin: 0 0;
`;

const topRight = css`
  inset: 15% 15% auto auto;
  transform: scale(1) translate(50%, -50%);
  transform-origin: 100% 0;
`;

const bottomLeft = css`
  inset: auto auto 15% 15%;
  transform: scale(1) translate(-50%, 50%);
  transform-origin: 0 100%;
`;

const bottomRight = css`
  inset: auto 15% 15% auto;
  transform: scale(1) translate(50%, 50%);
  transform-origin: 100% 100%;
`;

export const BadgeContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: inline-flex;
`;

const badgePlacement = {
  'top-left': topLeft,
  'top-right': topRight,
  'bottom-right': bottomRight,
  'bottom-left': bottomLeft,
} as const;

export const Badge = styled.div<{ size?: string; position: BadgePosition }>`
  position: absolute;
  z-index: 2;
  display: inline-flex;
  width: ${({ size }) => size || '10px'};
  height: ${({ size }) => size || '10px'};
  ${({ position }) => badgePlacement[position]}
`;
