import { ReactComponent as BasketIcon } from '@ui-kit/assets/icons/Basket.svg';
import { ReactComponent as ClipIcon } from '@ui-kit/assets/icons/Clip.svg';
import { Box } from '@ui-kit/box';
import { IconButton } from '@ui-kit/button';
import { pxToRem } from '@ui-kit/helpers';
import { Hidden } from '@ui-kit/hidden';
import { SvgIcon } from '@ui-kit/icon';
import { theme } from '@ui-kit/theme';
import { Text } from '@ui-kit/typography';
import React from 'react';

import { DropZoneContainer, FileInput, FileItem, FilesList, Label } from './SecondaryFileUpload.styles';
import { useFilesDropZone } from './useFilesDropZone';

export interface DropZoneFilesProps {
  onChange: (files: File[]) => void;
  value: File[];
  inputId: string;
  text: string;
  linkText: string;
  isError?: boolean;
  error?: string;
}

export const SecondaryFileUpload = React.forwardRef<any, DropZoneFilesProps>(
  ({ onChange, value, inputId, text, linkText, error, isError: isErrorProps }, ref) => {
    const inputRef = React.useRef<HTMLInputElement | null | undefined>(null);
    const { isDragging, containerProps } = useFilesDropZone({
      onChange,
    });
    const isError = Boolean(error) || isErrorProps;

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;

      onChange([...(value || []), ...(files || [])]);
    };

    const onDeleteItem = (item: File) => () => {
      onChange(value?.filter(file => item.name !== file.name));
    };

    const onEnter = (event: any) => {
      event.stopPropagation();
      event.keyCode === 13 && inputRef?.current?.click();
    };

    const setRef = (e?: HTMLInputElement | null) => {
      if (typeof ref === 'function') {
        ref?.(e);
      }
      inputRef.current = e;
    };
    return (
      <div>
        <DropZoneContainer {...containerProps} isDragging={isDragging}>
          <Box>
            <SvgIcon icon={ClipIcon} color={'tertiary'} typeColorProperty={'stroke'} />
            <Box mt={'15px'}>
              <Hidden mq={theme.media.down.s}>
                <Text as={'span'} fontSize={pxToRem(15)}>
                  {text}
                </Text>
              </Hidden>
              <Label tabIndex={0} htmlFor={inputId} onKeyDown={onEnter}>
                {linkText}
              </Label>
            </Box>
          </Box>
          <FileInput ref={setRef} id={inputId} type="file" onChange={handleInputChange} multiple />
        </DropZoneContainer>
        <FilesList>
          {(value || []).map(file => (
            <FileItem key={file.name}>
              <Text fontSize={pxToRem(15)} textColor={'tertiary'} decoration={'underline'} lineClamp={1}>
                {file.name}
              </Text>
              <IconButton
                borderColor={'tertiary'}
                buttonSize={'21px'}
                onClick={onDeleteItem(file)}
                icon={
                  <SvgIcon icon={BasketIcon} color={'secondary'} typeColorProperty={'stroke'} viewBox="0 0 20.4 22.5" />
                }
              />
            </FileItem>
          ))}
        </FilesList>
        {isError && (
          <Text
            fontVariant={'primary'}
            fontSize={pxToRem(12)}
            margin={'10px 0 5px 0'}
            textColor={'error'}
            fontWeight={'bold'}
          >
            {error}
          </Text>
        )}
      </div>
    );
  },
);
