import { SvgIconProps, TColorProperty } from '@ui-kit/icon/SvgIcon';
import { themeMode } from '@ui-kit/theme';
import styled from 'styled-components';
import theme from 'styled-theming';

export const svgColor = theme.variants('mode', 'color', {
  primary: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.white },
  secondary: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen },
  tertiary: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.cruelSeaGreen },
  error: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.red },
});

type TProps = SvgIconProps & { $typeColorProperty?: TColorProperty | TColorProperty[] };

const getColor = (props: TProps) => {
  const { $typeColorProperty } = props;
  if (!$typeColorProperty) return null;

  const getStyles = (type: string) => `${type}: ${svgColor({ ...props })} !important`;

  if (Array.isArray($typeColorProperty)) {
    return $typeColorProperty.map(type => getStyles(type));
  }

  return getStyles($typeColorProperty);
};

export const SvgBaseRoot = styled.svg<TProps>`
  overflow: hidden;
  cursor: inherit;
  ${getColor};
  & > * {
    ${getColor};
  }
`;
