import { USER_APPLICATION_ROLE } from '@constants/user';
import { UserApplicationRoleType } from '@type/user';

export const headerMenuItemKeys = {
  dashboard: 'nav_menu-overview',
  objects: 'nav_menu-your_objects',
  settings: 'nav_menu-settings',
};

export const getHeaderMenuItemsAccordingUserAppRole = (applicationRole?: UserApplicationRoleType) => {
  switch (applicationRole) {
    case USER_APPLICATION_ROLE.account_owner:
    case USER_APPLICATION_ROLE.account_administrator:
      return [headerMenuItemKeys.dashboard, headerMenuItemKeys.objects, headerMenuItemKeys.settings];
    case USER_APPLICATION_ROLE.guest_user:
      return [headerMenuItemKeys.dashboard, headerMenuItemKeys.objects];
    default:
      return [];
  }
};
