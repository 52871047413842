import { Container, LinkContainer, StyledLink } from '@ui-kit/tag/Tag.styles';
import { TBorderRadius } from '@ui-kit/theme/borderRadius';
import { ITextBaseProps, Text } from '@ui-kit/typography';
import React from 'react';
import { LinkProps } from 'react-router-dom';

export interface TagProps extends ITextBaseProps {
  bgColor?: 'primary' | 'secondary' | 'tertiary';
  borderColor?: 'primary' | 'secondary' | 'tertiary';
  link?: string;
  borderRadius?: TBorderRadius;
  padding?: string;
  text: string;
}

export type StyledTagProps = Omit<TagProps, 'text'>;

const Link: React.FC<StyledTagProps & LinkProps> = ({ children, href, ...props }) => {
  if (!href || href === '/') {
    return <Container {...props}>{children}</Container>;
  }

  return (
    <LinkContainer href={href} {...props}>
      {children}
    </LinkContainer>
  );
};

export const Tag: React.FC<TagProps> = ({
  bgColor = 'primary',
  borderColor = 'tertiary',
  textColor = 'secondary',
  borderRadius,
  link,
  padding,
  text,
  ...textProps
}) => {
  return (
    <StyledLink
      to={link || ''}
      component={Link}
      bgColor={bgColor}
      borderColor={borderColor}
      borderRadius={borderRadius}
    >
      <Text as={'span'} {...textProps} textColor={textColor}>
        {text}
      </Text>
    </StyledLink>
  );
};
