import { themeMode } from '@ui-kit/theme';
import { ITextBaseProps } from '@ui-kit/typography';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';

export const textColor = theme.variants('mode', 'textColor', {
  primary: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.cruelSeaGreen },
  secondary: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.white },
  tertiary: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen },
  quaternary: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.whiteSmoke },
  quinary: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.greyDark },
  senary: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.eerieBlack },
  septenary: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.yellow },
  octal: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.greyMiddle },
  error: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.red },
});

export const hoverColor = theme.variants('mode', 'hoverColor', {
  main: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen },
  darkenedMain: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.darkenedSeaGreen },
});

interface ILineClamp {
  lineClamp?: number;
}

const LineClamp = ({ lineClamp }: ILineClamp) => css`
  display: -webkit-box;
  -webkit-line-clamp: ${lineClamp};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  box-orient: vertical;
`;

const UpperFirst = css`
  &:first-letter {
    text-transform: uppercase;
  }
`;

const textDecoration = ({ decoration }: ITextBaseProps) => {
  return decoration ? decoration : 'none';
};

const fontCase = ({ case: _case }: ITextBaseProps) => {
  return _case ? { textTransform: _case } : {};
};

export const StyledText = styled.div<ITextBaseProps>`
  word-break: ${({ wordBreak }) => wordBreak || 'break-word'};
  white-space: ${({ whiteSpace }) => whiteSpace || 'pre-line'};
  ${({ lineClamp }) => !!lineClamp && LineClamp};
  ${({ upperFirst }) => upperFirst && UpperFirst};
  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ padding }) => padding && `padding: ${padding}`};
  ${({ fontVariant = 'primary', fontWeight = 'regular' }) =>
    ({ theme }) =>
      theme.font.variants[fontVariant][fontWeight]};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}` : '1rem')};
  text-align: ${({ textAlign }) => textAlign};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  line-height: ${({ lineHeight }) => lineHeight ?? '110%'};
  color: ${textColor} ${({ isImportantTextColor }) => isImportantTextColor && '!important'};
  text-decoration: ${textDecoration};
  cursor: ${({ cursor }) => cursor || 'inherit'};
  transition: color 0.2s ease-in-out;
  ${fontCase};
  box-sizing: border-box;
  &:hover {
    color: ${hoverColor};
  }
`;
