import { ReactComponent as CloseIcon } from '@ui-kit/assets/icons/Close.svg';
import { IconButton } from '@ui-kit/button';
import { Container, Header, InnerButton, InnerContent } from '@ui-kit/dialog/Dialog.styles';
import { SvgIcon } from '@ui-kit/icon';
import React from 'react';

export type HeaderColorType = 'primary' | 'secondary';

export interface DialogProps {
  onClosePopup?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  HeaderComponent: React.ReactNode;
  ContentComponent?: React.ReactNode;
  headerColor?: HeaderColorType;
  className?: string;
  ariaLabels?: {
    closeButton?: string;
  };
}

export const Dialog: React.FC<DialogProps> = ({
  children,
  onClosePopup,
  HeaderComponent,
  ContentComponent,
  headerColor = 'primary',
  className,
  ariaLabels,
}) => {
  return (
    <Container className={className}>
      <Header headerColor={headerColor} isCloseButton={!!onClosePopup}>
        <>
          {HeaderComponent}
          {onClosePopup && (
            <InnerButton>
              <IconButton
                rounded
                onClick={onClosePopup}
                buttonSize={'33px'}
                borderColor={'primary'}
                icon={<SvgIcon icon={CloseIcon} color={'primary'} width={15} height={15} />}
                aria-label={ariaLabels?.closeButton}
              />
            </InnerButton>
          )}
        </>
      </Header>
      <InnerContent>
        <>
          {ContentComponent}
          {children}
        </>
      </InnerContent>
    </Container>
  );
};
