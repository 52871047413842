import { inputStyles } from '@ui-kit/form/Input/common.styles';
import { InputBase, InputProps, StyledInputProps } from '@ui-kit/form/Input/InputBase';
import 'cleave.js/dist/addons/cleave-phone.no';
import Cleave from 'cleave.js/react';
import { Props as CleaveProps } from 'cleave.js/react/props';
import React from 'react';
import styled from 'styled-components';

export type CleaveInputProps = InputProps<StyledInputProps & CleaveProps>;

export const StyledCleaveInput = styled(Cleave)<StyledInputProps>`
  ${inputStyles};
`;

export const CleaveInput = React.forwardRef<any, CleaveInputProps>(({ 'data-testid': dataTestId, ...props }, ref) => {
  return (
    <InputBase
      {...props}
      ref={ref}
      variant={'primary'}
      customInput={StyledCleaveInput}
      data-testid={dataTestId}
      htmlRef={ref as (i: any) => void}
    />
  );
});
