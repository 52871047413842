import { themeMode } from '@ui-kit/theme';
import React from 'react';
import styled from 'styled-components';
import theme from 'styled-theming';

type TArrowDirection = 'up' | 'down' | '';

interface ArrowProps {
  color: 'primary';
  type: TArrowDirection;
  margin?: string;
}

const borderColor = theme.variants('mode', 'color', {
  primary: {
    [themeMode.light]: ({ theme }) => theme.palette.commonColors.white,
  },
});

const getRotation = ({ type }: { type: TArrowDirection }) => (type === 'up' ? 180 : 0);

const StyledArrow: React.FC<ArrowProps> = styled.span<ArrowProps>`
  display: inline-flex;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  margin: ${({ margin }) => margin};
  border-top: 7px solid ${borderColor};
  transform: rotate(${getRotation}deg);
`;

export const CaretArrow: React.FC<ArrowProps> = StyledArrow;
