import { Spinner as UiSpinner } from '@ui-kit/Spinner';
import React from 'react';

import { InnerLoader } from './Spinner.styles';

interface Props {
  isVisible: boolean;
  size?: number;
}

export const Spinner: React.FC<Props> = ({ isVisible, size = 20 }) => {
  if (!isVisible) return null;

  return (
    <InnerLoader>
      <UiSpinner size={size} />
    </InnerLoader>
  );
};
