import { Container, Footer, Header } from '@ui-kit/navbar/Menu/Menu.styles';
import { MenuProps } from '@ui-kit/navbar/Navbar.types';
import React from 'react';

import { MenuItem } from './MenuItem';

export const Menu: React.FC<MenuProps> = ({ headerItems, footerItems, activeKey, setActiveMenuItem, toggleMenu }) => {
  return (
    <Container>
      <Header>
        {headerItems.map(it => (
          <MenuItem
            {...it}
            isActive={activeKey === it.itemKey}
            key={it.itemKey}
            setActiveMenuItem={setActiveMenuItem}
            toggleMenu={toggleMenu}
          />
        ))}
      </Header>
      <Footer>
        {footerItems.map(it => (
          <MenuItem
            {...it}
            isActive={activeKey === it.itemKey}
            key={it.itemKey}
            setActiveMenuItem={setActiveMenuItem}
          />
        ))}
      </Footer>
    </Container>
  );
};
