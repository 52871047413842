import { ReactComponent as CloseIcon } from '@ui-kit/assets/icons/Close.svg';
import { IconButton } from '@ui-kit/button';
import { Inner } from '@ui-kit/form/SearchInput/Multivalue.styles';
import { pxToRem } from '@ui-kit/helpers';
import { SvgIcon } from '@ui-kit/icon';
import { Text } from '@ui-kit/typography';
import React from 'react';

interface Props {
  text: string;
  onDeleteValue: () => void;
}

export const MultiValue: React.FC<Props> = ({ text, onDeleteValue }) => {
  return (
    <Inner>
      <Text
        margin={'0 10px 0 0'}
        textColor={'secondary'}
        fontSize={pxToRem(12)}
        fontWeight={'bold'}
        fontVariant={'primary'}
        lineClamp={1}
        lineHeight={'16px'}
      >
        {text}
      </Text>
      <IconButton
        rounded
        onClick={onDeleteValue}
        onTouchEnd={onDeleteValue}
        buttonSize={'16px'}
        borderColor={'primary'}
        icon={<SvgIcon icon={CloseIcon} color={'primary'} width={6} height={6} viewBox="0 0 14 14" />}
      />
    </Inner>
  );
};
