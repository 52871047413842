import { Spinner } from '@components/common/Spinner';
import { EmptyPlaceholder, InnerFixedHeader } from '@ui-kit/table/common';
import { TableBodyCell, TableContainer } from '@ui-kit/table/common/Common.styles';
import { ContentType } from '@ui-kit/table/VirtuosoTable/VirtuosoTable.types';
import React, { useCallback } from 'react';
import { HeaderGroup, Row, TableBodyPropGetter, TableBodyProps, TablePropGetter, TableProps } from 'react-table';
import { TableVirtuoso as ReactVirtuosoTable } from 'react-virtuoso';

import { FixedHeaderContent } from './FixedHeaderContent';
import { TableRows } from './TableRows';

interface ITableProps<D extends object> {
  /**
   * The height is used only when useWindowScroll is false
   */
  height?: number;
  /**
   * Uses the document scroller rather than wrapping the list in its own.
   * If true the height doesn't apply and header is sticky on the top of page.
   */
  useWindowScroll?: boolean;
  isLoading?: boolean;
  isLoadingMore?: boolean;
  emptyDataText?: string;
  atBottomStateChange?: (endReached: boolean) => void;
  getTableProps: (propGetter?: TablePropGetter<D>) => TableProps;
  getTableBodyProps: (propGetter?: TableBodyPropGetter<D>) => TableBodyProps;
  headerGroups: Array<HeaderGroup<D>>;
  rows: Array<Row<D>>;
  endReached?: (index: number) => void;
  onClickRow?: (original: any) => () => void;
  stickyHeaderOffsetTop?: string;
}

export const VirtuosoTable = <D extends object>(props: ITableProps<D>) => {
  const {
    atBottomStateChange,
    useWindowScroll = true,
    height,
    emptyDataText,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    onClickRow,
    endReached,
    isLoading,
    isLoadingMore,
  } = props;

  const getEmptyData = useCallback(
    () => <EmptyPlaceholder isLoading={isLoading} emptyDataText={emptyDataText} />,
    [emptyDataText, isLoading],
  );
  const getTable = useCallback(props => <TableContainer {...getTableProps()} {...props} />, [getTableProps]);
  const getTableRows = useCallback(
    props => <TableRows rows={rows} {...props} onClickRow={onClickRow} />,
    [rows, onClickRow],
  );
  const getTableHead = React.forwardRef<HTMLDivElement, ContentType>(({ style, ...props }, ref) => (
    <InnerFixedHeader
      {...props}
      isLoading={isLoading || isLoadingMore}
      isStickyHeader
      ref={ref as React.MutableRefObject<HTMLDivElement>}
    />
  ));
  const getTableBody = React.forwardRef<HTMLTableSectionElement, any>(({ style, ...props }, ref) => {
    return <div {...getTableBodyProps()} {...props} ref={ref} />;
  });
  const getFixedHeaderContent = useCallback(() => <FixedHeaderContent headerGroups={headerGroups} />, [headerGroups]);
  const getItemContent = useCallback(
    (index: number) => {
      return (rows[index]?.cells || []).map(cell => {
        return (
          <TableBodyCell
            // @ts-ignore
            mediaQueries={cell.column.style?.mediaQueries}
            {...cell.getCellProps([
              {
                // @ts-ignore
                style: cell.column.style,
              },
            ])}
          >
            {cell.render('Cell')}
          </TableBodyCell>
        );
      });
    },
    [rows],
  );

  return (
    <>
      <ReactVirtuosoTable
        endReached={endReached}
        useWindowScroll={useWindowScroll}
        atBottomStateChange={atBottomStateChange}
        height={height}
        totalCount={rows?.length}
        components={{
          Table: getTable,
          EmptyPlaceholder: getEmptyData,
          TableBody: getTableBody,
          TableRow: getTableRows,
          TableHead: getTableHead,
        }}
        fixedHeaderContent={getFixedHeaderContent}
        itemContent={getItemContent}
      />
      <Spinner isVisible={!!isLoadingMore} />
    </>
  );
};
