enum appEnvs {
  NODE_ENV = 'NODE_ENV',
  APP_TITLE = 'APP_TITLE',
  GA_CODE = 'GA_CODE',
  CLIENT_VERSION = 'CLIENT_VERSION',
}

export const appEnv: {
  [key in appEnvs]?: string;
} = {
  NODE_ENV: process.env.NODE_ENV,
  APP_TITLE: process.env.APP_TITLE,
  GA_CODE: process.env.GA_CODE,
  CLIENT_VERSION: process.env.CLIENT_VERSION,
};
