import { pxToRem } from '@ui-kit/helpers';
import { themeMode } from '@ui-kit/theme';
import styled from 'styled-components';
import theme from 'styled-theming';

const mainColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen,
});

export const DropZoneContainer = styled.div<{ isDragging: boolean }>`
  width: 100%;
  height: 150px;
  border: 1px ${({ isDragging }) => (isDragging ? 'solid' : 'dotted')} ${mainColor};
  background-color: ${({ isDragging }) => (isDragging ? '#ddd' : 'transparent')};
  border-radius: ${({ theme }) => theme.borderRadius.s};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 30px;
`;

export const Label = styled.label`
  color: ${mainColor};
  font-size: ${pxToRem(15)};
  text-decoration: underline;
  ${({ theme }) => theme.font.variants.primary.regular};
  cursor: pointer;
`;

export const FileInput = styled.input`
  visibility: hidden;
  position: absolute;
  left: -999999px;
  top: -999999px;
`;

export const FilesList = styled.div`
  margin-top: 13px;
`;

export const FileItem = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
