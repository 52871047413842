import { SelectOption } from '@helpers/select';
import { ReactComponent as SearchIcon } from '@ui-kit/assets/icons/Search.svg';
import { pxToRem, Select, SvgIcon, Text } from '@ui-kit/index';
import React from 'react';
import { components, ControlProps } from 'react-select';
import { InputActionMeta } from 'react-select/dist/declarations/src/types';

import { styles } from './LiveSearchInput.styles';

interface Props {
  options: SelectOption[];
  menuIsOpen: boolean;
  closeMenu: () => void;
  isLoading: boolean;
  inputValue: string;
  inputChange: (newValue: string) => void;
  selectValue: SelectOption | null;
  selectChange: (newValue: SelectOption) => void;
  loadingMessageText: string;
  placeholder?: string;
}

const Control = ({ children, ...props }: ControlProps) => {
  return (
    <components.Control {...props}>
      <SvgIcon icon={SearchIcon} width={16} height={16} color={'secondary'} typeColorProperty={'stroke'} />
      {children}
    </components.Control>
  );
};

export const LiveSearchInput: React.FC<Props> = ({
  options,
  menuIsOpen,
  closeMenu,
  isLoading,
  inputValue,
  inputChange,
  selectValue,
  selectChange,
  loadingMessageText,
  placeholder,
}) => {
  const loadingMessage = () => (
    <Text textColor={'octal'} textAlign={'center'} fontWeight={'medium'} fontSize={pxToRem(15)}>
      {loadingMessageText}
    </Text>
  );

  const noOptionsMessage = () => null;

  const onInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    if (actionMeta?.action !== 'input-blur' && actionMeta?.action !== 'menu-close') {
      inputChange(newValue);
    }
  };

  return (
    <Select
      isSearchable
      blurInputOnSelect
      filterOption={() => true}
      defaultMenuIsOpen={false}
      menuIsOpen={menuIsOpen}
      onMenuClose={closeMenu}
      isLoading={isLoading}
      options={options}
      loadingMessage={loadingMessage}
      noOptionsMessage={noOptionsMessage}
      value={selectValue}
      placeholder={placeholder}
      openMenuOnClick={false}
      inputValue={inputValue}
      onInputChange={onInputChange}
      components={{ Control, DropdownIndicator: () => null, IndicatorsContainer: () => null }}
      styles={styles}
      //@ts-ignore
      onChange={selectChange}
    />
  );
};
