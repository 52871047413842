import { routes } from '@constants/routes';
import { PageNotFound } from '@domains/404';
import { PrivateLayout } from '@domains/AppLayout';
import { HomePage } from '@domains/HomePage';
import { SessionUserAccountId } from '@helpers/account';
import { getIsDashboardRoute } from '@helpers/routing';
import { getIsFullRights } from '@helpers/user';
import { useSession } from '@hooks/auth/useSession';
import { SessionStatus } from '@hooks/auth/useSessionProvider';
import { StyledLayout } from '@ui-kit/layout/Layout.styles';
import { Loader } from '@ui-kit/loader';
import React, { lazy, Suspense } from 'react';
import { useLocation } from 'react-router';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

const ElevatorPage = lazy(() => import(/* webpackChunkName: "Elevator" */ '../Elevator'));
const ElevatorsPage = lazy(() => import(/* webpackChunkName: "Elevators" */ '../Elevators'));
const EditElevatorPage = lazy(() => import(/* webpackChunkName: "Edit elevator" */ '../EditElevator'));
const SettingsPage = lazy(() => import(/* webpackChunkName: "Settings" */ '../Settings'));
const ProfilePage = lazy(() => import(/* webpackChunkName: "Profile" */ '../Profile'));
const SettingsUserPage = lazy(() => import(/* webpackChunkName: "SettingsUser" */ '../Settings/User'));
const SettingsCompanyPage = lazy(() => import(/* webpackChunkName: "SettingsCompany" */ '../Settings/Company'));
const DashboardPage = lazy(() => import(/* webpackChunkName: "Dashboard" */ '../Dashboard'));
const AccountSwitcherPage = lazy(() => import(/* webpackChunkName: "AccountSwitcher" */ '../AccountSwitcher'));

const RoutesWithFullRights = [
  <Route key={routes.settings.path} path={routes.settings.path} exact component={SettingsPage} />,
  <Route key={routes.editObject.path} path={routes.editObject.path} exact component={EditElevatorPage} />,
  <Route key={routes.settingsUser.path} path={routes.settingsUser.path} exact component={SettingsUserPage} />,
  <Route key={routes.settingsCompany.path} path={routes.settingsCompany.path} exact component={SettingsCompanyPage} />,
];

const ObjectsRoutes = [
  <Route path={routes.objects.path} exact component={ElevatorsPage} />,
  <Route path={routes.objectDetails.path} exact component={ElevatorPage} />,
];

export const PrivateRouter = () => {
  const { status, currentUser } = useSession();
  const { replace } = useHistory();
  const isFullRights = getIsFullRights(currentUser);
  const isDashboardRoute = getIsDashboardRoute(currentUser?.applicationRole);
  const { pathname } = useLocation();
  const isAccountSwitcherRoute = pathname === '/accounts';

  React.useEffect(() => {
    if (status === SessionStatus.closed) {
      replace(routes.login.path);
    }

    if (status === SessionStatus.error) {
      replace(routes.login.path);
    }
  }, [status, replace]);

  if (status === SessionStatus.loading) {
    return (
      <StyledLayout>
        <Loader size={40} isVisible showOverlay={false} />;
      </StyledLayout>
    );
  }

  if (status !== SessionStatus.active) {
    return null;
  }

  return (
    <PrivateLayout isAccountSwitcherRoute={isAccountSwitcherRoute}>
      <Suspense fallback={null}>
        <Switch>
          <Route path={routes['404'].path} component={PageNotFound} />
          <Route path={routes.home.path} exact component={HomePage} />
          {!currentUser?.external && ObjectsRoutes}
          <Route path={routes.profile.path} exact component={ProfilePage} />
          {currentUser?.availableAccounts && currentUser.availableAccounts.length > 1 && (
            <Route path={routes.accountSwitcher.path} exact component={AccountSwitcherPage} />
          )}
          {isDashboardRoute && <Route path={routes.dashboard.path} exact component={DashboardPage} />}
          {isFullRights && RoutesWithFullRights}
          <Redirect to={routes['404'].path} />
        </Switch>
      </Suspense>
    </PrivateLayout>
  );
};
