import { getImageSrcSet } from '@helpers/image';
import { ImageSizesType } from '@type/image';
import { ReactComponent as ChevronIcon } from '@ui-kit/assets/icons/ChevronDown.svg';
import Avatar from '@ui-kit/avatar/Avatar';
import { DefaultPlaceholderProps } from '@ui-kit/avatar/DefaultPlaceholder';
import { Badge, BadgeProps } from '@ui-kit/badge';
import { IconButton } from '@ui-kit/button';
import { pxToRem } from '@ui-kit/helpers';
import { SvgIcon } from '@ui-kit/icon';
import { UserContent } from '@ui-kit/navbar/Drawer';
import { Text } from '@ui-kit/typography';
import React from 'react';
import { LinkProps } from 'react-router-dom';
import { Link as ReactLink } from 'react-router-dom';

import { Container, LinkContainer } from './LabelUser.styles';

interface LabelUserProps {
  profileLink?: string;
  avatar?: ImageSizesType;
  company?: {
    name?: string;
    link?: string;
  };
  userName?: string;
  onActiveMenuItem?: (key: string | null) => () => void;
  badge?: BadgeProps;
  avatarPlaceholder?: React.ReactNode;
  avatarLabel?: string;
  placeholderProps?: DefaultPlaceholderProps;
  contextMenu?: boolean;
}

const Link: React.FC<LinkProps> = ({ children, href, ...props }) => {
  if (!href || href === '/') {
    return <>{children}</>;
  }

  return (
    <LinkContainer {...props} to={href}>
      {children}
    </LinkContainer>
  );
};

export const LabelUser: React.FC<LabelUserProps> = ({
  profileLink,
  avatar,
  userName,
  company,
  onActiveMenuItem,
  badge,
  avatarPlaceholder,
  avatarLabel,
  placeholderProps,
  contextMenu,
}) => {
  const onUserImageClick = onActiveMenuItem ? onActiveMenuItem(profileLink || null) : undefined;
  return (
    <Container>
      <Badge size={badge?.size} position={badge?.position || 'top-right'} content={badge?.content}>
        <Avatar
          placeholder={avatarPlaceholder}
          src={avatar?.['1x'] || ''}
          srcSet={getImageSrcSet({ src: avatar })}
          aria-label={avatarLabel}
          alt={avatarLabel}
          placeholderProps={{ text: userName, ...placeholderProps }}
        />
      </Badge>
      <UserContent>
        <ReactLink to={profileLink || ''} component={Link} onClick={onUserImageClick}>
          <Text
            fontWeight={'bold'}
            fontSize={'1rem'}
            textColor={'senary'}
            fontVariant={'primary'}
            margin={'0 0 2px 0'}
            {...(profileLink ? { cursor: 'pointer', hoverColor: 'main' } : {})}
          >
            {userName}
          </Text>
        </ReactLink>
        <ReactLink to={company?.link || ''} component={Link}>
          <Text
            fontWeight={'medium'}
            fontSize={pxToRem(13)}
            textColor={'senary'}
            fontVariant={'primary'}
            margin={'0 0 2px 0'}
          >
            {company?.name}
          </Text>
        </ReactLink>
      </UserContent>
      {contextMenu && (
        <IconButton
          icon={<SvgIcon icon={ChevronIcon} color={'primary'} width={'24px'} height={'24px'} />}
          borderColor={'tertiary'}
          buttonSize={'24px'}
        />
      )}
    </Container>
  );
};
