import { IconButton } from '@ui-kit/button';
import { themeMode } from '@ui-kit/theme';
import styled from 'styled-components';
import theme from 'styled-theming';

export const InnerRadioButtons = styled.div`
  display: flex;
  align-items: center;
  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;

const borderColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.whiteSmoke,
});

export const Footer = styled.div`
  padding: 25px 40px 30px 35px;
  box-sizing: border-box;
  border-top: 1px solid ${borderColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Content = styled.div`
  padding: 35px 34px 30px 39px;
  box-sizing: border-box;
`;

export const ContainerToolTip = styled.div`
  max-width: 560px;
  width: 100%;
`;

export const InnerNextButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LeftIconButton = styled(IconButton)`
  transform: rotate(180deg);
  margin-right: 5px;
`;
