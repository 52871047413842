import { Breakpoint } from '@ui-kit/theme/breakpoints';
import React, { useEffect, useState } from 'react';
import Joyride, { Step as JoyrideStep, StoreHelpers } from 'react-joyride';

import { Tooltip } from './Tooltip';

interface Step extends JoyrideStep {
  id: string;
}
interface AppTourProps {
  steps: Array<Step>;
  isRun?: boolean;
  closeButtonTitle: string;
}

export const AppTour: React.FC<AppTourProps> = ({ steps, isRun: propsIsRun = false, closeButtonTitle }) => {
  const [helpers, setHelpers] = useState<StoreHelpers | undefined>();
  const [isRun, setIsRun] = useState(propsIsRun);
  const { clientWidth } = document.documentElement;
  const isRunTour = clientWidth >= Breakpoint.xxl;

  useEffect(() => {
    if (!isRunTour) return;
    setIsRun(propsIsRun);
  }, [propsIsRun, isRunTour]);

  const stopTour = () => {
    setIsRun(false);
  };

  useEffect(() => {
    window.addEventListener('resize', stopTour);
    return () => {
      window.removeEventListener('resize', stopTour);
    };
  }, []);

  const handleSetHelpers = (helpers: StoreHelpers) => {
    setHelpers(helpers);
  };
  return (
    <Joyride
      run={isRun}
      steps={steps}
      getHelpers={handleSetHelpers}
      scrollToFirstStep={true}
      spotlightPadding={7}
      tooltipComponent={props => (
        <Tooltip
          {...props}
          closeProps={{ ...props.closeProps, onClick: stopTour }}
          helpers={helpers}
          closeButtonTitle={closeButtonTitle}
        />
      )}
      styles={{
        options: {
          arrowColor: 'transparent',
          zIndex: 99999,
        },
        spotlight: {
          borderRadius: '10px',
        },
      }}
    />
  );
};
