import { MenuItem } from '@szhsin/react-menu';
import { themeMode } from '@ui-kit/theme';
import styled from 'styled-components';
import theme from 'styled-theming';

const borderColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.whiteRose,
});

const headerBackground = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen,
});

const activeBackground = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.wildSand,
});

export const MenuHeader = styled.div`
  background-color: ${headerBackground};
  padding: 1.5rem;
  border-top-left-radius: ${({ theme }) => theme.borderRadius.s};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.s};
  min-width: 250px;
  display: flex;
  justify-content: center;
`;

export const MenuInfoContent = styled.div`
  padding: 1.5rem 2rem;
`;

export const MenuContent = styled.div`
  padding: 1.5rem 0 2.625rem;
  border-top: 1px solid ${borderColor};
`;

export const MenuOption = styled(MenuItem)`
  letter-spacing: 0.3px;
  line-height: 1.2;
  padding: 0.5rem 2rem;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    background: ${activeBackground};
  }

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

export const MenuButtonWrapper = styled.div`
  padding: 1rem 2rem 0;
`;
