import { SpinnerProps } from '@ui-kit/Spinner/Spinner';
import { themeMode } from '@ui-kit/theme';
import styled from 'styled-components';
import theme from 'styled-theming';

const color = theme.variants('mode', 'color', {
  dark: {
    [themeMode.light]: ({ theme }) => theme.palette.commonColors.cruelSeaGreen,
  },
  light: {
    [themeMode.light]: ({ theme }) => theme.palette.commonColors.white,
  },
});

export const StyledSvg = styled.svg<SpinnerProps>`
  rect {
    animation: spinner 1.2s linear infinite;
    fill: ${color};
  }
  rect:nth-child(1) {
    animation-delay: -1.05s;
  }

  rect:nth-child(2) {
    animation-delay: -0.9s;
  }

  rect:nth-child(3) {
    animation-delay: -0.75s;
  }

  rect:nth-child(4) {
    animation-delay: -0.6s;
  }

  rect:nth-child(5) {
    animation-delay: -0.45s;
  }

  rect:nth-child(6) {
    animation-delay: -0.3s;
  }

  rect:nth-child(7) {
    animation-delay: -0.15s;
  }

  rect:nth-child(8) {
    animation-delay: 0s;
  }

  @keyframes spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
