import { Link, LinkProps, themeMode } from '@ui-kit/index';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';

const commonColor = theme.variants('mode', 'color', {
  active: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.cruelSeaGreen },
  hover: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.white },
  default: { [themeMode.light]: 'transparent' },
});

const hoverColors = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen,
});

interface Props {
  isActive: boolean;
}

const styles = css<Props>`
  padding: 10px 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  background-color: ${props => commonColor({ ...props, color: props.isActive ? 'active' : 'default' })};
  cursor: pointer;
  transition: background-color 0.2s ease-in;
  .menu-item-svg {
    & > * {
      transition: stroke 0.2s ease-in-out;
    }
  }
  .menu-item-content {
    transition: color 0.2s ease-in-out;
  }
  &:hover {
    background-color: ${props => commonColor({ ...props, color: props.isActive ? 'hover' : 'default' })};
    box-shadow: ${({ theme, isActive }) => (isActive ? `2px 2px 2px ${theme.palette.commonColors.greyLight}` : 'none')};
  }
  &:hover {
    .menu-item-svg {
      & > * {
        stroke: ${hoverColors} !important;
      }
    }
    .menu-item-content {
      color: ${hoverColors};
    }
  }
`;

export const NavItem = styled.div<Props>`
  ${styles};
`;

export const NavLink = styled(Link)<Props & LinkProps>`
  ${styles};
`;

export const Content = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
