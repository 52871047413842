import { CleaveInput } from '@ui-kit/form';
import { IconBaseProps, SvgIcon } from '@ui-kit/icon';
import { themeMode } from '@ui-kit/theme';
import styled, { StyledProps } from 'styled-components';
import theme from 'styled-theming';

const background = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen,
});

const color = theme.variants('mode', 'kind', {
  active: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.white },
  default: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen },
});

export const borderColor = theme.variants('mode', 'kind', {
  regular: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen },
  error: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.red },
});

const getPrimaryBorder = (props: StyledProps<{ isError?: boolean }>) => {
  if (props.isError) {
    return `1px solid ${borderColor({ ...props, kind: 'error' })}`;
  }
  return `1px solid ${borderColor({ ...props, kind: 'regular' })}`;
};

export const StyledCleaveInput = styled(CleaveInput)<{ $isOpenCalendar: boolean }>`
  & div {
    padding: 12px 7px;
    border: ${getPrimaryBorder};
    &:focus-within {
      border: ${getPrimaryBorder};
    }
  }

  & input {
    padding: 0 0 0 2px;
    background-color: ${props => (props.$isOpenCalendar ? `${background({ ...props })}` : 'transparent')};
    border-radius: 3px;
    color: ${props =>
      props.$isOpenCalendar ? color({ ...props, kind: 'active' }) : color({ ...props, kind: 'default' })};

    &::placeholder {
      color: currentColor;
    }
  }
`;

export const Icon = styled(SvgIcon)<IconBaseProps<any>>`
  cursor: pointer;
`;

export const IconButton = styled.button`
  padding: 5px;
  box-sizing: border-box;
  flex-shrink: 0;
`;
