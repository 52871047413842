import { getStyles } from '@ui-kit/form/Select/Select.styles';
import { StylesConfig } from 'react-select/dist/declarations/src/styles';

export const styles: StylesConfig = {
  input: provided => ({
    ...provided,
    input: {
      opacity: '1 !important',
    },
  }),
  control: (provided, otherProps) => ({
    ...provided,
    ...getStyles()?.control?.(provided, otherProps),
    padding: '15px 20px',
  }),
  menu: (provided, otherProps) => ({
    ...provided,
    ...getStyles()?.menu?.(provided, otherProps),
    zIndex: 400,
  }),
  valueContainer: (provided, otherProps) => ({
    ...provided,
    ...getStyles()?.valueContainer?.(provided, otherProps),
    padding: '2px 8px 2px 15px',
  }),
};
