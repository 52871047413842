import { TypeVariant } from '@ui-kit/form/Input/InputBase';
import { outlineColor } from '@ui-kit/globalStyles';
import { themeMode } from '@ui-kit/theme';
import styled, { css, StyledProps } from 'styled-components';
import theme from 'styled-theming';

export const backgroundColor = theme('mode', { [themeMode.light]: ({ theme }) => theme.palette.commonColors.white });
export const textColor = theme('mode', { [themeMode.light]: ({ theme }) => theme.palette.commonColors.cruelSeaGreen });
export const placeholderColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.greyMiddle,
});
export const borderColor = theme.variants('mode', 'kind', {
  regular: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen },
  error: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.red },
  active: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.cruelSeaGreen },
  disabled: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.greyMiddle },
});

const disabledDarkColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.greyDarkest,
});

const disabledLightColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.greyLight,
});

const getPrimaryBorder = (
  props: StyledProps<{ $isError?: boolean; $isActive?: boolean; disabled?: boolean; readOnly?: boolean }>,
) => {
  if (props.$isError) {
    return `1px solid ${borderColor({ ...props, kind: 'error' })}`;
  }
  if (props.$isActive) {
    return `1px solid ${borderColor({ ...props, kind: 'active' })}`;
  }
  if (props.disabled) {
    return `1px solid ${borderColor({ ...props, kind: 'disabled' })}`;
  }
  if (props.readOnly) {
    return `1px solid ${borderColor({ ...props, kind: 'disabled' })}`;
  }
  return `1px solid ${borderColor({ ...props, kind: 'regular' })}`;
};

export const fontStyles = css`
  ${({ theme }) => theme.font.variants.primary.regular};
  font-size: 1rem;
  color: ${textColor};
  line-height: normal;
`;

export const primaryStyles = css<IProps>`
  background: transparent;
  padding: 12px 20px;
  box-sizing: border-box;
  width: 100%;
  border: ${getPrimaryBorder};

  ${({ readOnly }) =>
    !readOnly &&
    css`
      &:focus-within {
        border: ${props => getPrimaryBorder({ ...props, $isActive: true })};
        box-shadow: 0 0 0 2px ${outlineColor};
      }
    `}
`;

export const secondaryStyles = css<IProps>`
  background: ${backgroundColor};
  padding: 20px 23px;
  box-sizing: border-box;
  width: 100%;
  outline-color: transparent;
  border: 1px solid ${({ $isError }) => ($isError ? ({ theme }) => theme.palette.commonColors.red : 'transparent')};
`;

const mapVariant: { [key: string]: any } = {
  primary: primaryStyles,
  secondary: secondaryStyles,
};

interface IProps {
  $isError?: boolean;
  variant?: TypeVariant;
  disabled?: boolean;
  readOnly?: boolean;
}

export const Label = styled.div<IProps>`
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  ${({ variant = 'primary' }) => mapVariant[variant]};
  ${({ disabled, readOnly }) => (disabled || readOnly) && `border-color: ${backgroundColor}`};
  background: ${({ disabled, readOnly }) => (disabled || readOnly ? disabledLightColor : backgroundColor)};
`;

export const InnerIcon = styled.span<{ clickable?: boolean }>`
  margin-left: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  ${({ clickable }) => clickable && 'cursor: pointer;'}
  user-select: none;
`;

export const InnerPrefixIcon = styled(InnerIcon)`
  margin-right: 15px;
  margin-left: 0;
`;

export const Inner = styled.div<{ disabled?: boolean }>`
  width: 100%;
`;

export const inputStyles = css`
  width: 100%;
  min-height: 20px;
  display: inline-flex;
  outline: none;
  border: none;
  background: transparent;
  &:disabled,
  &:read-only {
    color: ${disabledDarkColor};
    user-select: none;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
  &[data-autocompleted] {
    background-color: transparent !important;
  }

  ${fontStyles};
  &::placeholder {
    ${fontStyles};
    ${({ theme }) => theme.font.variants.primary.medium};
    background: transparent;
    color: ${placeholderColor};
  }
`;
