import { RIGHT_PADDING_LAYOUT, SIDEBAR_WIDTH, TOPBAR_HEIGHT, VIEWPORT_HEIGHT } from '@ui-kit/constants';
import { themeMode } from '@ui-kit/theme';
import { createGlobalStyle, css } from 'styled-components';
import theme from 'styled-theming';

const joyrideOverlayBackground = theme('mode', {
  [themeMode.light]: 'rgba(29, 45, 46, 0.5)',
});

const bodyBackground = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.whiteSmoke,
});

export const inputBackgroundColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.white,
});

export const outlineColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.yellow,
});

export const outlineStyles = css`
  outline-color: ${outlineColor};
  outline-width: 3px;
`;

export const GlobalStyles = createGlobalStyle`
*,
*:before,
*:after {
  box-sizing: border-box;
}

* {
  ${outlineStyles};
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
}

button,
input[type='submit'],
input[type='reset'] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
}
.react-joyride__overlay {
  background: ${joyrideOverlayBackground} !important;
}

input,
textarea {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus{
    -webkit-box-shadow: inset 0 0 0 50px ${inputBackgroundColor} !important;
    background-color: transparent !important;
    transform: none;
  }
}

:root {
  font-size: ${({ theme }) => theme.font.rootSize};
  ${VIEWPORT_HEIGHT}: 100%;
  ${SIDEBAR_WIDTH}: 366px;
  ${TOPBAR_HEIGHT}: 73px;
  ${RIGHT_PADDING_LAYOUT}: 70px;
  ${({ theme }) => theme.media.downWith.xxl} {
    ${RIGHT_PADDING_LAYOUT}: 30px;

  }
  background: ${bodyBackground};
  
}

body {
  min-height: var(${VIEWPORT_HEIGHT});

  &.no-scrolling {
    overflow: hidden !important;
    height: 100%;
    width: 100%;
    position: fixed;
  }
}
`;
