import { ButtonWithArrow } from '@components/common/buttonWithArrow';
import { ConfirmModalTranslationValuesType } from '@components/common/ConfirmModal/ConfirmModal';
import { AppTranslation, TranslationKeyType } from '@domains/Intl/domains/App';
import { Button, pxToRem, Text } from '@ui-kit/index';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { InnerButtons } from './Content.styles';

interface Props {
  translationKey: {
    buttons: {
      cancel: TranslationKeyType;
      confirm: TranslationKeyType;
    };
    content: TranslationKeyType;
  };
  translationValues?: Partial<ConfirmModalTranslationValuesType>;
  onConfirm: () => void;
  isOpenModal: boolean;
  closeModal: () => void;
}

export const Content: React.FC<Props> = ({ closeModal, onConfirm, translationKey, translationValues }) => {
  const {
    buttons: { confirm, cancel },
    content,
  } = translationKey;
  return (
    <div>
      <Text fontWeight={'medium'} fontSize={pxToRem(15)} margin={'35px 40px 30px 40px'} lineHeight={'1.4'}>
        <FormattedMessage id={AppTranslation[content]} values={translationValues?.content} />
      </Text>
      <InnerButtons>
        <ButtonWithArrow buttonType={'button'} borderRadius={'s'} onClick={onConfirm}>
          <FormattedMessage id={AppTranslation[confirm]} />
        </ButtonWithArrow>
        <Button background={'error'} buttonType={'button'} borderRadius={'s'} onClick={closeModal}>
          <FormattedMessage id={AppTranslation[cancel]} />
        </Button>
      </InnerButtons>
    </div>
  );
};
