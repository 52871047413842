import { AppTranslation } from '@domains/Intl/domains/App';
import { DialogProps, Dialog as UiDialog } from '@ui-kit/dialog';
import React from 'react';
import { useIntl } from 'react-intl';

export const Dialog: React.FC<DialogProps> = props => {
  const { formatMessage } = useIntl();
  return (
    <UiDialog
      {...props}
      ariaLabels={{ closeButton: formatMessage({ id: AppTranslation['aria-label.button.close-modal'] }) }}
    />
  );
};
