import { DATE_FORMAT } from '@constants/date';
import { Button } from '@ui-kit/button';
import { pxToRem } from '@ui-kit/helpers';
import { Text } from '@ui-kit/typography';
import { mapLocale } from '@utils/locales';
import { ChangeEventHandler } from 'cleave.js/react/props';
import { parse } from 'date-fns';
import React, { useRef, useState } from 'react';
import { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Modifier, StrictModifierNames } from 'react-popper';

import { DateInput } from './DateInput';
import { DatePicker, InnerClearButton, InnerDataPiker } from './Datepiker.styles';

interface DatePikerProps extends Omit<ReactDatePickerProps, 'value' | 'locale'> {
  onChange: (date?: Date | null) => void;
  titleClearButton: string;
  locale?: 'enGB' | 'no';
  popperModifiers?: ReadonlyArray<Modifier<StrictModifierNames>>;
  dropdownWidth?: string;
  inputWidth?: string;
  isError?: boolean;
  dateFormat?: string;
  value: Date | null;
  label?: string;
  id?: string;
}

export const DatePickerInput: React.FC<DatePikerProps> = ({
  dateFormat = DATE_FORMAT.primary,
  dropdownWidth,
  inputWidth,
  isError,
  locale = 'no',
  onChange,
  placeholderText = '--.--.--',
  popperModifiers,
  selected,
  titleClearButton,
  label,
  id,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const datePikerRef = useRef<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLButtonElement | null>(null);

  const onClear = () => {
    setIsOpen(false);
    onChange(null);
  };

  const handleChange = (value: Date | null) => {
    setIsOpen(false);
    onChange(value);
  };

  const toggleCalendar = () => {
    setIsOpen(prevOpen => !prevOpen);
  };

  const onClickOutside = () => setIsOpen(false);

  const onChangeDateInput: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    onChange(parse(target.value, dateFormat, new Date()));
  };

  const onClick = (e: any) => {
    if (!datePikerRef?.current?.contains(e.target)) {
      setIsOpen(false);
    }
  };

  return (
    <div onClick={onClick}>
      {label && (
        <label htmlFor={id || label}>
          <Text
            margin={'0 0 11px 0'}
            fontWeight={'bold'}
            fontSize={pxToRem(15)}
            fontVariant={'primary'}
            textColor={'primary'}
          >
            {label}
          </Text>
        </label>
      )}

      <InnerDataPiker ref={datePikerRef} dropdownWidth={dropdownWidth} inputWidth={inputWidth}>
        <DatePicker
          {...props}
          id={id || label}
          open={isOpen}
          selected={selected}
          readOnly={isOpen}
          locale={mapLocale[locale]}
          dateFormat={dateFormat}
          placeholderText={placeholderText}
          onClickOutside={onClickOutside}
          // @ts-ignore
          onChange={handleChange}
          showPopperArrow={false}
          customInput={
            <DateInput
              ref={triggerRef}
              isOpenCalendar={isOpen}
              handleOpenCalendar={toggleCalendar}
              isError={isError}
              // @ts-ignore
              onChange={onChangeDateInput}
            />
          }
          fixedHeight
          shouldCloseOnSelect
          popperModifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 3],
              },
            },
            ...(popperModifiers || []),
          ]}
        >
          <InnerClearButton>
            <Button onClick={onClear} width={'100%'} borderRadius={'s'} buttonType={'button'}>
              {titleClearButton}
            </Button>
          </InnerClearButton>
        </DatePicker>
      </InnerDataPiker>
    </div>
  );
};
