import { Spinner } from '@components/common/Spinner';
import { pxToRem } from '@ui-kit/helpers';
import { themeMode } from '@ui-kit/theme';
import { Text } from '@ui-kit/typography';
import React from 'react';
import styled from 'styled-components';
import theme from 'styled-theming';

interface Props {
  emptyDataText?: string;
  isLoading?: boolean;
}

const bgColorNoData = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.white,
});

export const InnerNoData = styled.div`
  background-color: ${bgColorNoData};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
`;

export const EmptyPlaceholder: React.FC<Props> = ({ emptyDataText, isLoading }) => {
  if (isLoading) {
    return <Spinner isVisible={isLoading} />;
  }

  if (!emptyDataText) return null;

  return (
    <InnerNoData>
      <Text fontWeight={'medium'} fontSize={pxToRem(16)}>
        {emptyDataText}
      </Text>
    </InnerNoData>
  );
};
