import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  row-gap: 10px;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  & > * {
    margin-right: 5px;
  }
  outline: none;
  flex: 1 0 0;
`;
