export const USER_APPLICATION_ROLE = {
  account_owner: 'Account owner',
  account_administrator: 'Account administrator',
  logbook_user: 'Logbook user',
  guest_user: 'Guest user',
};

export enum UserFields {
  id = 'id',
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  phoneNumber = 'phoneNumber',
  domainRole = 'domainRole',
  enabled = 'enabled',
  lastLoginAt = 'lastLoginAt',
}

export const usersSortFieldMapping: Record<string, string> = {
  [UserFields.firstName]: 'order[firstName]',
  [UserFields.email]: 'order[email]',
  [UserFields.phoneNumber]: 'order[phoneNumber]',
  [UserFields.domainRole]: 'order[domainRole.name]',
  [UserFields.lastLoginAt]: 'order[lastLoginAt]',
  [UserFields.enabled]: 'order[enabled]',
};

export enum UsersFilterFields {
  search = 'search',
}

export const usersFilterFieldMapping: Record<string, string> = {
  [UsersFilterFields.search]: 'search',
};
