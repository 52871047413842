import { StyledButtonProps } from '@ui-kit/button/Button/Button.types';
import { themeMode } from '@ui-kit/theme';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import styledTheming from 'styled-theming';

export const ButtonBaseRoot = styled.div`
  overflow: hidden;
  // will-change: transform: Fix safari overflow hidden with border-radius
  // credits https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari
  will-change: transform;
`;

const background = styledTheming.variants('mode', 'background', {
  primary: {
    [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen,
  },
  secondary: {
    [themeMode.light]: ({ theme }) => theme.palette.commonColors.cruelSeaGreen,
  },
  transparent: { [themeMode.light]: 'transparent' },
  error: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.red },
});

const borderColor = styledTheming.variants('mode', 'borderColor', {
  primary: {
    [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen,
  },
});

const styles = css<StyledButtonProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 12px 28px;
  border-radius: ${({ theme, borderRadius = 'm' }) => theme.borderRadius[borderRadius]};
  background: ${background};
  border: ${props => (props.borderColor ? `${props.borderWidth || '1px'} solid ${borderColor(props)}` : 'none')};
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

export const UiButton = styled.button<StyledButtonProps>`
  ${styles};
`;

export const UiLinkButton = styled(Link)<StyledButtonProps>`
  ${styles};
  text-decoration: none;
`;
