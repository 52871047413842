import { Logout } from '@components/Auth/Logout';
import { apiKeys } from '@constants/apiKeys';
import { apiRoutes } from '@constants/apiRoutes';
import { FAQ_LINK } from '@constants/common';
import { ELEVATORS_TABS } from '@constants/elevator';
import { routes } from '@constants/routes';
import { AppTranslation } from '@domains/Intl/domains/App';
import { SessionUserAccountId } from '@helpers/account';
import { SessionToken } from '@helpers/auth';
import { getHeaderMenuItemsAccordingUserAppRole, headerMenuItemKeys } from '@helpers/navigation';
import { getHomeRoute } from '@helpers/routing';
import { useSession } from '@hooks/auth/useSession';
import axiosApi from '@lib/axiosApi';
import { SETTINGS_TABS } from '@type/settings';
import { IUser } from '@type/user';
import { AppTour } from '@ui-kit/appTour';
import { ReactComponent as BlocksIcon } from '@ui-kit/assets/icons/Blocks.svg';
import { ReactComponent as HeadsetIcon } from '@ui-kit/assets/icons/Headset.svg';
import { ReactComponent as ListTreeIcon } from '@ui-kit/assets/icons/ListTree.svg';
import { ReactComponent as LoggOutIcon } from '@ui-kit/assets/icons/Loggout.svg';
import { ReactComponent as SettingIcon } from '@ui-kit/assets/icons/Settings.svg';
import { useStorageManager } from '@ui-kit/hooks';
import { Layout, Navbar, pxToRem } from '@ui-kit/index';
import { useModal } from '@ui-kit/modal/Modal.hooks';
import { Text } from '@ui-kit/typography';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import { useLocation } from 'react-router';

const headerMenuItems = [
  {
    icon: BlocksIcon,
    title: <FormattedMessage id={AppTranslation['navbar.item.overview']} />,
    itemKey: routes.dashboard.get(),
    iconWidth: '19.5',
    iconHeight: '22.3',
    pageLink: routes.dashboard.get(),
    dataAttr: headerMenuItemKeys.dashboard,
  },
  {
    icon: ListTreeIcon,
    title: <FormattedMessage id={AppTranslation['navbar.item.objects']} />,
    itemKey: routes.objects.get(ELEVATORS_TABS.all),
    iconWidth: '19.5',
    iconHeight: '19.5',
    pageLink: routes.objects.get(ELEVATORS_TABS.all),
    dataAttr: headerMenuItemKeys.objects,
  },
  {
    icon: SettingIcon,
    title: <FormattedMessage id={AppTranslation['navbar.item.settings']} />,
    itemKey: routes.settings.get(SETTINGS_TABS.users),
    iconWidth: '19.5',
    iconHeight: '19.5',
    pageLink: routes.settings.get(SETTINGS_TABS.users),
    dataAttr: headerMenuItemKeys.settings,
  },
];

const footerMenuItems = ({ onLogout }: { onLogout: () => void }) => [
  {
    icon: HeadsetIcon,
    pageLink: FAQ_LINK,
    title: <FormattedMessage id={AppTranslation['navbar.item.FAQ']} />,
    itemKey: routes.FAQ.get(),
    isExternalLink: true,
    iconWidth: '19.5',
    iconHeight: '19.5',
  },
  {
    icon: LoggOutIcon,
    title: <FormattedMessage id={AppTranslation['navbar.item.logOut']} />,
    itemKey: 'logout',
    iconWidth: '19.5',
    iconHeight: '18',
    onClick: onLogout,
  },
];

const steps = [
  {
    title: <FormattedMessage id={AppTranslation['app-tour.title.welcome']} />,
    content: (
      <div>
        <Text
          textColor={'primary'}
          fontSize={pxToRem(15)}
          fontWeight={'medium'}
          lineHeight={'1.4'}
          margin={'0 0 10px 0'}
        >
          <FormattedMessage id={AppTranslation['app-tour.content.welcome.part1']} />
        </Text>
        <Text textColor={'primary'} fontSize={pxToRem(15)} fontWeight={'medium'} lineHeight={'1.4'}>
          <FormattedMessage id={AppTranslation['app-tour.content.welcome.part2']} />
        </Text>
      </div>
    ),

    target: 'body',
    placement: 'center' as const,
    disableBeacon: true,
    hideArrow: true,
    zIndex: 9999,
    id: 'main',
  },
  {
    title: <FormattedMessage id={AppTranslation['navbar.item.overview']} />,
    content: <FormattedMessage id={AppTranslation['app-tour.content.overview']} />,
    placement: 'right' as const,
    target: `[data-menu-item=${headerMenuItemKeys.dashboard}]`,
    disableBeacon: true,
    hideArrow: true,
    zIndex: 9999,
    id: headerMenuItemKeys.dashboard,
  },
  {
    title: <FormattedMessage id={AppTranslation['navbar.item.objects']} />,
    content: <FormattedMessage id={AppTranslation['app-tour.content.objects']} />,
    placement: 'right' as const,
    target: `[data-menu-item=${headerMenuItemKeys.objects}]`,
    disableBeacon: true,
    hideArrow: true,
    zIndex: 9999,
    id: headerMenuItemKeys.objects,
  },
];

export const PrivateLayout: React.FC<{ isAccountSwitcherRoute: boolean }> = ({ isAccountSwitcherRoute, children }) => {
  const [isRunAppTour, setIsRunAppTour] = useState(false);
  const { isOpenModal: isOpenLogoutModal, openModal: openLogoutModal, closeModal: closeLogoutModal } = useModal();
  const { currentUser, createSession } = useSession();
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();
  const [activeMenuItem, setActiveMenuItem] = useState<string | null | undefined>(pathname);
  const isFirstOpenAppStorage = useStorageManager({ key: 'isFirstOpenApp' });
  const headerMenuItemsAccordingUserAppRole = getHeaderMenuItemsAccordingUserAppRole(currentUser?.applicationRole);
  const queryClient = useQueryClient();
  const homeRoute = getHomeRoute(currentUser?.applicationRole, currentUser?.availableAccounts.length);

  const headerNavBarItems = headerMenuItems?.filter(item =>
    headerMenuItemsAccordingUserAppRole.includes(item.dataAttr),
  );

  const appTourSteps = steps.filter(step => ['main', ...headerMenuItemsAccordingUserAppRole].includes(step.id));

  const { mutateAsync: getCurrentUser } = useMutation(apiKeys.currentUser, async () => {
    const userData = await axiosApi.get<IUser>(apiRoutes.currentUser);
    return userData || null;
  });

  const checkUserAccountsUpdate = async () => {
    const { data } = await getCurrentUser();
    if (currentUser?.availableAccounts) {
      const currentUserAccountsNumber = currentUser?.availableAccounts.length;
      const updatedUserAccountsNumber = data.availableAccounts.length;
      if (currentUserAccountsNumber !== updatedUserAccountsNumber) {
        if (currentUserAccountsNumber > updatedUserAccountsNumber) {
          SessionUserAccountId.set(Number(currentUser?.mainAccount.id));
        }
        queryClient.invalidateQueries();
        createSession({ token: SessionToken.get()! });
      }
    }
  };

  useEffect(() => {
    if (pathname) {
      setActiveMenuItem(pathname);

      if (
        pathname === routes.objects.get(ELEVATORS_TABS.all) ||
        pathname === routes.dashboard.path ||
        pathname === routes.settings.get(SETTINGS_TABS.users)
      ) {
        checkUserAccountsUpdate();
      }
    }
  }, [pathname]);

  useEffect(() => {
    if ((!isFirstOpenAppStorage.item || isFirstOpenAppStorage.item === 'false') && pathname !== '/accounts') {
      isFirstOpenAppStorage.item = 'true';
      setIsRunAppTour(true);
    }

    return () => {
      setIsRunAppTour(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const onCloseLogoutModal = () => {
    closeLogoutModal();
    setActiveMenuItem(null);
  };

  return (
    <Layout
      navBar={
        <Navbar
          activeMenuItem={activeMenuItem}
          setActiveMenuItem={setActiveMenuItem}
          footerItems={footerMenuItems({ onLogout: openLogoutModal })}
          headerItems={headerNavBarItems}
          avatar={currentUser?.profileImage?.[60]}
          userName={`${currentUser?.firstName} ${currentUser?.lastName}`}
          userEmail={currentUser?.email}
          companyName={currentUser?.companyName}
          profileLink={routes.profile.path}
          isAccountSwitcherRoute={isAccountSwitcherRoute}
          availableAccounts={currentUser?.availableAccounts}
          homeRoute={homeRoute}
        />
      }
    >
      {children}
      <AppTour
        steps={appTourSteps}
        isRun={isRunAppTour}
        closeButtonTitle={formatMessage({ id: AppTranslation['app-tour.button.end-introduction'] })}
      />
      <Logout closeModal={onCloseLogoutModal} isOpenModal={isOpenLogoutModal} />
    </Layout>
  );
};
