import { inputStyles } from '@ui-kit/form/Input/common.styles';
import { InputBase, InputProps, StyledInputProps } from '@ui-kit/form/Input/InputBase';
import React from 'react';
import styled from 'styled-components';

export type HTMLInputProps = InputProps<StyledInputProps & React.InputHTMLAttributes<HTMLInputElement>>;

export const StyledInput = styled.input`
  ${inputStyles}
`;

export const Input = React.forwardRef<any, HTMLInputProps>((props, ref) => {
  return <InputBase ref={ref} {...props} customInput={StyledInput} />;
});
