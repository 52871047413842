export type TCommonColors = keyof typeof commonColors;

enum commonColors {
  white = '#fff',
  whiteSmoke = '#f9f5f3',
  whiteRose = '#f2edeb',
  wildSand = '#f4f4f4',
  greyDarkest = '#5d5e5d',
  greyDark = 'rgba(29, 45, 46, 0.8)',
  greyMiddle = 'rgba(168, 165, 165, 0.8)',
  greyLight = 'rgba(118, 118, 128, 0.12)',
  silverLight = 'rgba(29, 45, 46, 0.3)',
  silverMiddle = 'rgba(29, 45, 46, 0.6)',
  seaGreen = '#298675',
  darkenedSeaGreen = '#1a6d5e',
  cruelSeaGreen = '#1d2d2e',
  everGreen = '#0f5145',
  middleGreen = '#156859',
  yellow = '#f3bb4d',
  red = '#ee4669',
  black = '#000',
  eerieBlack = '#181818',
}

export const palette = {
  commonColors,
};
