import { imageScalingTransitionCss } from '@ui-kit/image/Image.styles';
import styled from 'styled-components';

const mapAvatarSize = {
  s: '60px',
  m: '134px',
};

export type TAvatarSize = keyof typeof mapAvatarSize;

export const AvatarContainer = styled.div<{ size?: TAvatarSize }>`
  aspect-ratio: 1 / 1;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  width: ${({ size = 's' }) => mapAvatarSize[size]};
  height: ${({ size = 's' }) => mapAvatarSize[size]};
  background: transparent;
`;

export const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  display: block;
  object-fit: cover;
  color: transparent;
  aspect-ratio: inherit;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  ${imageScalingTransitionCss}
`;
