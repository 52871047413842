import { pxToRem } from '@ui-kit/helpers';
import { Text } from '@ui-kit/typography';
import React from 'react';

interface ErrorMessageProps {
  error?: string;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ error }) => {
  if (!error) return null;
  return (
    <Text
      fontVariant={'primary'}
      fontSize={pxToRem(12)}
      margin={'10px 0 5px 0'}
      textColor={'error'}
      fontWeight={'bold'}
    >
      {error}
    </Text>
  );
};
