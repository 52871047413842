import { TypeRadio } from '@ui-kit/form/Radio';
import { outlineColor } from '@ui-kit/globalStyles';
import { themeMode } from '@ui-kit/theme';
import { Text as UiText } from '@ui-kit/typography';
import styled, { StyledProps } from 'styled-components';
import theme from 'styled-theming';

const color = theme.variants('mode', 'kind', {
  primary: {
    [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen,
  },
  secondary: {
    [themeMode.light]: ({ theme }) => theme.palette.commonColors.eerieBlack,
  },
});

interface IProps {
  checked: boolean;
  type: TypeRadio;
}

export const PrimaryCustomRadio = styled.span<IProps>`
  display: inline-block;
  border-radius: ${({ theme }) => theme.borderRadius.circle};
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  border: 2px solid ${props => color({ ...props, kind: 'primary' })};
  background-color: ${props => (props.checked ? color({ ...props, kind: 'primary' }) : 'transparent')};
`;

const getCheckedColor = (props: StyledProps<{ checked: boolean }>) => {
  return color({ ...props, kind: props.checked ? 'primary' : 'secondary' });
};

export const SecondaryCustomRadio = styled.span<IProps>`
  display: inline-flex;
  border: 2px solid ${getCheckedColor};
  border-radius: ${({ theme }) => theme.borderRadius.circle};
  height: 26px;
  width: 26px;
  box-sizing: border-box;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    display: ${props => (props.checked ? 'inline-block' : 'none')};
    transform: translate(-50%, -50%);
    left: 50%;
    width: 14px;
    height: 14px;
    border-radius: ${({ theme }) => theme.borderRadius.circle};
    background-color: ${getCheckedColor};
    box-sizing: border-box;
  }
`;

export const Label = styled.label`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1;
  outline: none;
  cursor: pointer;

  & input[type='radio'] {
    bottom: 0;
    cursor: pointer;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    margin: 0;
    &:focus + .custom-radio {
      box-shadow: 0 0 0 2px ${outlineColor};
    }
  }
`;

export const Text = styled(UiText)`
  margin-left: 10px;
`;
