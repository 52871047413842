import { VIEWPORT_HEIGHT } from '@ui-kit/constants';
import { OverlayProps } from '@ui-kit/overlay/Overlay';
import { themeMode } from '@ui-kit/theme';
import styled from 'styled-components';
import theme from 'styled-theming';

const background = theme.variants('mode', 'backgroundType', {
  primary: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.silverLight },
  darken: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.silverMiddle },
});

export const Overlay = styled.div<OverlayProps>`
  background-color: ${background} !important;
  top: 0;
  left: 0;
  width: 100vw;
  height: var(${VIEWPORT_HEIGHT});
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${({ theme }) => theme.zIndex._7};
  ${({ styles }) => styles};
`;
