import { apiKeys } from '@constants/apiKeys';
import { apiRoutes } from '@constants/apiRoutes';
import { LOADING_KEYS } from '@constants/loading';
import { useLoadingContext } from '@domains/AppRoot/Providers/LoadingProvider';
import { showErrorMessage } from '@helpers/error';
import { getHomeRoute } from '@helpers/routing';
import { useSession } from '@hooks/auth/useSession';
import axiosApi from '@lib/axiosApi';
import { IUser } from '@type/user';
import React from 'react';
import { Control, DeepMap, FieldError, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

interface OptionsUseLoginType {
  username: string;
  password: string;
}

export interface IUseLoginReturnType {
  control: Control<any>;
  onLogin: (e: React.SyntheticEvent) => Promise<void>;
  formErrors: DeepMap<Record<string, any>, FieldError> | null;
  responseError?: unknown;
}

interface ILoginResponse {
  token: string;
  data: {
    user: IUser;
  };
}

export const useLogin = (): IUseLoginReturnType => {
  const history = useHistory();
  const { setLoading, removeLoading } = useLoadingContext();
  const { createSession } = useSession();

  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const { mutate } = useMutation(
    apiKeys.login,
    (data: OptionsUseLoginType): Promise<ILoginResponse> => {
      setLoading(LOADING_KEYS.login);
      return axiosApi.post(apiRoutes.login, data)?.then(res => res.data);
    },
    {
      onSuccess: async ({ token, data: { user } }) => {
        await createSession({ token });
        const route = getHomeRoute(user?.applicationRole, user?.availableAccounts.length);
        history.push(route);
      },
      onError: showErrorMessage(),
      onSettled: removeLoading(LOADING_KEYS.login),
    },
  );

  const onSubmit: SubmitHandler<OptionsUseLoginType> = credentials => {
    mutate(credentials);
  };

  return { control, onLogin: handleSubmit(onSubmit), formErrors };
};
