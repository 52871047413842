import { CaretArrow } from '@ui-kit/arrow';
import { HeaderTableRow, TableHeaderCell } from '@ui-kit/table/common/Common.styles';
import React from 'react';
import { HeaderGroup } from 'react-table';

interface Props {
  headerGroups: Array<HeaderGroup<any>>;
}

export const FixedHeaderContent: React.FC<Props> = ({ headerGroups }) => {
  return (
    <>
      {headerGroups?.map(headerGroup => (
        <HeaderTableRow {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map(column => {
            const typeArrow = column.isSorted ? (column.isSortedDesc ? 'down' : 'up') : '';

            return (
              <TableHeaderCell
                // @ts-ignore
                mediaQueries={column.style?.mediaQueries}
                {...column.getHeaderProps([
                  column.getSortByToggleProps({ title: undefined }),
                  {
                    // @ts-ignore
                    style: column.style,
                  },
                ])}
                tabIndex={column?.canSort === false ? -1 : 0}
                onKeyDown={event => {
                  if (column?.canSort !== false && (event.key === 'Enter' || event.key === ' ')) column.toggleSortBy();
                }}
              >
                {column.render('Header')}
                {!column.disableSortBy && typeArrow && (
                  <CaretArrow margin="0 0 0 10px" color="primary" type={typeArrow} />
                )}
              </TableHeaderCell>
            );
          })}
        </HeaderTableRow>
      ))}
    </>
  );
};
