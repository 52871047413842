import { ReactComponent as Error } from '@ui-kit/assets/icons/Error.svg';
import { SvgIcon } from '@ui-kit/icon';
import React, { ChangeEvent } from 'react';

import { ErrorMessage } from '../ErrorMessage';
import { Inner, InnerIcon, InnerPrefixIcon, Label } from './common.styles';

enum Variant {
  primary = 'primary',
  secondary = 'secondary',
}

export type TypeVariant = keyof typeof Variant;

export interface StyledInputProps {
  type?: React.InputHTMLAttributes<unknown>['type'];
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  name?: string;
  value?: string | ReadonlyArray<string> | number;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export type InputProps<T extends StyledInputProps = StyledInputProps> = Omit<InputBaseProps<T>, 'customInput'> &
  React.InputHTMLAttributes<HTMLInputElement>;

export type InputBaseProps<T extends StyledInputProps> = {
  name?: string;
  error?: string;
  isError?: boolean;
  variant?: TypeVariant;
  icon?: React.ReactNode;
  iconClick?: () => void;
  prefixIcon?: React.ReactNode;
  customInput: React.ComponentType<StyledInputProps>;
  className?: string;
  htmlRef?: React.Ref<HTMLInputElement>;
  'data-testid'?: string;
} & T;

const InputBaseInner = <T extends StyledInputProps>(
  {
    className,
    value,
    onChange,
    variant = 'primary',
    name,
    error,
    isError: isErrorProps,
    type,
    placeholder,
    icon,
    iconClick,
    prefixIcon,
    customInput,
    htmlRef,
    'data-testid': dataTestId,
    ...props
  }: InputBaseProps<T>,
  ref: React.ForwardedRef<HTMLInputElement>,
) => {
  const InputComponent = customInput;
  const isError = Boolean(error) || isErrorProps;

  return (
    <Inner>
      <Label
        className={className}
        variant={variant}
        $isError={isError}
        disabled={props.disabled}
        readOnly={props.readOnly}
      >
        {Boolean(prefixIcon) && <InnerPrefixIcon>{prefixIcon}</InnerPrefixIcon>}
        <InputComponent
          {...props}
          name={name}
          value={value}
          onChange={onChange}
          type={type}
          placeholder={placeholder}
          data-testid={dataTestId}
          {...(htmlRef ? { htmlRef } : { ref })}
        />
        {Boolean(icon) && (
          <InnerIcon onClick={iconClick} clickable={!!iconClick}>
            {icon}
          </InnerIcon>
        )}
        {isError && (
          <InnerIcon>
            <SvgIcon icon={Error} color={'error'} width={20} height={20} />
          </InnerIcon>
        )}
      </Label>
      <ErrorMessage error={error} />
    </Inner>
  );
};

export const InputBase = React.forwardRef(InputBaseInner);
