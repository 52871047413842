import { pxToRem } from '@ui-kit/helpers';
import { Text } from '@ui-kit/typography';
import React from 'react';

import { Field, StyledTextArea } from './Textarea.styles';

export interface TextAreaProps {
  name?: string;
  value: string | undefined;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  className?: string;
  rows?: number;
  resize?: boolean;
  isError?: boolean;
  error?: string;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps & JSX.IntrinsicElements['textarea']>(
  (
    {
      className,
      disabled,
      name,
      onChange,
      placeholder,
      required,
      value,
      rows,
      resize,
      error,
      isError: isErrorProps,
      ...props
    },
    ref,
  ) => {
    const internalValue = typeof value === 'string' ? value : '';

    const internalRows = rows || 4;

    const isError = Boolean(error) || isErrorProps;

    return (
      <>
        <Field $isError={isError} className={className} rows={internalRows}>
          <StyledTextArea
            {...props}
            $isError={isError}
            disabled={disabled}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            ref={ref}
            required={Boolean(required)}
            resize={Boolean(resize)}
            rows={internalRows}
            value={internalValue}
          />
        </Field>
        {isError && (
          <Text
            fontVariant={'primary'}
            fontSize={pxToRem(12)}
            margin={'10px 0 5px 0'}
            textColor={'error'}
            fontWeight={'bold'}
          >
            {error}
          </Text>
        )}
      </>
    );
  },
);
