import { TooltipComponent } from '@ui-kit/tooltip/Tooltip.styles';
import React from 'react';
import { TooltipProps as ReactTooltipProps } from 'react-tooltip';

export interface TooltipProps extends ReactTooltipProps {
  isHoldingTooltip?: boolean;
  InnerTooltip?: React.FC;
}

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  id,
  InnerTooltip,
  place = 'bottom',
  isHoldingTooltip,
  delayUpdate = 1000,
  padding = '0',
  delayHide = 10,
  ...tooltipProps
}) => {
  return (
    <TooltipComponent
      scrollHide
      isHoldingTooltip={isHoldingTooltip}
      borderColor={'transparent'}
      backgroundColor={'transparent'}
      delayUpdate={delayUpdate}
      padding={padding}
      id={id}
      effect="solid"
      place={place}
      delayHide={delayHide}
      {...tooltipProps}
    >
      {InnerTooltip ? <InnerTooltip>{children}</InnerTooltip> : children}
    </TooltipComponent>
  );
};
