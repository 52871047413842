export type LoadingKeysType = keyof typeof LOADING_KEYS;

export const LOADING_KEYS = {
  elevatorEntries: 'elevatorEntries',
  changePassword: 'changePassword',
  resetPassword: 'resetPassword',
  login: 'login',
  companies: 'companies',
  addToFavoriteLists: 'addToFavoriteLists',
  createFavoriteList: 'createFavoriteList',
  userFavoriteLists: 'userFavoriteLists',
  removeFromFavorites: 'removeFromFavorites',
  newEntry: 'newEntry',
  deleteEntry: 'deleteEntry',
  entryImage: 'entryImage',
  entriesUsers: 'entriesUsers',
  entryCategories: 'entryCategories',
  entryTypes: 'entryTypes',
  createElevator: 'createElevator',
  editElevator: 'editElevator',
  elevator: 'elevator',
  elevatorAlarmLineTypes: 'elevatorAlarmLineTypes',
  elevatorAlarmTypes: 'elevatorAlarmTypes',
  elevatorLineProviders: 'elevatorLineProviders',
  elevatorModels: 'elevatorModels',
  elevators: 'elevators',
  elevatorManufacturers: 'elevatorManufacturers',
  giveUserAccessToElevator: 'giveUserAccessToElevator',
  giveCompanyAccessToElevator: 'giveCompanyAccessToElevator',
  setLocationMapToElevator: 'setLocationMapToElevator',
  elevatorTypes: 'elevatorTypes',
  users: 'users',
  settingsUsers: 'settingsUsers',
  deactivateUser: 'deactivateUser',
  activateUser: 'activateUser',
  deleteUser: 'deleteUser',
  createUser: 'createUser',
  editUser: 'editUser',
  domainRoles: 'domainRoles',
  profileChangePassword: 'profileChangePassword',
  uploadProfileImage: 'uploadProfileImage',
  deleteProfileImage: 'deleteProfileImage',
  archivedElevators: 'archivedElevators',
  archiveElevators: 'archiveElevators',
  account: 'account',
  editAccount: 'editAccount',
  membershipTypes: 'membershipTypes',
  logout: 'logout',
  editFavoriteList: 'editFavoriteList',
  deleteFavoriteList: 'deleteFavoriteList',
  accounts: 'accounts',
  user: 'user',
  removeUserAccessToElevators: 'removeUserAccessToElevators',
  sendEmailForCreateExternalUser: 'sendEmailForCreateExternalUser',
  createExternalUser: 'createExternalUser',
  companyTypes: 'companyTypes',
  accountCompanyAccesses: 'accountCompanyAccesses',
  addAccountCompanyAccesses: 'addAccountCompanyAccesses',
  removeAccountCompanyAccesses: 'removeAccountCompanyAccesses',
  company: 'company',
  removeCompanyAccessToElevators: 'removeCompanyAccessToElevators',
} as const;
