import React from 'react';

const cancelEvent = (e: React.DragEvent) => {
  e.preventDefault();
  e.stopPropagation();
};

export interface DropZoneProps {
  onChange: (fileList: File[]) => void;
}

export const useFilesDropZone = (props: DropZoneProps) => {
  const { onChange } = props;

  const [isDragging, setIsDragging] = React.useState(false);

  const handleDragEnter = (event: React.DragEvent) => {
    // Only  with files
    if (event.dataTransfer?.types?.includes('Files')) {
      setIsDragging(true);
    }
  };

  const handleDragLeave = (event: React.DragEvent) => {
    if (!isDragging) return;

    const { currentTarget, clientX, clientY } = event;
    const elementFromPoint = document.elementFromPoint(clientX, clientY);

    if (elementFromPoint instanceof Node && currentTarget instanceof Node) {
      if (currentTarget.contains(elementFromPoint)) return;
    }

    setIsDragging(false);
  };

  const handleDrop = (event: React.DragEvent) => {
    cancelEvent(event);
    onChange(Array.from(event.dataTransfer.files || []));
    setIsDragging(false);
  };

  return {
    isDragging,
    containerProps: {
      onDragEnter: handleDragEnter,
      onDragLeave: handleDragLeave,
      onDragOver: cancelEvent,
      onDrop: handleDrop,
    },
  };
};
