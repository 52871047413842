import { VIEWPORT_HEIGHT } from '@ui-kit/constants';
import { theme, themeMode } from '@ui-kit/theme';
import React, { FC, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';

export const StyledThemeProvider: FC = ({ children }) => {
  const viewHeight = () => {
    const doc = document.documentElement;
    const height = window.innerHeight;
    doc.style.setProperty(VIEWPORT_HEIGHT, `${height}px`);
  };
  useEffect(() => {
    window.addEventListener('resize', viewHeight);
    viewHeight();
    return () => {
      window.removeEventListener('resize', viewHeight);
    };
  }, []);

  return <ThemeProvider theme={{ mode: themeMode.light, ...theme }}>{children}</ThemeProvider>;
};
