export const CONTACT_EMAIL = 'kundeservice@nhk.no';

export const PRIVACY_POLICY_LINK = 'https://lilo.nhk.no/personvern';

export const FAQ_LINK = 'https://lilo.nhk.no/kontakt-oss';

export const SUBSCRIPTION_LINK = 'https://lilo.nhk.no/prices';

export const ITEMS_PER_PAGE = 50;

export enum CSS_PROPS {
  FIXED_HEADER_HIGHT = '--sticky-header-height',
}

export const ACTIVATION_TOKEN = 'activation_token';

export const FREE_SEARCH_CREATE_OPTION_ID = 'freeSearch';
