import { apiKeys } from '@constants/apiKeys';
import { routes } from '@constants/routes';
import { useLoadingContext } from '@domains/AppRoot/Providers/LoadingProvider';
import { AppTranslation } from '@domains/Intl/domains/App';
import { IUserAccountWithDomainRole } from '@type/user';
import { Button } from '@ui-kit/button';
import { pxToRem } from '@ui-kit/helpers';
import {
  MenuButtonWrapper,
  MenuContent,
  MenuHeader,
  MenuInfoContent,
  MenuOption,
} from '@ui-kit/navbar/Drawer/UserProfile/ProfileMenuContent/ProfileMenuContent.styles';
import { Text } from '@ui-kit/typography';
import React from 'react';
import { useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

export interface ProfileMenuContentProps {
  userName: string;
  userEmail?: string;
  profileLink: string;
  activeUserAccount?: IUserAccountWithDomainRole;
  accounts?: IUserAccountWithDomainRole[];
  selectUserAccount: (id: number) => void;
  closeSettingsMenu: () => void;
  toggleNavMenu?: () => void;
  companyName?: string;
}

export const ProfileMenuContent: React.FC<ProfileMenuContentProps> = ({
  userName,
  userEmail,
  profileLink,
  activeUserAccount,
  accounts,
  selectUserAccount,
  closeSettingsMenu,
  toggleNavMenu,
  companyName,
}) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { removeAllLoading } = useLoadingContext();
  const queryClient = useQueryClient();

  const closeMenus = () => {
    closeSettingsMenu();
    toggleNavMenu && toggleNavMenu();
  };

  return (
    <>
      <MenuHeader>
        <Text fontSize={pxToRem(15)} fontWeight={'bold'} textColor={'secondary'}>
          {activeUserAccount?.account.name || companyName}
        </Text>
      </MenuHeader>
      <MenuInfoContent>
        <Text fontWeight={'bold'} fontSize={pxToRem(15)} textColor={'senary'} fontVariant={'primary'}>
          {userName}
        </Text>
        <Text
          fontWeight={'medium'}
          fontSize={pxToRem(13)}
          textColor={'senary'}
          fontVariant={'primary'}
          margin={'0 0 24px 0'}
        >
          {userEmail}
        </Text>
        <Button
          elementType={'link'}
          to={profileLink}
          background={'transparent'}
          borderColor={'primary'}
          borderRadius={'xs'}
          width={'100%'}
          onClick={closeMenus}
        >
          <Text fontVariant={'primary'} textColor={'tertiary'} fontSize={pxToRem(15)} fontWeight={'medium'}>
            {formatMessage({ id: AppTranslation['navbar.user.profile-settings'] })}
          </Text>
        </Button>
      </MenuInfoContent>
      {!!accounts?.length && (
        <MenuContent>
          <Text
            fontVariant={'primary'}
            textColor={'quinary'}
            fontSize={pxToRem(13)}
            fontWeight={'medium'}
            margin={'0 0 16px 0'}
            padding={'0 32px 0 32px'}
          >
            {formatMessage({ id: AppTranslation['navbar.user.change-company'] })}
          </Text>
          {accounts?.slice(0, 3).map(item => (
            <MenuOption
              key={item.account.id}
              onClick={() => {
                selectUserAccount(item.account.id);
                closeMenus();
                queryClient.invalidateQueries({ ...apiKeys.dashboard() });
                removeAllLoading();
                history.push(routes.dashboard.path);
              }}
            >
              <Text
                fontVariant={'primary'}
                textColor={'primary'}
                fontSize={pxToRem(15)}
                fontWeight={'medium'}
                lineHeight={'1.2'}
              >
                {item.account.name}
              </Text>
            </MenuOption>
          ))}
          {accounts?.length > 3 && (
            <MenuButtonWrapper>
              <Button
                elementType={'link'}
                to={routes.accountSwitcher.path}
                background={'transparent'}
                borderColor={'primary'}
                borderRadius={'xs'}
                width={'100%'}
                onClick={closeMenus}
              >
                <Text fontVariant={'primary'} textColor={'tertiary'} fontSize={pxToRem(15)} fontWeight={'medium'}>
                  {formatMessage({ id: AppTranslation['navbar.user.all-companies'] })}
                </Text>
              </Button>
            </MenuButtonWrapper>
          )}
        </MenuContent>
      )}
    </>
  );
};
