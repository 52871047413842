import { FileInfo } from '@type/file';

export const isString = (value: unknown): value is string => typeof value === 'string';

export const isNumber = (value: unknown): value is number => typeof value === 'number';

export const isFile = (value: unknown): value is File => value instanceof File;

export const isFileInfo = (value: unknown): value is FileInfo => {
  return isString((value as FileInfo)?.url) && isString((value as FileInfo)?.name);
};
