import styled, { css } from 'styled-components';

export const imageOpacityTransitionCss = css`
  transition: opacity 0.5s ease-out;
  opacity: ${({ isLoaded }: { isLoaded: boolean }) => (isLoaded ? 1 : 0)};
`;
export const imageScalingTransitionCss = css`
  transition: transform 1s cubic-bezier(0, 0.71, 0.19, 1.02), opacity 0.5s ease-out;
  transform: scale(${({ isLoaded }: { isLoaded: boolean }) => (isLoaded ? 1 : 0.4)});
  opacity: ${({ isLoaded }: { isLoaded: boolean }) => (isLoaded ? 1 : 0)};
`;

export const ImageContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UiImage = styled.img.attrs({ className: 'image-component' })<{
  aspectRatio?: string;
  $objectFit?: string;
  isLoaded: boolean;
}>`
  display: block;
  width: 100%;
  height: 100%;
  opacity: 1;
  object-fit: ${({ $objectFit }) => $objectFit || 'cover'};
  ${({ aspectRatio }) => aspectRatio && `aspect-ratio: ${aspectRatio}`};
  ${imageOpacityTransitionCss};
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;
