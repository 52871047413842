import styled from 'styled-components';

export const MenuWrapper = styled.div`
  width: 100%;
`;

export const LabelUserWrapper = styled.div`
  width: 100%;
  cursor: pointer;
`;
