import styled, { css } from 'styled-components';

export const Container = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  ${({ theme }) => theme.media.downWith.m} {
    justify-content: center;
  }
`;

const styles = css`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  ${styles};
`;

export const Footer = styled.div`
  ${styles};
`;
