import Cookies from 'js-cookie';

export class SessionUserAccountId {
  static accountKey = 'user-account-id';

  static has = () => {
    return Boolean(Cookies.get(SessionUserAccountId.accountKey)?.trim());
  };

  static get = (): string | null => {
    return Cookies.get(SessionUserAccountId.accountKey) || null;
  };

  static set = (id: number): void => {
    Cookies.set(SessionUserAccountId.accountKey, String(id));
  };

  static reset = () => {
    Cookies.remove(SessionUserAccountId.accountKey);
  };
}
