import { ChevronArrow } from '@ui-kit/index';
import React from 'react';
import { components, DropdownIndicatorProps } from 'react-select';
import { GroupBase } from 'react-select/dist/declarations/src/types';
import styled from 'styled-components';

const Inner = styled.div<{ menuIsOpen: boolean }>`
  box-sizing: border-box;
  padding-bottom: ${({ menuIsOpen }) => (menuIsOpen ? 0 : '2px')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Indicator = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: DropdownIndicatorProps<Option, IsMulti, Group>,
) => {
  const { menuIsOpen } = props.selectProps;
  return (
    <components.DropdownIndicator {...props}>
      <Inner menuIsOpen={menuIsOpen}>
        <ChevronArrow color={'secondary'} size={6} borderWidth={2} type={menuIsOpen ? 'up' : 'down'} />
      </Inner>
    </components.DropdownIndicator>
  );
};
