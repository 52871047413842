import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const LinkContainer = styled(Link)`
  text-decoration: none;
`;
