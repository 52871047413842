import React, { ChangeEvent, useRef } from 'react';

import { StyledInput } from './Placeholder.styles';

interface TProps {
  placeholder: string;
  inputValue: string;
  onInputChange: (value: string, e: ChangeEvent<HTMLInputElement>) => void;
  setIsFocused: any;
}

export const Placeholder: React.FC<TProps> = ({ placeholder, inputValue, onInputChange, setIsFocused }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    onInputChange(e.target.value, e);
  };
  const onFocus = () => setIsFocused(true);
  const onBlur = () => setIsFocused(false);
  return (
    <StyledInput
      onFocus={onFocus}
      onBlur={onBlur}
      ref={inputRef}
      placeholder={placeholder}
      value={inputValue}
      onChange={onChange}
    />
  );
};
