import { CustomCheckbox, InnerCheckbox } from '@ui-kit/form/Checkbox/Checkbox.styles';
import React, { HTMLAttributes, SyntheticEvent } from 'react';

export interface CheckboxProps extends HTMLAttributes<any> {
  checked?: boolean;
  disabled?: boolean;
  defaultChecked?: boolean;
  indeterminate?: boolean;
  id?: string;
  name?: string;
  onChange?: (e: React.ChangeEvent) => void;
  onClick?: (e: SyntheticEvent) => void;
}

export const Checkbox = ({
  checked,
  disabled,
  id,
  name,
  onChange,
  indeterminate,
  defaultChecked,
  tabIndex = 0,
  ...props
}: CheckboxProps) => {
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const onEnter = (event: any) => {
    event.stopPropagation();
    event.keyCode !== 13 || inputRef?.current?.click();
  };

  return (
    <InnerCheckbox>
      <input
        {...props}
        ref={inputRef}
        defaultChecked={defaultChecked}
        checked={checked || indeterminate}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        type="checkbox"
      />
      <CustomCheckbox
        tabIndex={disabled ? -1 : tabIndex}
        onKeyDown={onEnter}
        aria-hidden="true"
        indeterminate={indeterminate}
      />
    </InnerCheckbox>
  );
};
