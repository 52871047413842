import { ReactComponent as ArrowIcon } from '@ui-kit/assets/icons/LongRightArrow.svg';
import { Button, ButtonProps } from '@ui-kit/button';
import { SvgIcon } from '@ui-kit/icon';
import React from 'react';

export const ButtonWithArrow: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <Button {...props} icon={<SvgIcon icon={ArrowIcon} color={'primary'} width={20} height={15} />}>
      {children}
    </Button>
  );
};
