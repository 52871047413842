import { pxToRem } from '@ui-kit/helpers';
import { SvgIcon } from '@ui-kit/icon';
import { Content, NavItem, NavLink } from '@ui-kit/navbar/Menu/MenuItem.styles';
import { Text } from '@ui-kit/typography';
import React from 'react';

import { MenuItemProps } from '../Navbar.types';

const Item: React.FC<Omit<MenuItemProps, 'setActiveMenuItem' | 'icon' | 'itemKey'>> = ({
  children,
  pageLink,
  isExternalLink,
  isActive,
  toggleMenu,
  dataAttr,
  ...props
}) => {
  const onEnter = (event: any) => event.keyCode !== 13 || props?.onClick?.();
  if (!pageLink) {
    return (
      <NavItem tabIndex={0} isActive={isActive} {...props} data-menu-item={dataAttr} onKeyDown={onEnter}>
        {children}
      </NavItem>
    );
  }
  const linkAttr = isExternalLink ? 'href' : 'to';
  return (
    <NavLink {...{ [linkAttr]: pageLink }} isActive={isActive} data-menu-item={dataAttr} onKeyDown={onEnter}>
      {children}
    </NavLink>
  );
};

export const MenuItem: React.FC<MenuItemProps> = ({
  isActive,
  setActiveMenuItem,
  icon,
  iconWidth,
  iconHeight,
  pageLink,
  title,
  itemKey,
  dataAttr,
  onClick,
  isExternalLink,
  toggleMenu,
  ...props
}) => {
  return (
    <Item
      pageLink={pageLink}
      dataAttr={dataAttr}
      isActive={isActive}
      isExternalLink={isExternalLink}
      onClick={setActiveMenuItem(itemKey, onClick)}
      toggleMenu={toggleMenu}
      {...props}
    >
      <SvgIcon
        className={'menu-item-svg'}
        typeColorProperty={'stroke'}
        fill={'none'}
        icon={icon}
        color={isActive ? 'primary' : 'tertiary'}
        width={iconWidth}
        height={iconHeight}
      />
      <Content>
        <Text
          className={'menu-item-content'}
          as={'span'}
          fontWeight={'medium'}
          fontSize={pxToRem(15)}
          fontVariant={'primary'}
          textColor={isActive ? 'secondary' : 'primary'}
          cursor={'pointer'}
        >
          {title}
        </Text>
      </Content>
    </Item>
  );
};
