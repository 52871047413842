import { Dialog } from '@components/common/Dialog';
import { ReactComponent as ArrowIcon } from '@ui-kit/assets/icons/LongRightArrow.svg';
import { ReactComponent as Arrow } from '@ui-kit/assets/icons/ShortRightArrow.svg';
import { Button, IconButton as RightIconButton } from '@ui-kit/button';
import { Radio } from '@ui-kit/form';
import { pxToRem } from '@ui-kit/helpers';
import { SvgIcon } from '@ui-kit/icon';
import { Text } from '@ui-kit/typography';
import React from 'react';
import { StoreHelpers, TooltipRenderProps } from 'react-joyride';

import {
  ContainerToolTip,
  Content,
  Footer,
  InnerNextButtons,
  InnerRadioButtons,
  LeftIconButton,
} from './Tooltip.styles';

export const Tooltip = ({
  index,
  isLastStep,
  step,
  closeProps,
  backProps,
  size,
  primaryProps,
  tooltipProps,
  helpers,
  closeButtonTitle,
}: TooltipRenderProps & { helpers?: StoreHelpers; closeButtonTitle: string }) => {
  const changeStep = (btnIndex: number) => () => {
    helpers && helpers.go(btnIndex);
  };
  return (
    <ContainerToolTip {...tooltipProps}>
      <Dialog
        onClosePopup={closeProps.onClick}
        HeaderComponent={
          <Text textColor={'secondary'} fontSize={pxToRem(23)} fontWeight={'bold'}>
            {step.title}
          </Text>
        }
      >
        <Content>
          <Text textColor={'primary'} fontSize={pxToRem(15)} fontWeight={'medium'} lineHeight={'1.4'}>
            {step.content}
          </Text>
        </Content>
        <Footer>
          <InnerRadioButtons>
            {Array.from({ length: size }, (v, i) => i).map((_, btnIndex) => (
              <Radio
                name={'tour-btn'}
                type={'primary'}
                key={`btn-${btnIndex}`}
                value={`${btnIndex}`}
                checked={index === btnIndex}
                //@ts-ignore
                onChange={changeStep(btnIndex)}
              />
            ))}
          </InnerRadioButtons>
          <InnerNextButtons>
            {index > 0 && !isLastStep && (
              <LeftIconButton
                {...backProps}
                buttonSize={'36px'}
                borderColor={'secondary'}
                icon={<SvgIcon icon={Arrow} typeColorProperty={'stroke'} color={'secondary'} width={32} height={32} />}
              />
            )}
            {isLastStep ? (
              <Button
                {...closeProps}
                borderRadius={'xs'}
                background={'primary'}
                textColor={'secondary'}
                icon={<SvgIcon icon={ArrowIcon} color={'primary'} width={20} height={15} />}
              >
                {closeButtonTitle}
              </Button>
            ) : (
              <RightIconButton
                {...primaryProps}
                buttonSize={'36px'}
                borderColor={'secondary'}
                icon={<SvgIcon icon={Arrow} typeColorProperty={'stroke'} color={'secondary'} width={32} height={32} />}
              />
            )}
          </InnerNextButtons>
        </Footer>
      </Dialog>
    </ContainerToolTip>
  );
};
