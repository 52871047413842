import { SelectOption } from '@helpers/select';
import { IUseSelectOptionsForCreateEditElevatorResult } from '@hooks/elevator';
import { ImageType } from '@type/image';
import { IUser } from '@type/user';
import { IResultUseModal } from '@ui-kit/modal/Modal.hooks';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { QueryKey } from 'react-query/types/core/types';

export interface IEntity {
  name: ElevatorTypes;
  id: number;
  deletedAt?: string;
}

export interface IElevator {
  locationMap?: ImageType;
  model: IEntity;
  manufacturer: IEntity;
  fabricatedAt: string;
  address: string;
  renovatedAt?: string;
  niNumber: string;
  fabricationNumber: string;
  description: string;
  alarmPhoneNumber?: string;
  privateRoomAccess: boolean;
  archived: boolean;
  type: {
    slug: ElevatorTypes;
    id: number;
    name: string;
  };
  alarmType?: IEntity;
  alarmLineType?: IEntity;
  mazeMapUrl?: string;
  safetyInspectionCompany: IEntity;
  inspectionCompany: IEntity;
  alarmCompany?: IEntity;
  lineProvider?: IEntity;
  lastLogEntry?: { createdAt: string };
  createdAt: string;
  id: number;
  updatedAt: string;
  contactPersons?: IUser[];
  account: {
    name: string;
    id: string;
  };
}

export enum ElevatorField {
  type = 'type',
  niNumber = 'niNumber',
  description = 'description',
  address = 'address',
  lastLogEntry = 'lastLogEntry',
  account = 'account',
}

export interface IElevatorType {
  name: string;
  createdAt: string;
  id: number;
}

export enum ElevatorTypes {
  'passenger_lift' = 'passenger_lift',
  'escalator' = 'escalator',
  'moving_walkway' = 'moving_walkway',
  'lifting_platform' = 'lifting_platform',
  'goods_lift' = 'goods_lift',
  'small_goods_lift' = 'small_goods_lift',
  'goods_and_passenger_lift' = 'goods_and_passenger_lift',
  'parking_facilities' = 'parking_facilities',
  'lifting_table' = 'lifting_table',
  'slope_lift' = 'slope_lift',
  'stair_lift' = 'stair_lift',
}

export interface IElevatorsListProps {
  elevators: IElevator[];
  elevatorsQuantity: number;
  fetchMore: () => void;
  height?: number;
  useWindowScroll?: boolean;
  goToElevatorPage: <E extends { id: number }>(elevator: E) => () => void;
  filterFreeSearchText?: string;
  addingToFavoriteData: { isOpenFavoriteModal: boolean; closeFavoriteModal: () => void; openFavoriteModal: () => void };
  invalidateQueryKey?: QueryKey;
  archiveElevatorsModalData: IResultUseModal;
  companiesConfirmModalData: {
    isOpenCompaniesConfirmModal: boolean;
    closeCompaniesConfirmModal: () => void;
    openCompaniesConfirmModal: () => void;
  };
  invalidateQueryKeyForArchiving: QueryKey;
  isExternalUser?: boolean;
  hasNextPage?: boolean;
  isLoading?: boolean;
}

export enum ElevatorAccessFormField {
  'user' = 'user',
  'company' = 'company',
  'safetyInspectionCompany' = 'safetyInspectionCompany',
  'inspectionCompany' = 'inspectionCompany',
  'alarmCompany' = 'alarmCompany',
}

export type FavoriteElevatorType = Omit<
  IElevator,
  | 'alarmPhoneNumber'
  | 'locationMap'
  | 'fabricatedAt'
  | 'renovatedAt'
  | 'privateRoomAccess'
  | 'alarmType'
  | 'alarmLineType'
  | 'mazeMapUrl'
  | 'safetyInspectionCompany'
  | 'inspectionCompany'
  | 'alarmCompany'
  | 'lineProvider'
  | 'contactPersons'
  | 'updatedAt'
>;

export interface IFavoriteElevator {
  elevator: FavoriteElevatorType;
}

export interface IFavoriteList {
  owner: string;
  title: string;
  favoriteElevatorsListItems: IFavoriteElevator[];
  createdAt: string;
  id: number;
  updatedAt: string;
}

export enum AddingToFavoriteListField {
  title = 'title',
}

export enum CreateFavoriteListField {
  title = 'title',
}

export enum CreateElevatorFormField {
  model = 'model',
  fabricatedAt = 'fabricatedAt',
  address = 'address',
  renovatedAt = 'renovatedAt',
  niNumber = 'niNumber',
  fabricationNumber = 'fabricationNumber',
  description = 'description',
  alarmPhoneNumber = 'alarmPhoneNumber',
  privateRoomAccess = 'privateRoomAccess',
  safetyInspectionCompany = 'safetyInspectionCompany',
  inspectionCompany = 'inspectionCompany',
  lineProvider = 'lineProvider',
  alarmCompany = 'alarmCompany',
  type = 'type',
  alarmType = 'alarmType',
  alarmLineType = 'alarmLineType',
  mazeMapUrl = 'mazeMapUrl',
  locationMap = 'locationMap',
  contactPersons = 'contactPersons',
  manufacturer = 'manufacturer',
}

export interface IElevatorsManufacturer {
  name: string;
  models: [
    {
      name: string;
      id: number;
    },
  ];
  id: number;
}

export interface IArchivedElevatorsListProps {
  elevators: IElevator[];
  elevatorsQuantity: number;
  fetchMore: () => void;
  height?: number;
  useWindowScroll?: boolean;
  goToElevatorPage: <E extends { id: number }>(elevator: E) => () => void;
  filterFreeSearchText?: string;
  unArchiveElevatorsModalData: IResultUseModal;
  invalidateQueryKeyForArchiving: QueryKey;
  hasNextPage?: boolean;
  isLoading?: boolean;
}

export interface IInfoFormCredentials {
  [CreateElevatorFormField.type]: SelectOption | string;
  [CreateElevatorFormField.model]: SelectOption | string;
  [CreateElevatorFormField.manufacturer]: SelectOption | string;
  [CreateElevatorFormField.niNumber]: string;
  [CreateElevatorFormField.fabricationNumber]: string;
  [CreateElevatorFormField.fabricatedAt]: string;
  [CreateElevatorFormField.renovatedAt]: string;
  [CreateElevatorFormField.description]: string;
}

export interface IControlFormCredentials {
  [CreateElevatorFormField.safetyInspectionCompany]: SelectOption | string;
  [CreateElevatorFormField.inspectionCompany]: SelectOption | string;
  [CreateElevatorFormField.lineProvider]: SelectOption | string;
  [CreateElevatorFormField.alarmType]: SelectOption | string;
  [CreateElevatorFormField.alarmCompany]: SelectOption | string;
  [CreateElevatorFormField.alarmLineType]: SelectOption | string;
  [CreateElevatorFormField.alarmPhoneNumber]: string;
}

export interface ILocationFormCredentials {
  [CreateElevatorFormField.address]: string;
  [CreateElevatorFormField.privateRoomAccess]: string;
  [CreateElevatorFormField.mazeMapUrl]: string;
  [CreateElevatorFormField.locationMap]: File[] | (string | undefined)[];
}

export interface IContactsFormCredentials {
  [CreateElevatorFormField.contactPersons]: IUser[];
}

export type GeneralElevatorFormType = IInfoFormCredentials &
  IControlFormCredentials &
  IContactsFormCredentials &
  ILocationFormCredentials;

export type DataType = { [key: string]: Partial<GeneralElevatorFormType> | null };
export type ErrorsType = { [key: string]: FieldErrors<GeneralElevatorFormType> | null };

export interface StepProps<T> {
  data: T;
  options?: IUseSelectOptionsForCreateEditElevatorResult;
  elevator?: IElevator;
  cancelLink: string;
}
