import { ControlledMenuProps } from '@szhsin/react-menu';
import { UiMenu } from '@ui-kit/menu/controlledMenu/Menu.styles';
import React, { useEffect } from 'react';

interface Props extends Omit<ControlledMenuProps, 'menuButton'> {
  anchorRef: React.RefObject<Element>;
  closeMenu: (e?: Event) => void;
  noPadding?: boolean;
}

export const Menu: React.FC<Props> = ({ children, closeMenu, anchorRef, noPadding = false, ...props }) => {
  const handleClickOutside = (event: Event) => {
    const { target } = event;
    const { current: wrap } = anchorRef;

    if (!wrap) return;
    // react-select removes target from the DOM, before an event is catched
    //@ts-ignore
    if (!document.contains(target)) return;
    //@ts-ignore
    if (wrap.contains(target)) return;

    closeMenu(event);
  };

  useEffect(() => {
    const mapEventType = [`click`, `touchstart`];
    mapEventType.map(type => document.addEventListener(type, handleClickOutside));
    return () => {
      mapEventType.map(type => document.removeEventListener(type, handleClickOutside));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UiMenu
      {...props}
      anchorRef={anchorRef}
      align={props.align || 'end'}
      position={props.position || 'anchor'}
      offsetY={props.offsetY || 3}
      noPadding={noPadding}
    >
      {children}
    </UiMenu>
  );
};
