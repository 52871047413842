import { ReactComponent as CloseIcon } from '@ui-kit/assets/icons/Close.svg';
import { IconButton, IconButtonBgColorType, IconButtonBorderColorType } from '@ui-kit/button';
import { SvgIcon, TColorSvgIcon } from '@ui-kit/icon';
import React from 'react';

interface CloseButtonProps {
  onClosePopup: () => void;
  iconColor?: TColorSvgIcon;
  borderColor?: IconButtonBorderColorType;
  bgColor?: IconButtonBgColorType;
  className?: string;
}

export const CloseButton: React.FC<CloseButtonProps> = ({ onClosePopup, iconColor, borderColor, bgColor }) => {
  return (
    <IconButton
      rounded
      onClick={onClosePopup}
      buttonSize={'33px'}
      borderColor={borderColor || 'secondary'}
      bgColor={bgColor}
      icon={<SvgIcon icon={CloseIcon} color={iconColor || 'secondary'} width={15} height={15} />}
    />
  );
};
