import { ImageSizesType } from '@type/image';
import { IUserAccountWithDomainRole } from '@type/user';
import { ReactComponent as CloseIcon } from '@ui-kit/assets/icons/CloseSlim.svg';
import { IconButton } from '@ui-kit/button';
import { Hidden } from '@ui-kit/hidden';
import { SvgIcon } from '@ui-kit/icon';
import { UserProfile } from '@ui-kit/navbar/Drawer/UserProfile';
import { Logo } from '@ui-kit/navbar/Logo';
import { Menu } from '@ui-kit/navbar/Menu';
import { MenuProps } from '@ui-kit/navbar/Navbar.types';
import { theme } from '@ui-kit/theme';
import React from 'react';

import { Content, Footer, Header, InnerCloseButton, LogoLink, Wrapper } from './Drawer.styles';

export interface DrawerProps extends Omit<MenuProps, 'activeKey' | 'setActiveMenuItem'> {
  userName: string;
  userEmail?: string;
  profileLink: string;
  avatar?: ImageSizesType;
  toggleMenu?: () => void;
  activeMenuItem: string | null | undefined;
  setActiveMenuItem: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  isAccountSwitcherRoute: boolean;
  availableAccounts?: IUserAccountWithDomainRole[];
  companyName?: string;
  homeRoute?: string;
}

export const Drawer: React.FC<DrawerProps> = ({
  userName,
  userEmail,
  avatar,
  headerItems,
  footerItems,
  toggleMenu,
  activeMenuItem,
  setActiveMenuItem,
  profileLink,
  isAccountSwitcherRoute,
  availableAccounts,
  companyName,
  homeRoute,
}) => {
  const onActiveMenuItem = (key: string | null, onClick?: () => void) => () => {
    setActiveMenuItem(key);
    if (toggleMenu) {
      toggleMenu();
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <Wrapper>
      <Header>
        <Hidden mq={theme.media.downWith.m}>
          <LogoLink to={homeRoute || ''}>
            <Logo />
          </LogoLink>
        </Hidden>
        <Hidden mq={theme.media.up.xxl}>
          <InnerCloseButton>
            <IconButton
              icon={<SvgIcon icon={CloseIcon} color={'tertiary'} width={'21px'} height={'21px'} />}
              borderColor={'tertiary'}
              buttonSize={'auto'}
              onClick={toggleMenu}
            />
          </InnerCloseButton>
        </Hidden>
      </Header>
      {!isAccountSwitcherRoute && (
        <>
          <Content>
            <Menu
              setActiveMenuItem={onActiveMenuItem}
              activeKey={activeMenuItem}
              headerItems={headerItems}
              footerItems={footerItems}
              toggleMenu={toggleMenu}
            />
          </Content>
          <Footer>
            <UserProfile
              userName={userName}
              userEmail={userEmail}
              companyName={companyName}
              profileLink={profileLink}
              avatar={avatar}
              availableAccounts={availableAccounts}
              toggleMenu={toggleMenu}
            />
          </Footer>
        </>
      )}
    </Wrapper>
  );
};
