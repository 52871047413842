import { ErrorMessage } from '@ui-kit/form/ErrorMessage';
import { Indicator } from '@ui-kit/form/Select/DropdownIndicator';
import { getStyles, InnerReactSelect } from '@ui-kit/form/Select/Select.styles';
import { pxToRem } from '@ui-kit/helpers';
import { Text } from '@ui-kit/typography';
import React from 'react';
import ReactSelect, { Props } from 'react-select';
import { useTheme } from 'styled-components';

export interface SelectProps extends Props {
  error?: string;
  isError?: boolean;
  isRequired?: boolean;
  className?: string;
  label?: string;
  labelID?: string;
  labelRef?: any;
  innerRef?: any;
}

export const Select = React.forwardRef<any, SelectProps>(
  ({ error, isError: propsIsError, styles, className, label, labelID, labelRef, isRequired, ...props }, innerRef) => {
    const { borderRadius, ...dataTheme } = useTheme();
    const isError = propsIsError || !!error;
    return (
      <InnerReactSelect className={className}>
        {Boolean(label) && (
          <label htmlFor={labelID} ref={labelRef}>
            <Text
              padding={'0 0 11px 0'}
              fontWeight={'bold'}
              fontSize={pxToRem(15)}
              fontVariant={'primary'}
              textColor={'primary'}
            >
              {label}
              {isRequired && '*'}
            </Text>
          </label>
        )}
        <ReactSelect
          {...props}
          ref={innerRef}
          inputId={labelID}
          className={'react-select'}
          components={{ DropdownIndicator: Indicator, ...props.components }}
          theme={selectTheme => ({ ...selectTheme, mainBorderRadius: borderRadius, ...dataTheme })}
          styles={{ ...getStyles(isError), ...styles }}
          closeMenuOnSelect
        />
        <ErrorMessage error={error} />
      </InnerReactSelect>
    );
  },
);
