import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { IntlProvider } from './IntlProvider';
import { LoadingProvider } from './LoadingProvider';
import { ReactQueryProvider } from './ReactQueryProvider';
import { SessionProvider } from './SessionProvider';
import { StyledThemeProvider } from './ThemeProvider';

export const Providers: FC = ({ children }) => {
  return (
    <BrowserRouter>
      <IntlProvider>
        <ReactQueryProvider>
          <StyledThemeProvider>
            <SessionProvider>
              <LoadingProvider>{children}</LoadingProvider>
            </SessionProvider>
          </StyledThemeProvider>
        </ReactQueryProvider>
      </IntlProvider>
    </BrowserRouter>
  );
};
