export enum Breakpoint {
  xxs = 320,
  xs = 375,
  s = 640,
  m = 768,
  l = 1024,
  xl = 1280,
  xxl = 1440,
  xxxl = 1920,
}

const down = (bp: number) => `@media screen and (max-width: ${bp - 0.02}px)`;
const downWith = (bp: number) => `@media screen and (max-width: ${bp}px)`;
const up = (bp: number) => `@media screen and (min-width: ${bp + 0.02}px)`;
const upWith = (bp: number) => `@media screen and (min-width: ${bp}px)`;

const getBreakpoints = (callBack: (bp: number) => string) => {
  return Object.keys(Breakpoint).reduce((acc, label) => {
    (acc as any)[label] = callBack((Breakpoint as any)[label]);
    return acc;
  }, {} as Record<keyof typeof Breakpoint, string>);
};

const Up = getBreakpoints(up);
const Down = getBreakpoints(down);
const DownWith = getBreakpoints(downWith);
const UpWith = getBreakpoints(upWith);

export const Media = {
  up: Up,
  down: Down,
  downWith: DownWith,
  upWith: UpWith,
};
