import { Link as ReactLink } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(ReactLink)`
  cursor: pointer;
  display: inline-block;
`;

export const ExternalLink = styled.a`
  text-decoration: none;
  display: inline-block;
`;
