import { ELEVATORS_TABS } from '@constants/elevator';
import { routes } from '@constants/routes';
import { USER_APPLICATION_ROLE } from '@constants/user';
import { SessionUserAccountId } from '@helpers/account';
import { UserApplicationRoleType } from '@type/user';

export const getTabTypesForRoute = <T extends {}>(types: T) => Object.values(types).join('|');

export const getHomeRoute = (applicationRole?: UserApplicationRoleType, avaliableAccountsCount?: number) => {
  switch (applicationRole) {
    case USER_APPLICATION_ROLE.account_owner:
    case USER_APPLICATION_ROLE.account_administrator:
    case USER_APPLICATION_ROLE.guest_user:
      return avaliableAccountsCount && avaliableAccountsCount > 1 && !SessionUserAccountId.has()
        ? routes.accountSwitcher.path
        : routes.dashboard.path;
    case USER_APPLICATION_ROLE.logbook_user:
      return routes.profile.path;
    default:
      return routes.home.get();
  }
};

export const getIsDashboardRoute = (applicationRole?: UserApplicationRoleType): boolean =>
  [
    USER_APPLICATION_ROLE.account_owner,
    USER_APPLICATION_ROLE.account_administrator,
    USER_APPLICATION_ROLE.guest_user,
  ].includes(applicationRole || '');
