import { $object, Override } from '@ui-kit/helpers';
import { SvgBaseRoot } from '@ui-kit/icon/SvgIcon.styles';
import React from 'react';

export type TColorSvgIcon = 'primary' | 'secondary' | 'tertiary' | 'error';

export interface SvgIconProps {
  color: TColorSvgIcon;
}

export type TColorProperty = 'fill' | 'stroke';

export type IconBaseProps<T extends $object> = {
  icon: keyof JSX.IntrinsicElements | React.ComponentType<T>;
  className?: string;
  typeColorProperty?: TColorProperty | TColorProperty[];
  color?: TColorSvgIcon;
} & Override<SvgIconProps, T>;

export const SvgIcon = <T extends $object>({
  className,
  icon,
  typeColorProperty = 'fill',
  ...props
}: IconBaseProps<T>) => {
  return <SvgBaseRoot {...props} className={className} as={icon as any} $typeColorProperty={typeColorProperty} />;
};
