import { pxToRem } from '@ui-kit/helpers';
import { ITextBaseProps, Text } from '@ui-kit/typography';
import React from 'react';
import styled from 'styled-components';

const Title = styled(Text)<ITextBaseProps>`
  font-size: ${pxToRem(46)};
  ${({ theme }) => theme.media.down.s} {
    font-size: ${pxToRem(36)};
  }
`;

export const PageTitle: React.FC<ITextBaseProps> = ({ children, ...props }) => {
  return (
    <Title {...props} textColor={'primary'} fontWeight={'bold'} fontVariant={'primary'}>
      {children}
    </Title>
  );
};
