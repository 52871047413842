import { Link } from '@components/common/Link';
import { PageTitle } from '@components/common/PageTitle';
import { ELEVATORS_TABS } from '@constants/elevator';
import { routes } from '@constants/routes';
import { AppTranslation } from '@domains/Intl/domains/App';
import { getHomeRoute } from '@helpers/routing';
import { getIsFullRights } from '@helpers/user';
import { useSession } from '@hooks/auth';
import { pxToRem } from '@ui-kit/helpers';
import { Text } from '@ui-kit/typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const PageNotFound = () => {
  const { currentUser } = useSession();
  const route = getHomeRoute(currentUser?.applicationRole, currentUser?.availableAccounts.length);

  return (
    <div>
      <PageTitle>
        <FormattedMessage id={AppTranslation['page-not-found.title']} />
      </PageTitle>
      <Text margin={'20px 0 0 0'} fontSize={pxToRem(18)}>
        <FormattedMessage
          id={AppTranslation['page-not-found.description']}
          values={{
            homePageLink: (
              <Link
                to={route}
                fontSize={pxToRem(18)}
                textColor={'tertiary'}
                translationKey={'page-not-found.home-page-link'}
              />
            ),
          }}
        />
      </Text>
    </div>
  );
};
