import { SIDEBAR_WIDTH, VIEWPORT_HEIGHT } from '@ui-kit/constants';
import { themeMode } from '@ui-kit/theme';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'styled-theming';

const background = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.whiteSmoke,
});

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${background};
  padding: 55px 23px 200px 43px;
  box-sizing: border-box;
  ${({ theme }) => theme.media.downWith.m} {
    padding: 25px 37px 43px 37px;
  }
  position: relative;
`;

export const DesktopContainer = styled.aside`
  width: var(${SIDEBAR_WIDTH});
  position: fixed;
  top: 0;
  bottom: 0;
  flex-shrink: 0;
  height: 100vh;
  padding-right: 20px;
  box-sizing: border-box;
  z-index: ${({ theme }) => theme.zIndex._2};
`;

export const Header = styled.div`
  position: relative;
  padding-bottom: 60px;
  box-sizing: border-box;
  display: flex;
  ${({ theme }) => theme.media.downWith.m} {
    padding-bottom: 10px;
  }
`;

export const Content = styled.div`
  flex-grow: 1;
  width: 100%;
  ${({ theme }) => theme.media.downWith.m} {
    display: flex;
    align-items: center;
  }
`;

const borderColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.whiteRose,
});

export const Footer = styled.div`
  margin-top: 30px;
  padding-top: 40px;
  box-sizing: border-box;
  border-top: 1px solid ${borderColor};
  display: flex;
  align-items: center;
  ${({ theme }) => theme.media.downWith.m} {
    margin-top: 10px;
  }
`;

export const UserContent = styled.div`
  flex-grow: 1;
  margin-left: 16px;
`;

export const mainColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen,
});

export const MobileDrawerContainer = styled.div`
  width: var(${SIDEBAR_WIDTH});
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  transform: translateX(100%);
  z-index: 999999;
  transition: all 0.5s ease-out;
  ${({ theme }) => theme.media.downWith.xs} {
    width: 100%;
  }

  &.active {
    transform: translateX(0);
  }
`;

export const InnerCloseButton = styled.div`
  flex-shrink: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  left: -10px;
  ${({ theme }) => theme.media.up.m} {
    position: absolute;
    top: -42px;
    left: -30px;
  }
`;

const overlayBackground = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.silverLight,
});

export const MobileOverlay = styled.div`
  background-color: ${overlayBackground};
  top: 0;
  left: 0;
  width: 100vw;
  height: var(${VIEWPORT_HEIGHT});
  position: fixed;
  z-index: 99998;
`;

export const LogoLink = styled(Link)`
  line-height: 0;
`;
