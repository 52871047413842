import { mainColor } from '@ui-kit/form/SearchInput/Common.styles';
import styled from 'styled-components';

export const Inner = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.s};
  padding: 8px 12px;
  box-sizing: border-box;
  background: ${mainColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  flex-shrink: 0;
`;
