import Cookies from 'js-cookie';

export class SessionToken {
  static accessKey = 'session-token';

  static has = () => {
    return Boolean(Cookies.get(SessionToken.accessKey)?.trim());
  };

  static get = (): string | null => {
    return Cookies.get(SessionToken.accessKey) || null;
  };

  static set = (token: string): void => {
    if (typeof token !== 'string') {
      console.error('Unexpected type of token');
      return;
    }

    Cookies.set(SessionToken.accessKey, token);
  };

  static reset = () => {
    Cookies.remove(SessionToken.accessKey);
  };
}
