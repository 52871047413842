import React from 'react';
import styled from 'styled-components';

interface IBox {
  mr?: string;
  ml?: string;
  mb?: string;
  mt?: string;
  width?: string;
  height?: string;
  maxWidth?: string;
  as?: keyof JSX.IntrinsicElements;
  className?: string;
}

const StyledBox = styled.div<IBox>`
  margin-right: ${({ mr }) => mr || 0};
  margin-left: ${({ ml }) => ml || 0};
  margin-top: ${({ mt }) => mt || 0};
  margin-bottom: ${({ mb }) => mb || 0};
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};
`;

export const Box: React.FC<IBox> = StyledBox;
