import { themeMode } from '@ui-kit/theme';
import styled from 'styled-components';
import theme from 'styled-theming';

const borderColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.whiteSmoke,
});

export const InnerButtons = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  padding: 33px 40px 30px 40px;
  box-sizing: border-box;
  flex-wrap: wrap;
  row-gap: 10px;
  border-top: 1px solid ${borderColor};
  ${({ theme }) => theme.media.down.xs} {
    & > * {
      width: 100%;
    }
  }
`;
