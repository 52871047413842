import { TableRow as UiTableRow } from '@ui-kit/table/common/Common.styles';
import { ContentType } from '@ui-kit/table/VirtuosoTable/VirtuosoTable.types';
import React from 'react';
import { Row } from 'react-table';
import { ItemProps } from 'react-virtuoso';

interface Props<D extends object = {}> extends ItemProps, ContentType {
  rows: Array<Row<D>>;
  onClickRow?: (original: D) => () => void;
}

export const TableRows: React.FC<Props> = ({ rows, onClickRow, ...props }) => {
  const row = rows[props['data-index']];
  const entity = row.original;

  const onEnter = (event: any) => {
    event.keyCode !== 13 || onClickRow?.(entity)();
  };

  return (
    <UiTableRow
      {...props}
      {...row.getRowProps()}
      {...(onClickRow ? { onClick: onClickRow(entity), tabIndex: 0 } : {})}
      onKeyDown={onEnter}
      isSelected={row.isSelected}
    />
  );
};
