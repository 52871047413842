import { mainColor } from '@ui-kit/form/SearchInput/Common.styles';
import { themeMode } from '@ui-kit/theme';
import styled from 'styled-components';
import theme from 'styled-theming';

const colorText = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.eerieBlack,
});

export const StyledInput = styled.input`
  width: 100%;
  border: none;
  padding: 8px 0;
  box-sizing: border-box;
  margin: 0;
  outline: 0;
  font-size: 1rem;
  color: ${colorText};
  ${({ theme }) => theme.font.variants.primary.medium};
  &::placeholder {
    ${({ theme }) => theme.font.variants.primary.medium};
    color: ${mainColor};
    font-size: 1rem;
  }
`;
