import { ReactComponent as Bars } from '@ui-kit/assets/icons/Bars.svg';
import { SvgIcon } from '@ui-kit/icon';
import { Logo } from '@ui-kit/navbar/Logo';
import { Container, LogoLink, MenuButton } from '@ui-kit/navbar/TopBar/TopBar.styles';
import React from 'react';

interface TopBarProps {
  toggleMenu: () => void;
  isAccountSwitcherRoute: boolean;
  homeRoute?: string;
}

export const TopBar: React.FC<TopBarProps> = ({ toggleMenu, isAccountSwitcherRoute, homeRoute }) => {
  return (
    <Container>
      <LogoLink to={homeRoute || ''}>
        <Logo variant={'secondary'} width={'41px'} height={'44px'} viewBox="0 0 67.539 68.296" />
      </LogoLink>
      {!isAccountSwitcherRoute && (
        <MenuButton onClick={toggleMenu}>
          <SvgIcon icon={Bars} color="primary" />
        </MenuButton>
      )}
    </Container>
  );
};
