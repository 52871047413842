import { ReactComponent as SearchIcon } from '@ui-kit/assets/icons/Search.svg';
import { SvgIcon } from '@ui-kit/icon';
import { theme } from '@ui-kit/theme';
import React from 'react';
import styled from 'styled-components';

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 24px 0 27px;
  ${theme.media.down.s} {
    padding: 0 15px 0 22px;
  }
  ${theme.media.down.xs} {
    padding: 0 10px 0 15px;
  }
`;

export const PrefixIcon = () => (
  <Inner>
    <SvgIcon icon={SearchIcon} typeColorProperty={'stroke'} color={'secondary'} width={16} height={16} />
  </Inner>
);
