import { themeMode } from '@ui-kit/theme';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';

const borderColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen,
});

const bgColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen,
});

const checkMarkColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.white,
});

const disabledInputColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.whiteSmoke,
});

const disabledCheckMarkColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.greyMiddle,
});

const dashColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.white,
});

export const InnerCheckbox = styled.label<{ indeterminate?: boolean }>`
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  padding: 0;
  position: relative;
  line-height: 1;
  outline: none;
  cursor: pointer;

  & input[type='checkbox'] {
    display: none;
  }

  & input:checked + .custom-checkbox {
    background-color: ${bgColor};

    &::after {
      border-color: ${checkMarkColor};
      opacity: 1;
    }
  }

  & input:disabled:checked + .custom-checkbox {
    background-color: ${disabledInputColor};
    &:after {
      border-color: ${disabledCheckMarkColor};
      opacity: 1;
    }
  }

  & input:disabled + .custom-checkbox {
    border-color: ${disabledCheckMarkColor};
  }
`;

const checkmarkStyles = css`
  left: 28%;
  top: 40%;
  display: table;
  border: 2px solid transparent;
  width: 5px;
  height: 11px;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
`;

const dashStyles = css`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 10px;
  height: 3px;
  background: ${dashColor};
`;

export const CustomCheckbox = styled.span.attrs({ className: 'custom-checkbox' })<{ indeterminate?: boolean }>`
  background-color: transparent;
  border-collapse: separate;
  border: 1px solid ${borderColor};
  display: block;
  height: 20px;
  left: 0;
  position: relative;
  top: 0;
  transition: all 0.3s;
  width: 20px;
  z-index: 0;

  /* Icon */
  &::after {
    position: absolute;
    ${({ indeterminate }) => (indeterminate ? dashStyles : checkmarkStyles)};
    opacity: 1;
    content: '';
  }
`;
