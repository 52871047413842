import { themeMode } from '@ui-kit/theme';
import React from 'react';
import styled, { StyledProps } from 'styled-components';
import theme from 'styled-theming';

const getBorderWidth = ({ borderWidth }: StyledProps<{ borderWidth: number }>) =>
  `0 ${borderWidth}px ${borderWidth}px 0`;

const colors = theme.variants('mode', 'color', {
  primary: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.white },
  secondary: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen },
});

type TArrow = 'up' | 'down' | 'left' | 'right';

interface IArrowProps {
  color: 'primary' | 'secondary';
  borderWidth: number;
  size: number;
  disabled?: boolean;
  type?: TArrow;
}

const mapRotation = {
  up: -135,
  down: 45,
  left: 135,
  right: -45,
};

export const StyledArrow = styled.div<IArrowProps>`
  border: solid ${colors};
  border-width: ${getBorderWidth};
  display: inline-block;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  opacity: ${({ disabled }) => (disabled ? '0.7' : '1')};
  transform: rotate(${({ type = 'down' }) => mapRotation[type]}deg);
`;

export const ChevronArrow: React.FC<IArrowProps> = StyledArrow;
