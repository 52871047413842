import { StyledIconButton } from '@ui-kit/button/IconButton/IconButton.styles';
import React, { HTMLAttributes } from 'react';

export type IconButtonBorderColorType = 'primary' | 'secondary' | 'tertiary' | 'error';
export type IconButtonBgColorType = 'primary' | 'transparent';

export interface TIconButtonProps extends HTMLAttributes<HTMLButtonElement> {
  buttonSize: string;
  buttonType?: 'submit' | 'button';
  borderColor: IconButtonBorderColorType;
  bgColor?: IconButtonBgColorType;
  disabled?: boolean;
  icon: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onTouchEnd?: (value: any) => void;
  rounded?: boolean;
}

export const IconButton = React.forwardRef<HTMLButtonElement, TIconButtonProps>(
  ({ icon, buttonType, bgColor = 'transparent', ...props }, ref) => {
    return (
      <StyledIconButton ref={ref} {...props} bgColor={bgColor} type={buttonType}>
        {icon}
      </StyledIconButton>
    );
  },
);
