import { BadgeContainer, BadgePosition, Badge as StyledBadge } from '@ui-kit/badge/Badge.styles';
import React from 'react';

export type BadgeProps = {
  className?: string;
  content?: React.ReactNode | null;
  position?: BadgePosition;
  size?: string;
};

export const Badge: React.FC<BadgeProps> = ({
  position = 'top-right',
  content = null,
  className,
  children,
  size,
  ...props
}) => {
  return (
    <BadgeContainer className={className}>
      <>
        {children}
        <StyledBadge {...props} position={position} size={size}>
          {content}
        </StyledBadge>
      </>
    </BadgeContainer>
  );
};
