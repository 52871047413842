import { pxToRem } from '@ui-kit/helpers';
import { themeMode } from '@ui-kit/theme';
import styled from 'styled-components';
import theme from 'styled-theming';
import { Swiper } from 'swiper/react';

const mainColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.white,
});

const bgColorNavButton = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen,
});

export const UISwiper = styled(Swiper)<{ height: number }>`
  height: ${({ height }) => height + 'px'};
  .swiper-slide {
    width: max-content;
    cursor: pointer;
  }
`;

export const MainSwiper = styled(Swiper)`
  box-sizing: border-box;
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: ${mainColor};
    transform: translateY(-50%);
    border-radius: ${({ theme }) => theme.borderRadius.circle};
    background: ${bgColorNavButton};
    width: 40px;
    height: 40px;
    font-weight: bold;
    &::after {
      font-size: ${pxToRem(17)};
    }
    ${({ theme }) => theme.media.down.xs} {
      display: none;
    }
  }
  .swiper-button-next {
    padding-left: 3px;
    box-sizing: border-box;
  }
  .swiper-button-prev {
    padding-right: 3px;
    box-sizing: border-box;
  }
`;
