import { RIGHT_PADDING_LAYOUT, SIDEBAR_WIDTH, TOPBAR_HEIGHT, VIEWPORT_HEIGHT } from '@ui-kit/constants';
import { themeMode } from '@ui-kit/theme';
import styled from 'styled-components';
import theme from 'styled-theming';

const containerBackground = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.whiteSmoke,
});

export const StyledLayout = styled.div`
  background: ${containerBackground};
  box-sizing: border-box;
  min-height: var(${VIEWPORT_HEIGHT});
  display: flex;
`;

export const Main = styled.main.attrs({ className: 'page-content' })`
  width: 100%;
  box-sizing: border-box;
  padding: 55px var(${RIGHT_PADDING_LAYOUT}) 55px var(${SIDEBAR_WIDTH});
  ${({ theme }) => theme.media.downWith.xxl} {
    margin-top: var(${TOPBAR_HEIGHT});
    padding: 20px var(${RIGHT_PADDING_LAYOUT}) 25px 30px;
    width: 100%;
  }
`;
