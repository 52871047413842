import React from 'react';
import { ToastContainer as ReactToastContainer } from 'react-toastify';
import { ToastContainerProps } from 'react-toastify/dist/types';
import styled, { DefaultTheme, StyledComponent } from 'styled-components';

const StyledToastContainer = styled(ReactToastContainer)`
  .Toastify__toast {
    border-radius: ${({ theme }) => theme.borderRadius.s};
    padding: 0;
    min-height: auto;
  }
  .Toastify__toast-container {
    padding: 0;
  }
  .Toastify__toast-body {
    padding: 0;
  }
`;

export const ToastContainer: StyledComponent<
  React.FC<ToastContainerProps>,
  DefaultTheme,
  {},
  never
> = StyledToastContainer;
