import { Dialog } from '@components/common/Dialog';
import { AppTranslation, TranslationKeyType } from '@domains/Intl/domains/App';
import { pxToRem } from '@ui-kit/helpers';
import { Modal } from '@ui-kit/modal';
import { Text } from '@ui-kit/typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Content } from './Content';

export interface ConfirmModalTranslationType {
  buttons: {
    cancel: TranslationKeyType;
    confirm: TranslationKeyType;
  };
  content: TranslationKeyType;
  header: TranslationKeyType;
}

export interface ConfirmModalTranslationValuesType {
  content: Record<string, any>;
  header: Record<string, any>;
}

export interface Props {
  translationKey: ConfirmModalTranslationType;
  translationValues?: Partial<ConfirmModalTranslationValuesType>;
  onConfirm: () => void;
  isOpenModal: boolean;
  closeModal: () => void;
  width?: string;
}

export const ConfirmModal: React.FC<Props> = ({
  translationKey,
  translationValues,
  isOpenModal,
  closeModal,
  onConfirm,
  width,
}) => {
  const { header, ...translationKeyRest } = translationKey;
  return (
    <Modal isOpen={isOpenModal} shouldCloseOnOverlayClick onRequestClose={closeModal} width={width}>
      <Dialog
        HeaderComponent={
          <Text textColor={'secondary'} fontWeight={'bold'} fontSize={pxToRem(23)} fontVariant={'primary'}>
            <FormattedMessage id={AppTranslation[translationKey.header]} values={translationValues?.header} />
          </Text>
        }
        ContentComponent={
          <Content
            translationKey={translationKeyRest}
            translationValues={translationValues}
            onConfirm={onConfirm}
            closeModal={closeModal}
            isOpenModal={isOpenModal}
          />
        }
        onClosePopup={closeModal}
      />
    </Modal>
  );
};
