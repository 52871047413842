const antiAliasing = {
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale',
};

const variants = {
  primary: {
    regular: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 400,
      ...antiAliasing,
    },
    medium: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 500,
      ...antiAliasing,
    },
    semiBold: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 600,
      ...antiAliasing,
    },
    bold: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 700,
      ...antiAliasing,
    },
  },
};

export const font = {
  rootSize: '16px',
  variants,
};
