import { themeMode } from '@ui-kit/theme';
import styled from 'styled-components';
import theme from 'styled-theming';

const borderColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen,
});

const textColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.cruelSeaGreen,
});

export const EditorWrapper = styled.div<{ $rows?: number }>`
  &,
  & .rt-wrapper {
    height: ${({ $rows }) => ($rows || 4) * 20}px;
    max-height: ${({ $rows }) => ($rows || 4) * 20}px;
    background-color: ${({ theme }) => theme.palette.commonColors.white};
    box-shadow: none;
    box-sizing: border-box;
    display: block;
    margin: 0;
    outline: none;
    width: 100%;
  }
  & .rt-wrapper {
    overflow: hidden;
    border-radius: ${({ theme }) => theme.borderRadius.s};
    border: 1px solid ${borderColor};
  }
  & .rt-editor {
    padding: 20px;
    box-sizing: border-box;
    font-size: 1rem;
    color: ${textColor};
    ${({ theme }) => theme.font.variants.primary.regular};

    & .public-DraftStyleDefault-block {
      margin: 0;
    }
    & .DraftEditor-root {
      height: auto;
    }
  }
`;
