import { ReactComponent as CheckCircle } from '@ui-kit/assets/icons/CheckCircle.svg';
import { ReactComponent as CloseInCircle } from '@ui-kit/assets/icons/CloseInCircle.svg';
import { ReactComponent as Info } from '@ui-kit/assets/icons/Info.svg';
import { ReactComponent as Warning } from '@ui-kit/assets/icons/Warning.svg';
import { pxToRem } from '@ui-kit/helpers';
import { SvgIcon } from '@ui-kit/icon';
import { Text } from '@ui-kit/typography';
import React from 'react';
import { toast as reactToast } from 'react-toastify';
import { ToastOptions } from 'react-toastify/dist/types';

import { CloseButton, Container, InnerContent, InnerIcon } from './ToastMessage.styles';

export enum MessageType {
  success = 'success',
  error = 'error',
  info = 'info',
}

const iconMap = {
  [MessageType.info]: Info,
  [MessageType.success]: CheckCircle,
  [MessageType.error]: Warning,
};

export interface MsgProps {
  type: keyof typeof MessageType;
  text: string;
  closeToast?: () => void;
}

const ToastMessage: React.FC<MsgProps> = ({ type = 'info', text, closeToast, ...props }) => {
  return (
    <Container type={type} {...props}>
      <InnerIcon>
        <SvgIcon icon={iconMap[type]} color={'primary'} width={'21px'} height={'21px'} />
      </InnerIcon>
      <InnerContent>
        <Text fontSize={pxToRem(15)} textColor={'secondary'} fontVariant={'primary'}>
          {text}
        </Text>
      </InnerContent>
      <CloseButton onClick={closeToast}>
        <SvgIcon icon={CloseInCircle} color={'primary'} />
      </CloseButton>
    </Container>
  );
};

export const toast = (msgProps: MsgProps, options?: ToastOptions) =>
  reactToast(<ToastMessage {...msgProps} />, {
    ...options,
    closeButton: false,
    style: { width: '100%', maxWidth: '540px' },
  });
