import { AppTranslation } from '@domains/Intl/domains/App';
import { pxToRem } from '@ui-kit/helpers';
import { LinkProps, Link as UiLink } from '@ui-kit/typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Link: React.FC<LinkProps & { translationKey?: keyof typeof AppTranslation }> = ({
  children,
  translationKey,
  ...props
}) => {
  return (
    <UiLink
      textAlign={'center'}
      textColor={'septenary'}
      fontSize={pxToRem(15)}
      fontWeight={'medium'}
      fontVariant={'primary'}
      decoration={'underline'}
      lineHeight={'130%'}
      {...props}
    >
      {children}
      {translationKey && <FormattedMessage id={AppTranslation[translationKey]} />}
    </UiLink>
  );
};
