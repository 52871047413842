import { ReactComponent as CalendarIcon } from '@ui-kit/assets/icons/Calendar.svg';
import { CleaveInputProps } from '@ui-kit/form';
import React from 'react';

import { IconButton } from './DateInput.styles';
import { Icon, StyledCleaveInput } from './DateInput.styles';

type DateInputProps = Omit<CleaveInputProps, 'options'> & {
  isOpenCalendar: boolean;
  handleOpenCalendar: (e: any) => void;
  setCustomInputRef?: (e: any) => void;
  setCleaveInputRef?: any;
};

export const DateInput = React.forwardRef<HTMLButtonElement, DateInputProps>(
  ({ handleOpenCalendar, isOpenCalendar, ...props }, ref) => {
    return (
      <StyledCleaveInput
        {...props}
        $isOpenCalendar={isOpenCalendar}
        options={{ date: true, delimiter: '.', datePattern: ['d', 'm', 'Y'] }}
        icon={
          <IconButton ref={ref} onClick={handleOpenCalendar} type={'button'}>
            <Icon typeColorProperty={'stroke'} fill={'none'} color={'secondary'} icon={CalendarIcon} />
          </IconButton>
        }
      />
    );
  },
);
