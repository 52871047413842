import { useEffect } from 'react';
import ReactGA from 'react-ga4';

import { appEnv } from '../../appEnv';

export const useGoogleAnalytics = () => {
  // Initialize Google Analytics
  useEffect(() => {
    if (!!appEnv.GA_CODE) {
      ReactGA.initialize(appEnv.GA_CODE);
    }
  }, []);
};
