import { Spinner } from '@ui-kit/Spinner';
import React, { useCallback, useState } from 'react';

import { ImageContainer, SpinnerContainer, UiImage } from './Image.styles';

type OverrideProps = 'placeholder' | 'onError';

export interface ImageProps extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, OverrideProps> {
  /**
   * Fallback renders when image is failed to load
   */
  fallback?: React.ReactNode;
  placeholder?: React.ReactNode;
  aspectRatio?: string;
  className?: string;
  objectFit?: 'contain' | 'cover';
  onError?: () => void;
  as?: React.FC;
  // Spinner
  spinner?: boolean;
  spinnerSize?: number;
  spinnerColor?: 'dark' | 'light';
}

enum ImageStatus {
  loading = 'loading',
  loaded = 'loaded',
  failed = 'failed',
}

const getKeyFromSource = (src?: string, srcSet?: string) => {
  return `${src ?? '-'}.${srcSet ?? '-'}`;
};

export const Image: React.FC<ImageProps> = ({
  alt,
  as: Component = UiImage,
  aspectRatio,
  className,
  fallback = null,
  objectFit = 'cover',
  onError,
  placeholder = null,
  src,
  srcSet,
  spinner = true,
  spinnerSize,
  spinnerColor = 'light',
  ...imageProps
}) => {
  const [status, setStatus] = useState(ImageStatus.loading);

  const setLoadingOnRef = useCallback(node => {
    if (node) setStatus(ImageStatus.loading);
  }, []);

  const handleError = () => {
    onError?.();
    setStatus(ImageStatus.failed);
  };

  const handleLoad = () => {
    setStatus(ImageStatus.loaded);
  };

  const isLoading = status === ImageStatus.loading;
  const isLoaded = status === ImageStatus.loaded;
  const isFailed = status === ImageStatus.failed;

  return (
    <ImageContainer className={className ? `${className} image-container` : 'image-container'}>
      <>
        {placeholder}
        {spinner && isLoading && (
          <SpinnerContainer>
            <Spinner size={spinnerSize} color={spinnerColor} />
          </SpinnerContainer>
        )}
        <Component
          {...imageProps}
          key={getKeyFromSource(src, srcSet)}
          alt={alt}
          ref={setLoadingOnRef}
          src={src}
          srcSet={srcSet}
          onLoad={handleLoad}
          onError={handleError}
          aspectRatio={aspectRatio}
          isLoaded={isLoaded}
          $objectFit={objectFit}
        />
        {isFailed && fallback}
      </>
    </ImageContainer>
  );
};
