import { apiKeys } from '@constants/apiKeys';
import { apiRoutes } from '@constants/apiRoutes';
import { LOADING_KEYS } from '@constants/loading';
import { routes } from '@constants/routes';
import { useLoadingContext } from '@domains/AppRoot/Providers/LoadingProvider';
import { AppTranslation } from '@domains/Intl/domains/App';
import { showErrorMessage } from '@helpers/error';
import axiosApi from '@lib/axiosApi';
import { ChangePasswordFormFields } from '@type/auth';
import { toast } from '@ui-kit/message';
import React from 'react';
import { Control, DeepMap, FieldError, SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

interface OptionsType {
  [ChangePasswordFormFields.password]: string;
  [ChangePasswordFormFields.passwordConfirmation]: string;
}

interface BodyRequestType {
  plainPassword: string;
  token: string;
}

export interface IUseChangePasswordReturnType {
  control: Control<any>;
  onChangePassword: (e: React.SyntheticEvent) => Promise<void>;
  formErrors: DeepMap<Record<string, any>, FieldError> | null;
  watchPassword: string;
}

interface IUseChangePasswordResponse {
  isExternal: boolean;
}

export const useChangePassword = (): IUseChangePasswordReturnType => {
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const token = query.get('token');

  const { setLoading, removeLoading } = useLoadingContext();
  const { formatMessage } = useIntl();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors: formErrors },
  } = useForm({
    defaultValues: {
      [ChangePasswordFormFields.password]: '',
      [ChangePasswordFormFields.passwordConfirmation]: '',
    },
  });
  const watchPassword = watch(ChangePasswordFormFields.password);
  const { mutate } = useMutation(
    apiKeys.changePassword,
    (data: BodyRequestType): Promise<IUseChangePasswordResponse> => {
      setLoading(LOADING_KEYS.changePassword);
      return axiosApi.post(apiRoutes.changePassword, data)?.then(res => res.data);
    },
    {
      onSuccess: async ({ isExternal }) => {
        reset();
        if (isExternal) {
          history.push(routes.successfulExternalUserPasswordChange.path);
        } else {
          toast({ text: formatMessage({ id: AppTranslation['change-password.request.success'] }), type: 'success' });
          history.push(routes.login.path);
        }
      },
      onError: showErrorMessage(),
      onSettled: removeLoading(LOADING_KEYS.changePassword),
    },
  );

  const onSubmit: SubmitHandler<OptionsType> = credentials => {
    if (!token) {
      return toast({
        text: formatMessage({
          id: AppTranslation['change-password.token.err-msg.invalid-token'],
        }),
        type: 'error',
      });
    }
    const { plainPassword } = credentials;
    mutate({ plainPassword, token });
  };

  return { control, onChangePassword: handleSubmit(onSubmit), formErrors, watchPassword };
};
