import { CreateElevatorFormField, ElevatorField } from '@type/elevator';

export const ELEVATORS_TABS = {
  all: 'all',
  favorites: 'favorites',
  archived: 'archived',
};

export const ELEVATOR_TABS = {
  info: 'info',
  logbook: 'logbook',
};

export const elevatorsSortFieldMapping = {
  [ElevatorField.type]: 'order[type.name]',
  [ElevatorField.niNumber]: 'order[niNumber]',
  [ElevatorField.lastLogEntry]: 'order[lastLogEntry.createdAt]',
  [ElevatorField.description]: 'order[description]',
  [ElevatorField.address]: 'order[address]',
  [ElevatorField.account]: 'order[account.name]',
};

export enum ElevatorFilterField {
  type = 'type',
  niNumber = 'niNumber',
  fabricationNumber = 'fabricationNumber',
  model = 'model',
  manufacturer = 'manufacturer',
  inspectionCompany = 'inspectionCompany',
  account = 'account',
  search = 'search',
}

export const elevatorsFilterFieldMapping: Record<string, string> = {
  [ElevatorFilterField.type]: 'type',
  [ElevatorFilterField.manufacturer]: 'manufacturer',
  [ElevatorFilterField.model]: 'model',
  [ElevatorFilterField.niNumber]: 'niNumber',
  [ElevatorFilterField.fabricationNumber]: 'fabricationNumber',
  [ElevatorFilterField.account]: 'account.id',
  [ElevatorFilterField.search]: 'search',
  [ElevatorFilterField.inspectionCompany]: 'inspectionCompany',
};

export type ElevatorFormTabsType = keyof typeof ELEVATOR_FORM_TABS;

export enum ELEVATOR_FORM_TABS {
  info = 'info',
  contacts = 'contacts',
  control = 'control',
  location = 'location',
  summary = 'summary',
}

export const errorsMap: { [key: string]: string[] } = {
  [ELEVATOR_FORM_TABS.info]: [
    CreateElevatorFormField.model,
    CreateElevatorFormField.fabricatedAt,
    CreateElevatorFormField.renovatedAt,
    CreateElevatorFormField.niNumber,
    CreateElevatorFormField.fabricationNumber,
    CreateElevatorFormField.description,
    CreateElevatorFormField.manufacturer,
    CreateElevatorFormField.type,
  ],
  [ELEVATOR_FORM_TABS.contacts]: [CreateElevatorFormField.contactPersons],
  [ELEVATOR_FORM_TABS.control]: [
    CreateElevatorFormField.safetyInspectionCompany,
    CreateElevatorFormField.inspectionCompany,
    CreateElevatorFormField.lineProvider,
    CreateElevatorFormField.alarmCompany,
    CreateElevatorFormField.alarmPhoneNumber,
    CreateElevatorFormField.alarmType,
    CreateElevatorFormField.alarmLineType,
  ],
  [ELEVATOR_FORM_TABS.location]: [
    CreateElevatorFormField.address,
    CreateElevatorFormField.mazeMapUrl,
    CreateElevatorFormField.locationMap,
    CreateElevatorFormField.privateRoomAccess,
  ],
};

export const DEVIATION_LOGBOOK_SUBCATEGORIES_NAMES = [
  'Periodisk sikkerhetskontroll',
  'Ettersyn/Vedlikeholdskontrakt',
  'Stikkprøvekontroll',
  'Tilstandskontroll',
];
