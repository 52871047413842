import { FileInfo } from '@type/file';
import { isServer } from '@ui-kit/helpers';

import { isFile, isFileInfo, isString } from './validation';

export const checkImgSrc = (src?: any) => {
  let isCheck = false;
  if (typeof src !== 'string') {
    isCheck = false;
  }
  const img = new Image();
  img.onload = () => {
    isCheck = true;
  };
  img.onerror = () => {
    isCheck = false;
  };
  img.src = src;
  return isCheck;
};

export const getSourceUrl = (value?: File | FileInfo | string | null): string | undefined => {
  if (!value) return;

  if (isString(value)) {
    return checkImgSrc(value) ? value : '';
  }
  if (isFileInfo(value)) return value.url;

  if (isFile(value) && !isServer()) {
    return (window.URL || window.webkitURL)?.createObjectURL(value);
  }
};

type ImageSrcSetParamsType = {
  src?: { [key in '1x' | '2x' | '3x']: string } | null | string;
};

export const getImageSrcSet = ({ src }: ImageSrcSetParamsType) => {
  return src && typeof src !== 'string' ? `${src['1x']} 1x, ${src['2x']} 2x, ${src['3x']} 3x` : '';
};
