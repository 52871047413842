import { Container, Inner } from '@ui-kit/form/SearchInput/Control.styles';
import { Dropdown } from '@ui-kit/form/SearchInput/Dropdown';
import { DropdownIndicator } from '@ui-kit/form/SearchInput/DropdownIndicator';
import { MultiValueContainer } from '@ui-kit/form/SearchInput/MultiValueContainer';
import { PrefixIcon } from '@ui-kit/form/SearchInput/PrefixIcon';
import { ISearchInputProps, TypeMenuProps } from '@ui-kit/form/SearchInput/SearchInput.types';
import React from 'react';

interface Props<Data> extends ISearchInputProps<Data> {
  onDeleteValue: (key: number | string, item: unknown) => () => void;
  wrapperRef: React.RefObject<HTMLDivElement>;
  handleToggleMenu: (open?: boolean) => void;
  setInputValueRef: (el: HTMLInputElement | null) => void;
  onFocusInputValue: () => void;
  menuProps: TypeMenuProps;
  buttonRef: any;
  setButtonRef: any;
  isFocused: boolean;
  setIsFocused: any;
  isDropdown?: boolean;
}

export const Control = <Data extends object>({
  values,
  onFilter,
  labelField,
  valueField,
  onDeleteValue,
  inputValue,
  onInputChange,
  placeholder,
  handleToggleMenu,
  menuProps,
  dropdownContent,
  setInputValueRef,
  wrapperRef,
  onFocusInputValue,
  buttonRef,
  setButtonRef,
  isFocused,
  setIsFocused,
  isDropdown = true,
}: Props<Data>) => {
  const onKeyDown = (e: any) => {
    if (e.key !== 'Escape') return;
    buttonRef.focus();
  };

  return (
    <Container ref={wrapperRef} onKeyDown={onKeyDown} isFocused={isFocused}>
      <Inner onClick={onFocusInputValue}>
        <PrefixIcon />
        <MultiValueContainer
          values={values}
          onFilter={onFilter}
          labelField={labelField}
          valueField={valueField}
          onDeleteValue={onDeleteValue}
          inputValue={inputValue}
          onInputChange={onInputChange}
          setInputValueRef={setInputValueRef}
          onFocusInputValue={onFocusInputValue}
          placeholder={placeholder}
          setIsFocused={setIsFocused}
        />
        {isDropdown && <DropdownIndicator setButtonRef={setButtonRef} handleToggleMenu={handleToggleMenu} />}
      </Inner>

      {dropdownContent && (
        <Dropdown
          buttonRef={buttonRef}
          menuProps={menuProps}
          handleToggleMenu={handleToggleMenu}
          wrapperRef={wrapperRef}
          dropdownContent={dropdownContent}
        />
      )}
    </Container>
  );
};
