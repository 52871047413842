import { MultiValue } from '@ui-kit/form/SearchInput/MultiValue';
import { Placeholder } from '@ui-kit/form/SearchInput/Placeholder';
import { ValueInput } from '@ui-kit/form/SearchInput/ValueInput';
import { Form } from '@ui-kit/form/SearchInput/ValueInput.styles';
import React, { ChangeEvent, SyntheticEvent, useEffect } from 'react';

import { Container } from './MultivalueContainer.styles';

interface Props<Data extends Record<string, any>> {
  values: readonly Data[];
  onFilter: () => void;
  labelField?: string;
  valueField?: string;
  placeholder?: string;
  onDeleteValue: (key: number | string, item: unknown) => () => void;
  inputValue: string;
  onInputChange: (value: string, e: ChangeEvent<HTMLInputElement>) => void;
  setInputValueRef: (el: HTMLInputElement | null) => void;
  onFocusInputValue: () => void;
  setIsFocused: any;
}

export const MultiValueContainer = <TOption extends object>({
  values,
  onFilter,
  labelField = 'label',
  valueField = 'value',
  onDeleteValue,
  inputValue,
  onInputChange,
  setInputValueRef,
  onFocusInputValue,
  placeholder,
  setIsFocused,
}: Props<TOption>) => {
  useEffect(() => {
    if (inputValue) return;
    onFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    onFilter();
  };
  const isMultiValues = !!values?.length;

  return (
    <Container>
      {values?.map(item => {
        const key = (item as any)[valueField];
        return <MultiValue key={key} text={(item as any)[labelField]} onDeleteValue={onDeleteValue(key, item)} />;
      })}
      <Form onSubmit={onSubmit} role={'search'} isMultiValues={isMultiValues}>
        {!!values?.length ? (
          <ValueInput
            inputValue={inputValue}
            onInputChange={onInputChange}
            setInputValueRef={setInputValueRef}
            onFocusInputValue={onFocusInputValue}
            setIsFocused={setIsFocused}
          />
        ) : (
          <Placeholder
            setIsFocused={setIsFocused}
            placeholder={placeholder || ''}
            inputValue={inputValue || ''}
            onInputChange={onInputChange}
          />
        )}
      </Form>
    </Container>
  );
};
