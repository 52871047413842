import { InputProps } from '@ui-kit/form/';
import { pxToRem } from '@ui-kit/helpers';
import { themeMode } from '@ui-kit/theme';
import ReactDatePicker from 'react-datepicker';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';

const mainColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen,
});

const activeDayColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.white,
});

const dayColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.cruelSeaGreen,
});

const mainFontSize = pxToRem(15);

const styles = css`
  font-size: ${mainFontSize};
  line-height: 100%;
  background-color: transparent;
  height: 100%;
  width: 100%;
  -webkit-appearance: none;
  flex-grow: 1;
  position: relative;
  padding: 12px;
`;

const stylesActiveDay = css<InputProps>`
  background: ${mainColor};
  color: ${activeDayColor};
`;

export const DatePicker = styled(ReactDatePicker)<InputProps>`
  ${styles};
`;

export const InnerDataPiker = styled.div<{ inputWidth?: string; dropdownWidth?: string }>`
  & .react-datepicker {
    border-radius: ${({ theme }) => theme.borderRadius.s};
    box-sizing: border-box;
    border: 1px solid ${mainColor};
    font-size: 1rem;
    width: ${({ dropdownWidth }) => dropdownWidth || '310px'};
  }
  & .react-datepicker__month-container {
    width: ${({ dropdownWidth }) => dropdownWidth || '310px'};
  }
  & .react-datepicker-wrapper {
    width: ${({ inputWidth }) => inputWidth || '150px'};
  }
  & .react-datepicker__navigation {
    box-sizing: border-box;
    top: 17px;

    & .react-datepicker__navigation-icon {
      font-size: ${mainFontSize};
    }

    & .react-datepicker__navigation-icon::before {
      border-color: ${mainColor};
    }
  }

  & .react-datepicker__navigation--previous {
    left: 23px;
  }
  & .react-datepicker__navigation--next {
    right: 23px;
  }
  & .react-datepicker__header {
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    background-color: transparent;
    border: none;
    padding: 27px 22px 8px 22px;

    & .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
      margin-top: 10px;
      margin-bottom: 12px;

      & .react-datepicker__year-select {
        box-sizing: border-box;
        margin-left: 10px;
        padding: 5px 15px 5px 10px;
      }
    }
  }

  & .react-datepicker__day-names {
    & .react-datepicker__day-name {
      box-sizing: border-box;
      color: ${mainColor};
      cursor: default;
      ${({ theme }) => theme.font.variants.primary.bold};
      font-size: ${mainFontSize};
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }

  & .react-datepicker__month {
    padding: 3px 2px 2px;
  }

  & .react-datepicker__current-month {
    cursor: default;
    margin-bottom: 15px;
    ${({ theme }) => theme.font.variants.primary.bold};
    font-size: ${pxToRem(18)};
    &:first-letter {
      text-transform: uppercase;
    }
  }
  & .react-datepicker-popper[data-placement^='bottom'] {
    padding-top: 0;
  }

  & .react-datepicker__month {
    margin: 0;
    box-sizing: border-box;
    border: none;
    border-radius: 5px;
    padding: 0 7px 12px 7px;
  }
  & .react-datepicker__day-name,
  & .react-datepicker__day,
  & .react-datepicker__time-name {
    margin: 1px;
    width: 2.2rem;
    padding: 5px;
    box-sizing: border-box;
  }

  & .react-datepicker__day {
    box-sizing: border-box;
    transition: background-color 0.3s ease, color 0.3s ease;
    color: ${dayColor};
    padding: 5px 7px;
    ${({ theme }) => theme.font.variants.primary.bold};
    font-size: ${mainFontSize};
    line-height: 113%;
    &:hover {
      ${stylesActiveDay};
    }

    &:focus {
      ${stylesActiveDay};
    }

    &--keyboard-selected,
    &--selected {
      ${stylesActiveDay};
    }

    &--outside-month,
    &--disabled {
      background: transparent;
      color: ${dayColor};
      opacity: 0.5;
      &:hover {
        background: transparent;
        color: ${dayColor};
        opacity: 0.5;
      }
    }
  }
`;

export const InnerClearButton = styled.div`
  padding: 12px 25px 23px 25px;
  box-sizing: border-box;
`;
