import React from 'react';
import styled from 'styled-components';

const isHidden = (mq: string) => {
  return !window.matchMedia(mq).matches;
};

interface HiddenProps {
  mq: string;
  implementation?: 'css' | 'js';
}

export const HiddenCss = styled.div<HiddenProps>`
  ${({ mq }) => mq} {
    clip-path: inset(50%);
    clip: rect(0 0 0 0);
    display: none;
    height: 1px;
    left: -999999px;
    overflow: hidden;
    position: absolute;
    top: -999999px;
    white-space: nowrap;
    width: 1px;
  }
`;

export const HiddenJs: React.FC<HiddenProps> = ({ children, mq }) => {
  const media = mq.replace('@media ', '');
  const [active, setActive] = React.useState(() => isHidden(media));

  React.useEffect(() => {
    const handleResize = () => {
      setActive(isHidden(media));
    };

    window.addEventListener('resize', handleResize, false);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [media]);

  if (!active || !children) return null;

  return children as React.ReactElement;
};

export const Hidden: React.FC<HiddenProps> = ({ children, mq, implementation = 'js' }) => {
  const Component = implementation === 'css' ? HiddenCss : HiddenJs;

  return <Component mq={mq}>{children}</Component>;
};
