import { StyledTagProps } from '@ui-kit/tag/Tag';
import { themeMode } from '@ui-kit/theme';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';

const commonColor = theme.variants('mode', 'color', {
  primary: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen },
  secondary: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.cruelSeaGreen },
  tertiary: { [themeMode.light]: 'transparent' },
});

const styles = css<StyledTagProps>`
  display: inline-flex;
  background: ${props => commonColor({ ...props, color: props.bgColor })};
  border-radius: ${({ borderRadius = 'm', theme }) => theme.borderRadius[borderRadius]};
  padding: ${({ padding }) => padding || '7px 20px'};
  box-sizing: border-box;
  border: ${props => (props.borderColor ? `1px solid ${commonColor({ ...props, color: props.borderColor })}` : 'none')};
`;

export const StyledLink = styled(Link)<StyledTagProps>``;

export const Container = styled.span<StyledTagProps>`
  ${styles};
`;

export const LinkContainer = styled.a<StyledTagProps>`
  ${styles};
  text-decoration: none;
`;
