import { AvatarContainer, AvatarImage, TAvatarSize } from '@ui-kit/avatar/Avatar.styles';
import { Image, ImageProps } from '@ui-kit/image';
import React from 'react';

import { DefaultPlaceholder, DefaultPlaceholderProps } from './DefaultPlaceholder';

export interface AvatarProps extends ImageProps {
  size?: TAvatarSize;
  placeholderProps?: DefaultPlaceholderProps;
}

const Avatar: React.FC<AvatarProps> = ({ className, size = 's', placeholder, placeholderProps, ...props }) => {
  return (
    <AvatarContainer size={size}>
      <Image
        {...props}
        as={AvatarImage}
        aspectRatio="1 / 1"
        placeholder={placeholder || <DefaultPlaceholder avatarSize={size} {...placeholderProps} />}
        spinnerSize={size === 's' ? 20 : 30}
      />
    </AvatarContainer>
  );
};

export default Avatar;
