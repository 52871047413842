import { AppTranslation } from '@domains/Intl/domains/App';
import { SessionUserAccountId } from '@helpers/account';
import { SessionToken } from '@helpers/auth';
import { useSession } from '@hooks/auth';
import { ImageSizesType } from '@type/image';
import { IUserAccountWithDomainRole } from '@type/user';
import { LabelUser } from '@ui-kit/labelUser';
import { Menu as ControlledMenu } from '@ui-kit/menu';
import { ProfileMenuContent } from '@ui-kit/navbar/Drawer/UserProfile/ProfileMenuContent';
import { LabelUserWrapper, MenuWrapper } from '@ui-kit/navbar/Drawer/UserProfile/UserProfile.styles';
import { Breakpoint } from '@ui-kit/theme/breakpoints';
import React, { useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';

export interface UserProfileProps {
  userName: string;
  userEmail?: string;
  profileLink: string;
  avatar?: ImageSizesType;
  availableAccounts?: IUserAccountWithDomainRole[];
  toggleMenu?: () => void;
  companyName?: string;
}

export const UserProfile: React.FC<UserProfileProps> = ({
  userName,
  userEmail,
  avatar,
  profileLink,
  availableAccounts,
  toggleMenu,
  companyName,
}) => {
  const { formatMessage } = useIntl();
  const avatarLabel = formatMessage({ id: AppTranslation['aria-label.user-image'] });
  const ref = useRef<HTMLDivElement | null>(null);
  const [isOpenMenu, setOpenMenu] = useState(false);
  const queryClient = useQueryClient();
  const currentUserAccountId = SessionUserAccountId.get();
  const { clientWidth } = document.documentElement;
  const isMobile = clientWidth <= Breakpoint.s;
  const { createSession } = useSession();

  const toggleSettingsMenu = () => {
    setOpenMenu(prev => !prev);
  };

  const closeSettingsMenu = () => {
    setOpenMenu(false);
  };

  const selectUserAccount = (id: number) => {
    SessionUserAccountId.set(id);
    createSession({ token: SessionToken.get()! });
  };

  const activeUserAccount = useMemo(() => {
    const activeAccount = availableAccounts?.find(item => item.account.id === Number(currentUserAccountId));
    return activeAccount || availableAccounts?.[0];
  }, [availableAccounts, currentUserAccountId]);

  const switchAccounts = useMemo(() => {
    const accounts = availableAccounts?.filter(item => item.account.id !== Number(currentUserAccountId));
    return availableAccounts && availableAccounts?.length > 1 ? accounts : undefined;
  }, [availableAccounts, currentUserAccountId]);

  return (
    <MenuWrapper ref={ref}>
      <LabelUserWrapper onClick={toggleSettingsMenu}>
        <LabelUser
          avatar={avatar}
          company={{ name: activeUserAccount?.account.name || companyName }}
          userName={userName}
          avatarLabel={avatarLabel}
          contextMenu={true}
        />
      </LabelUserWrapper>
      <ControlledMenu
        state={isOpenMenu ? 'open' : 'closed'}
        align={'center'}
        direction={isMobile ? 'top' : 'right'}
        closeMenu={closeSettingsMenu}
        anchorRef={ref}
        offsetY={-2}
        offsetX={-30}
        noPadding={true}
      >
        <ProfileMenuContent
          userName={userName}
          userEmail={userEmail}
          profileLink={profileLink}
          activeUserAccount={activeUserAccount}
          accounts={switchAccounts}
          selectUserAccount={selectUserAccount}
          closeSettingsMenu={closeSettingsMenu}
          toggleNavMenu={toggleMenu}
          companyName={companyName}
        />
      </ControlledMenu>
    </MenuWrapper>
  );
};
