import { Box } from '@ui-kit/box';
import { ButtonBase, ButtonProps, UiButton, UiLinkButton } from '@ui-kit/button/Button';
import { Text, TextColorType } from '@ui-kit/typography/Text';
import React from 'react';

const Content: React.FC<{
  color?: TextColorType;
  rightIconPadding?: string;
  icon?: React.ReactNode;
}> = ({ children, color, icon, rightIconPadding = '12px' }) => {
  const hasIcon = Boolean(icon);
  return (
    <>
      <Box as={'span'} mr={hasIcon ? rightIconPadding : '0'}>
        <Text fontWeight={'bold'} fontSize={'1rem'} textColor={color} cursor={'pointer'}>
          {children}
        </Text>
      </Box>
      {hasIcon && icon}
    </>
  );
};

export const Button: React.FC<ButtonProps> = ({
  children,
  background = 'primary',
  buttonType = 'button',
  elementType = 'button',
  textColor = 'secondary',
  rightIconPadding,
  icon,
  to,
  ...props
}) => {
  if (elementType === 'link' || to) {
    return (
      <ButtonBase element={UiLinkButton} {...props} background={background} to={to}>
        <Content icon={icon} color={textColor} rightIconPadding={rightIconPadding}>
          {children}
        </Content>
      </ButtonBase>
    );
  }
  return (
    <ButtonBase element={UiButton} {...props} background={background} type={buttonType}>
      <Content icon={icon} color={textColor} rightIconPadding={rightIconPadding}>
        {children}
      </Content>
    </ButtonBase>
  );
};
