import { RIGHT_PADDING_LAYOUT } from '@ui-kit/constants';
import { outlineStyles } from '@ui-kit/globalStyles';
import { themeMode } from '@ui-kit/theme';
import 'rc-tabs/assets/index.css';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';

const btnTextColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.cruelSeaGreen,
});

const activeColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen,
});

const disabledColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.greyMiddle,
});

const stylesBtn = css`
  font-size: 1rem;
  ${({ theme }) => theme.font.variants.primary.bold};
  color: ${btnTextColor};
  padding: 2px 0;
`;

export const StyledTabs = styled.div<{ hasCommonContent?: boolean; tabsGutter?: string }>`
  .rc-tabs {
    border: none;
    overflow: inherit;
    .rc-tabs-nav {
      position: relative;
      width: calc(100% + var(${RIGHT_PADDING_LAYOUT}));
    }
    .rc-tabs-content {
      flex-direction: column;
    }
    & .rc-tabs-ink-bar {
      background-color: ${activeColor};
      height: 2px;
    }

    .rc-tabs-tab {
      background: transparent;
      ${outlineStyles};

      ${({ tabsGutter }) => tabsGutter && `margin-left: ${tabsGutter}`};
      &:first-child {
        margin-left: 0;
      }
      &:nth-last-child(-n + 2) {
        margin-right: var(${RIGHT_PADDING_LAYOUT});
      }
      &:nth-child(2) {
        ${({ hasCommonContent }) => hasCommonContent && `margin-left: 0`};
      }
      &.rc-tabs-tab-disabled {
        .rc-tabs-tab-btn {
          color: ${disabledColor};
        }
      }
      &.rc-tabs-tab-active {
        .rc-tabs-tab-btn {
          ${outlineStyles};
          color: ${activeColor};
          & > * {
            color: ${activeColor};
          }
        }
      }
      .rc-tabs-tab-btn {
        ${stylesBtn};
        & > * {
          ${stylesBtn};
        }
      }
    }
    & .rc-tabs-tabpane-active {
      ${outlineStyles};
    }
    .rc-tabs-nav-operations {
      display: none;
    }
    .rc-tabs-nav-measure-ping-left::before,
    .rc-tabs-nav-wrap-ping-left::before {
      border: none;
    }
    .rc-tabs-nav-measure-ping-right::after,
    .rc-tabs-nav-wrap-ping-right::after {
      border: none;
    }
  }
`;
