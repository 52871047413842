import { useCallback, useState } from 'react';

export interface IResultUseModal {
  isOpenModal: boolean;
  openModal: () => void;
  closeModal: () => void;
  toggleModal: () => void;
}

type TypeModalHook = (initialState?: boolean) => IResultUseModal;

export const useModal: TypeModalHook = (initialState = false) => {
  const [isOpenModal, setIsOpenModal] = useState(initialState);

  const openModal = useCallback(() => {
    setIsOpenModal(true);
  }, [setIsOpenModal]);

  const closeModal = useCallback(() => {
    setIsOpenModal(false);
  }, [setIsOpenModal]);

  const toggleModal = useCallback(() => {
    setIsOpenModal(prev => !prev);
  }, [setIsOpenModal]);

  return { isOpenModal, openModal, closeModal, toggleModal };
};
