import { ExternalLink, StyledLink } from '@ui-kit/typography/Link/Link.styles';
import { ITextBaseProps, Text } from '@ui-kit/typography/Text/Text';
import React from 'react';

export interface LinkProps extends ITextBaseProps {
  to?: string;
  href?: string;
  'data-testid'?: string;
}

export const Link: React.FC<LinkProps> = ({ children, to, href, 'data-testid': dataTestId, ...props }) => {
  if (href) {
    return (
      <ExternalLink href={href} data-testid={dataTestId}>
        <Text {...props}>{children}</Text>
      </ExternalLink>
    );
  }

  if (to) {
    return (
      <StyledLink to={to} data-testid={dataTestId}>
        <Text {...props}>{children}</Text>
      </StyledLink>
    );
  }
  return null;
};
