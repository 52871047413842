import AppRoot from '@domains/AppRoot/AppRoot';
import { Providers } from '@domains/AppRoot/Providers';
import 'normalize.css';
import React from 'react';
import { render } from 'react-dom';

render(
  <Providers>
    <AppRoot />
  </Providers>,
  document.getElementById('root'),
);
