import { mainColor } from '@ui-kit/form/SearchInput/Common.styles';
import { theme as uiTheme } from '@ui-kit/theme';
import styled from 'styled-components';

export const Inner = styled.div`
  margin: 7px;
  padding: 0 30px;
  box-sizing: border-box;
  border-left: 1px solid ${mainColor};
  z-index: 50;
  ${uiTheme.media.down.s} {
    padding: 0 22px;
  }
  ${uiTheme.media.down.xs} {
    padding: 0 10px;
  }
`;

export const Button = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
