import ReactTooltip from 'react-tooltip';
import styled, { css } from 'styled-components';

const holdingTooltipStyles = css`
  pointer-events: auto !important;
  opacity: 1 !important;
  &:hover {
    visibility: visible;
  }
`;
export const TooltipComponent = styled(ReactTooltip)<{ isHoldingTooltip?: boolean }>`
  ${({ isHoldingTooltip }) => isHoldingTooltip && holdingTooltipStyles}
`;
