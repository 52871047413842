import { outlineColor } from '@ui-kit/globalStyles';
import { pxToRem } from '@ui-kit/helpers';
import { themeMode } from '@ui-kit/theme';
import { Theme } from 'react-select';
import { StylesConfig } from 'react-select/dist/declarations/src/styles';
import styled from 'styled-components';
import theme from 'styled-theming';

const activeOptionBackground = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.wildSand,
});

const mainColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen,
});

const menuBgColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen,
});

const defaultOptionColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.cruelSeaGreen,
});

const disabledOptionColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.greyMiddle,
});

const fontSize = pxToRem(15);

const getOptionColor = (themeProps: { theme: Theme }, isSelected: boolean, isFocused: boolean, isDisabled: boolean) => {
  if (isDisabled) {
    return disabledOptionColor(themeProps);
  }
  if (isSelected || isFocused) {
    return mainColor(themeProps);
  }
  return defaultOptionColor(themeProps);
};

export const getStyles = (isError?: boolean): StylesConfig => {
  return {
    option: (provided, { theme, isDisabled, isSelected, isFocused }) => {
      const themeProps = { theme };
      return {
        ...provided,
        padding: '8px 15px',
        boxSizing: 'border-box',
        fontSize,
        // @ts-ignore
        borderRadius: `${theme.mainBorderRadius.xs}`,
        backgroundColor: isSelected || isFocused ? `${activeOptionBackground(themeProps)}` : 'transparent',
        cursor: isDisabled ? 'default' : 'pointer',
        color: getOptionColor(themeProps, isSelected, isFocused, isDisabled),
        // @ts-ignore
        ...(isSelected ? theme.font.variants.primary.semiBold : theme.font.variants.primary.regular),
      };
    },
    menu: (provided, { theme }) => ({
      ...provided,
      // @ts-ignore
      borderRadius: `${theme.mainBorderRadius.s}`,
      // @ts-ignore
      border: `1px solid ${mainColor({ theme })}`,
      marginTop: '2px',
      marginBottom: '2px',
      padding: '2px 4px',
      boxSizing: 'border-box',
      boxShadow: 'none',
      background: menuBgColor(theme),
    }),
    singleValue: (provided, { isDisabled, theme }) => {
      const opacity = isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      return {
        ...provided,
        opacity,
        transition,
        fontSize,
        // @ts-ignore
        ...theme.font.variants.primary.regular,
        color: mainColor({ theme }),
      };
    },
    placeholder: (provided, { theme }) => ({
      ...provided,
      fontSize,
      // @ts-ignore
      ...theme.font.variants.primary.regular,
      color: mainColor({ theme }),
    }),
    valueContainer: (provided, { theme }) => ({
      ...provided,
      padding: '2px 8px 2px 20px',
      // @ts-ignore
      ...theme.font.variants.primary.regular,
    }),
    control: (provided, { theme, isFocused }) => {
      return {
        ...provided,
        // @ts-ignore
        borderRadius: `${theme.mainBorderRadius.s}`,
        // @ts-ignore
        border: `1px solid ${isError ? theme.palette.commonColors.red : mainColor({ theme })}`,
        outlineColor: 'transparent',
        boxShadow: isFocused ? `0px 0px 0px 2px ${outlineColor({ theme })}` : 'none',
        minHeight: '44px',
        fontSize: '1rem',
        ':hover': {
          // @ts-ignore
          border: `1px solid ${isError ? theme.palette.commonColors.red : mainColor({ theme })}`,
        },
      };
    },
    indicatorSeparator: provided => {
      return {
        ...provided,
        display: 'none',
      };
    },
    indicatorsContainer: provided => {
      return {
        ...provided,
        padding: '8px 10px',
      };
    },
    clearIndicator: provided => {
      return {
        ...provided,
        padding: '0 5px',
      };
    },
  };
};

export const InnerReactSelect = styled.div`
  width: 100%;
`;
