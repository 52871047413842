import { themeMode } from '@ui-kit/theme';
import styled from 'styled-components';
import theme from 'styled-theming';

export type BackgroundColorType = 'primary' | 'secondary';

const backgroundDefaultPlaceholder = theme.variants('mode', 'backgroundColor', {
  primary: {
    [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen,
  },
  secondary: {
    [themeMode.light]: ({ theme }) => theme.palette.commonColors.greyMiddle,
  },
});

export const Inner = styled.div<{ backgroundColor?: BackgroundColorType }>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.circle};
  background: ${backgroundDefaultPlaceholder};
  display: flex;
  align-items: center;
  justify-content: center;
`;
