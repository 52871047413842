import { ReactComponent as FilterIcon } from '@ui-kit/assets/icons/Filter.svg';
import { Button, Inner } from '@ui-kit/form/SearchInput/DropdownIndicator.styles';
import { SvgIcon } from '@ui-kit/icon';
import React from 'react';

interface DropdownIndicatorProps {
  handleToggleMenu: () => void;
  setButtonRef: any;
}

export const DropdownIndicator: React.FC<DropdownIndicatorProps> = ({ setButtonRef, handleToggleMenu }) => {
  return (
    <Inner>
      <Button ref={setButtonRef} onClick={handleToggleMenu}>
        <SvgIcon icon={FilterIcon} typeColorProperty={'stroke'} color={'secondary'} />
      </Button>
    </Inner>
  );
};
