import { Modal } from '@ui-kit/modal';
import styled from 'styled-components';

export const Inner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const SliderModal = styled(Modal)`
  .modal-content {
    background: transparent;
    border: none;
  }
`;

export const ImageContainer = styled.div`
  max-height: calc(100vh - 40px);
  min-height: calc(100vh - 40px);
  max-width: 2000px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InnerCloseButton = styled.div`
  position: fixed;
  right: 10px;
  top: 20px;
  z-index: 100;
`;
