import { CloseButton } from '@ui-kit/popup/CloseButton';
import { overlayStyles } from '@ui-kit/popup/Popup.styles';
import React from 'react';

import { ImageContainer, Inner, InnerCloseButton, SliderModal } from './Popup.styles';

interface Props {
  onClosePopup: () => void;
  isOpenPopup: boolean;
}

export const SliderPopup: React.FC<Props> = ({ children, onClosePopup, isOpenPopup }) => {
  return (
    <SliderModal
      overlayBackgroundType={'darken'}
      overlayStyles={overlayStyles}
      isOpen={isOpenPopup}
      shouldCloseOnOverlayClick
      onRequestClose={onClosePopup}
    >
      <Inner>
        <ImageContainer>{children}</ImageContainer>
        <InnerCloseButton>
          <CloseButton iconColor={'primary'} bgColor={'primary'} borderColor={'tertiary'} onClosePopup={onClosePopup} />
        </InnerCloseButton>
      </Inner>
    </SliderModal>
  );
};
