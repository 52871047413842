import { themeMode } from '@ui-kit/theme';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';

const colorText = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.eerieBlack,
});

const multiValueStyles = css`
  flex: 1 0 auto;
  padding: 6px 0;
  box-sizing: border-box;
  grid-area: 1 / 1 / 2 / 3;
  grid-template-columns: 0 min-content;
  display: inline-grid;
  visibility: visible;
  position: relative;
`;

export const Form = styled.form<{ isMultiValues: boolean }>`
  font-size: 1rem;
  flex: 1 0 auto;
  ${({ isMultiValues }) => isMultiValues && multiValueStyles}
`;

export const StyledInput = styled.input`
  color: ${colorText};
  border: none;
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  width: 100%;
  min-width: 2px;
  grid-area: 1 / 2 / auto / auto;
  font: inherit;
  background: none;
  opacity: 1;
  appearance: none;
`;

export const InnerInput = styled.div`
  visibility: visible;
  flex: 1 1 auto;
  display: inline-grid;
  grid-area: 1 / 1 / 2 / 3;
  grid-template-columns: 0 min-content;
  font-size: 1rem;
  ${({ theme }) => theme.font.variants.primary.medium};
  white-space: nowrap;
  &::after {
    content: attr(data-value) ' ';
    visibility: hidden;
    white-space: pre;
    grid-area: 1 / 2 / auto / auto;
    min-width: 2px;
    border: 0;
    margin: 0;
    outline: 0;
    padding: 0;
    font: inherit;
    background: none;
    appearance: none;
    border: none;
  }
`;
