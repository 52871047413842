import { CSS_PROPS } from '@constants/common';
import { TOPBAR_HEIGHT } from '@ui-kit/constants';
import { themeMode } from '@ui-kit/theme';
import styled, { css, StyledProps } from 'styled-components';
import theme from 'styled-theming';

const border = theme('mode', {
  [themeMode.light]: ({ theme }: any) => theme.palette.commonColors.wildSand,
});

const cellBackground = theme('mode', {
  [themeMode.light]: ({ theme }: any) => theme.palette.commonColors.cruelSeaGreen,
});

const disabledCellBackground = theme('mode', {
  [themeMode.light]: ({ theme }: any) => theme.palette.commonColors.greyDark,
});

const tableBackground = theme('mode', {
  [themeMode.light]: ({ theme }: any) => theme.palette.commonColors.white,
});

const colorHead = theme('mode', {
  [themeMode.light]: ({ theme }: any) => theme.palette.commonColors.white,
});

const colorBody = theme('mode', {
  [themeMode.light]: ({ theme }: any) => theme.palette.commonColors.cruelSeaGreen,
});

const selectedRowBackground = theme('mode', {
  [themeMode.light]: ({ theme }: any) => theme.palette.commonColors.wildSand,
});

const rowBackground = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.white,
});

export const TableContainer = styled.div`
  width: 100%;
  border-spacing: 0;
  background: ${tableBackground};
  border-radius: ${({ theme }) => theme.borderRadius.s};
  display: inline-block;

  & > :nth-child(1) {
    border-top-right-radius: ${({ theme }) => theme.borderRadius.s};
    border-top-left-radius: ${({ theme }) => theme.borderRadius.s};
  }
  & > :nth-child(2) {
    // border-bottom-radius could be added here
    overflow: hidden;
  }
`;

export const stylesCell = css`
  margin: 0;
  padding: 20px;
  border-bottom: 1px solid ${border};
  border-top: 1px solid ${border};
  box-sizing: border-box;
  font-size: 1rem;
`;
export interface MediaQueriesType {
  mediaQueries?: { mq: string; styles: React.CSSProperties }[];
}

const getStyles = (props: StyledProps<MediaQueriesType>) => {
  return props?.mediaQueries
    ?.map(mediaQuery => {
      return `${mediaQuery.mq}{
        ${mediaQuery.styles}
      }`;
    })
    .join(';');
};

export const TableHeaderCell = styled.div<MediaQueriesType>`
  ${stylesCell};
  background: ${cellBackground};
  ${({ theme }) => theme.font.variants.primary.bold};
  color: ${colorHead};
  text-align: left;
  box-sizing: border-box;
  ${getStyles};
  ${({ theme }) => theme.media.down.xl} {
    padding: 20px 16px;
  }
`;

export const TableBodyCell = styled.div<MediaQueriesType>`
  ${stylesCell};
  ${({ theme }) => theme.font.variants.primary.medium};
  color: ${colorBody};
  display: flex;
  align-items: center;
  ${getStyles};
  ${({ theme }) => theme.media.down.xl} {
    padding: 18px 16px;
  }
`;

export const TableRow = styled.div<{
  isSelected?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}>`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  &:last-child {
    ${TableHeaderCell},
    ${TableBodyCell} {
      border-bottom: 0;
    }
  }
  &:first-child {
    ${TableHeaderCell},
    ${TableBodyCell} {
      border-top: 0;
    }
  }
  background: ${({ isSelected }) => (isSelected ? selectedRowBackground : rowBackground)};
`;

export const HeaderTableRow = styled(TableRow)`
  border-top-right-radius: ${({ theme }) => theme.borderRadius.s};
  border-top-left-radius: ${({ theme }) => theme.borderRadius.s};
  overflow: hidden;
`;

const fixedHeaderBorderColor = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.whiteSmoke,
});

const stickyStyles = css`
  top: var(${CSS_PROPS.FIXED_HEADER_HIGHT}, 0);
  position: sticky;
  z-index: 50;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  ${({ theme }) => theme.media.down.xxl} {
    top: calc(var(${TOPBAR_HEIGHT}) + var(${CSS_PROPS.FIXED_HEADER_HIGHT}, 0px));
    border-top: 1px solid ${fixedHeaderBorderColor};
  }
`;

export const InnerFixedHeader = styled.div<{ isStickyHeader?: boolean; isLoading?: boolean }>`
  ${({ isStickyHeader }) => isStickyHeader && stickyStyles};
  ${({ isLoading }) => isLoading && 'pointer-events: none'};
  ${TableHeaderCell} {
    ${props => props.isLoading && `background: ${disabledCellBackground(props)}`};
  }
`;
