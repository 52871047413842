import { IUseDeleteUserReturnType } from '@hooks/user/useDeleteUser';
import { IUser } from '@type/user';

export enum SETTINGS_TABS {
  users = 'users',
  companies = 'companies',
  invoiceInformation = 'invoiceInformation',
  productsSubscription = 'productsSubscription',
}

export interface ISettingsUsersListProps {
  users: IUser[];
  currentUser?: IUser | null;
  fetchMore: () => void;
  editUser: (user: IUser) => () => void;
  goToUserPage: (user: IUser) => () => void;
  deleteUserData: IUseDeleteUserReturnType;
  hasNextPage: boolean;
  usersLoading?: boolean;
}
