import { outlineColor } from '@ui-kit/globalStyles';
import { themeMode } from '@ui-kit/theme';
import styled, { css, StyledProps } from 'styled-components';
import theme from 'styled-theming';

type StyledTextareaProps = {
  $isError?: boolean;
  resize?: boolean;
  rows: number;
};

export const backgroundColor = theme('mode', { [themeMode.light]: ({ theme }) => theme.palette.commonColors.white });
export const textColor = theme('mode', { [themeMode.light]: ({ theme }) => theme.palette.commonColors.cruelSeaGreen });
export const borderColor = theme.variants('mode', 'kind', {
  regular: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen },
  error: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.red },
  active: { [themeMode.light]: ({ theme }) => theme.palette.commonColors.cruelSeaGreen },
});

const getPrimaryBorder = (props: StyledProps<{ $isError?: boolean; $isActive?: boolean }>) => {
  if (props.$isError) {
    return `1px solid ${borderColor({ ...props, kind: 'error' })}`;
  }
  if (props.$isActive) {
    return `1px solid ${borderColor({ ...props, kind: 'active' })}`;
  }
  return `1px solid ${borderColor({ ...props, kind: 'regular' })}`;
};

export const fontStyles = css`
  ${({ theme }) => theme.font.variants.primary.regular};
  color: ${textColor};
  font-size: 1rem;
  line-height: normal;
`;

const resize = (props: StyledTextareaProps) => {
  return props.resize ? 'vertical' : 'none';
};

export const StyledTextArea = styled.textarea<StyledTextareaProps>`
  ${fontStyles};
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  display: block;
  height: 100%;
  left: 0;
  margin: 0;
  outline: none;
  padding: 20px;
  position: absolute;
  top: 0;
  width: 100%;
  resize: ${resize};
  &::placeholder {
    ${fontStyles};
  }
`;

export const Field = styled.div<StyledTextareaProps>`
  position: relative;
  height: ${({ rows }) => (rows || 4) * 20}px;
  max-height: ${({ rows }) => (rows || 4) * 20}px;
  background-color: ${backgroundColor};
  border-radius: ${({ theme }) => theme.borderRadius.s};
  border: ${getPrimaryBorder};
  box-sizing: border-box;
  display: block;
  margin: 0;
  outline: none;
  width: 100%;
  overflow: hidden;
  &:focus-within {
    border: ${props => getPrimaryBorder({ ...props, $isActive: true })};
    box-shadow: 0 0 0 2px ${outlineColor};
  }
`;
