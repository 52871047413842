import { borderRadius } from './borderRadius';
import { Media as media } from './breakpoints';
import { font } from './fonts';
import { palette } from './palette';
import { zIndex } from './zIndex';

export enum themeMode {
  light = 'light',
}

export type TypeThemeMode = keyof typeof themeMode;
export const theme = {
  palette,
  media,
  font,
  borderRadius,
  zIndex,
};

export type TypeTheme = typeof theme & { mode: TypeThemeMode };
