import { routes } from '@constants/routes';
import { PrivateRouter } from '@domains/Router/PrivateRouter';
import { Loader } from '@ui-kit/loader';
import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const Auth = lazy(() => import(/* webpackChunkName: "Auth" */ '../Auth/Auth'));

export const Router = () => {
  return (
    <Suspense fallback={<Loader size={40} />}>
      <Switch>
        <Route path={routes.auth.path} component={Auth} />
        <Route path={'/'}>
          <PrivateRouter />
        </Route>
        <Redirect to={routes.home.path} />
      </Switch>
    </Suspense>
  );
};
