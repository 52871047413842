import { InnerInput, StyledInput } from '@ui-kit/form/SearchInput/ValueInput.styles';
import React, { ChangeEvent, useEffect } from 'react';

interface ValueInputProps {
  inputValue?: string;
  onInputChange: (value: string, e: ChangeEvent<HTMLInputElement>) => void;
  setInputValueRef: (el: HTMLInputElement | null) => void;
  onFocusInputValue: () => void;
  setIsFocused: any;
}

export const ValueInput: React.FC<ValueInputProps> = ({
  inputValue,
  onInputChange,
  setInputValueRef,
  onFocusInputValue,
  setIsFocused,
}) => {
  useEffect(() => {
    onFocusInputValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onInputChange(e.target.value, e);
  };
  const onFocus = () => setIsFocused(true);
  const onBlur = () => setIsFocused(false);
  return (
    <InnerInput data-value={inputValue || ''}>
      <StyledInput
        value={inputValue}
        ref={setInputValueRef}
        onChange={handleChange}
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
        type="text"
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </InnerInput>
  );
};
