import { Label, PrimaryCustomRadio, SecondaryCustomRadio, Text } from '@ui-kit/form/Radio/Radio.styles';
import { pxToRem } from '@ui-kit/helpers';
import React, { ChangeEvent } from 'react';

export type TypeRadio = 'primary' | 'secondary';

interface RadioProps {
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  value: string;
  id?: string;
  type?: TypeRadio;
  label?: string;
}

const mapRadio = {
  primary: PrimaryCustomRadio,
  secondary: SecondaryCustomRadio,
};

export const Radio = React.forwardRef<any, RadioProps>(
  ({ checked, onChange, name, id, value, type = 'primary', label }, ref) => {
    const RadioInputComponent = mapRadio[type];
    return (
      <Label>
        <input type="radio" name={name} id={id} value={value} checked={checked} onChange={onChange} />
        <RadioInputComponent ref={ref} checked={checked} type={type} className={'custom-radio'} />
        {!!label && <Text fontSize={pxToRem(15)}>{label}</Text>}
      </Label>
    );
  },
);
