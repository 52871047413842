import React from 'react';

import { StyledSvg } from './Spinner.styles';

export interface SpinnerProps {
  color?: 'dark' | 'light';
  size?: number;
  className?: string;
}

export const Spinner: React.FC<SpinnerProps> = ({ className, color = 'dark', size = 30 }) => {
  return (
    <StyledSvg className={className} viewBox="0 0 143 143" width={size} height={size} color={color}>
      <rect x="65" width="13" height="39" rx="6.5" />
      <rect x="117.462" y="16.3457" width="13" height="39" rx="6.5" transform="rotate(45 117.462 16.3457)" />
      <rect x="143" y="65" width="13" height="39" rx="6.5" transform="rotate(90 143 65)" />
      <rect x="126.654" y="117.462" width="13" height="39" rx="6.5" transform="rotate(135 126.654 117.462)" />
      <rect x="65" y="104" width="13" height="39" rx="6.5" />
      <rect x="43.9229" y="89.8848" width="13" height="39" rx="6.5" transform="rotate(45 43.9229 89.8848)" />
      <rect x="39" y="65" width="13" height="39" rx="6.5" transform="rotate(90 39 65)" />
      <rect x="53.1152" y="43.9229" width="13" height="39" rx="6.5" transform="rotate(135 53.1152 43.9229)" />
    </StyledSvg>
  );
};
