import { TAvatarSize } from '@ui-kit/avatar/Avatar.styles';
import { getAvatarPlaceholder, ITextBaseProps, Text } from '@ui-kit/index';
import React from 'react';

import { BackgroundColorType, Inner } from './DefaultPlaceholder.styles';

export interface DefaultPlaceholderProps {
  text?: string;
  textStyles?: ITextBaseProps;
  backgroundColor?: BackgroundColorType;
}

const mapFontSize = {
  s: '1.3rem',
  m: '3rem',
};

export const DefaultPlaceholder: React.FC<DefaultPlaceholderProps & { avatarSize?: TAvatarSize }> = ({
  text = '',
  textStyles,
  backgroundColor = 'primary',
  avatarSize = 's',
}) => {
  return (
    <Inner backgroundColor={backgroundColor}>
      <Text
        textColor={'secondary'}
        fontSize={mapFontSize[avatarSize]}
        textAlign={'center'}
        fontWeight={'medium'}
        case={'uppercase'}
        {...textStyles}
      >
        {getAvatarPlaceholder(text)}
      </Text>
    </Inner>
  );
};
