import { ModalContent, UIModal } from '@ui-kit/modal/Modal.styles';
import { BackgroundTypeOverlay, Overlay } from '@ui-kit/overlay';
import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import { DefaultTheme, FlattenInterpolation, ThemeProps } from 'styled-components';

interface Props extends ReactModal.Props {
  overlayStyles?: FlattenInterpolation<ThemeProps<DefaultTheme>>;
  overlayBackgroundType?: BackgroundTypeOverlay;
  width?: string;
}

export const Modal = React.forwardRef<any, Props>(
  ({ children, overlayStyles, overlayBackgroundType, isOpen, width, ...props }, ref) => {
    useEffect(() => {
      if (!isOpen) return;
      document.documentElement.style.overflow = 'hidden';
      return () => {
        document.documentElement.style.overflow = 'auto';
      };
    }, [isOpen]);
    return (
      <UIModal
        {...props}
        ref={ref}
        isOpen={isOpen}
        ariaHideApp={false}
        width={width}
        contentElement={(props, children) => <ModalContent {...props}>{children}</ModalContent>}
        overlayElement={(props, contentElement) => (
          <Overlay {...props} className={'modal-overlay'} styles={overlayStyles} backgroundType={overlayBackgroundType}>
            {contentElement}
          </Overlay>
        )}
      >
        {children}
      </UIModal>
    );
  },
);
