import { getHomeRoute } from '@helpers/routing';
import { useSession } from '@hooks/auth';
import React from 'react';
import { Redirect } from 'react-router-dom';

export const HomePage = () => {
  const { currentUser } = useSession();

  const route = getHomeRoute(currentUser?.applicationRole, currentUser?.availableAccounts.length);
  if (!route) return null;

  return <Redirect to={route} />;
};
